import {
  LIST_MAKE,
  GET_MAKE,
  ADD_MAKE,
  UPDATE_MAKE,
  DELETE_MAKE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listMakes = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/make`,
      params
    });
    dispatch({
      type: LIST_MAKE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_MAKE,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getMake = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/make/${id}`,
    });
    dispatch({
      type: GET_MAKE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_MAKE,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addMake = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/make`,
      data
    });
    dispatch({
      type: ADD_MAKE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_MAKE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateMake = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/make/${id}`,
      data
    });
    dispatch({
      type: UPDATE_MAKE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MAKE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteMake = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/make/${id}`,
    });
    dispatch({
      type: DELETE_MAKE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_MAKE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}
export const clearMakeData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_MAKE,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};