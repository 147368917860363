import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  inviteUser,
  updateUser,
  getUser,
  clearData,
} from "../../../redux/actions/user";
import { listCompanyRoles } from "../../../redux/actions/companyRole";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { ADD_USER, GET_USER, UPDATE_USER } from "../../../redux/action_types";
import { ArrowBack } from "@material-ui/icons";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";

export default function Index() {
  const [field, setField] = useState({
    name: "",
    role: "",
    emailId: "",
  });
  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const { userId } = useParams();
  const user = useSelector((state) => state.user);
  const roles = useSelector((state) => state.companyRole);
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const [errors, seterrors] = useState({});
  const [companyLId, setCompanyLId] = useState("");
  const companyId = axios.defaults.headers.common["Company"];
  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem('companyId'))
      setCompanyLId(localStorage.getItem('companyId'))
    dispatch(clearData(UPDATE_USER));
    dispatch(clearData(GET_USER));
    dispatch(clearData(ADD_USER));
    dispatch(listCompanyRoles({ limit: 0 }));
    if (userId) dispatch(getUser(userId));
  }, []);

  useEffect(() => {
    // console.log(roles.list.data)
    if (roles.list && roles.list.data) setRoleList(roles.list.data);
  }, [roles]);
  useEffect(() => {
    // console.log(field)
  }, [field]);

  useEffect(() => {
    // console.log(user);
    if (user.detail.data._id && userId) {
      Object.keys(field).map((itm) => {
        if (itm === "role" && user.detail.data[itm]) {
          let role = user.detail.data[itm]._id;
          field.role = role;
          setField({ ...field });
        } else if (itm === "name") {
          let name =
            user.detail.data.firstName +
            (user.detail.data.lastName ? " " + user.detail.data.lastName : "");
          field.name = name;
          setField({ ...field });
        } else setField({ ...field, [itm]: user.detail.data[itm] });
      });
    }
    if (user.detail.data._id && !userId) {
      dispatch(clearData(GET_USER));
      history.push(
        (auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-user-list/" + axios.defaults.headers.common["Company"]
      );
    }
    if (user.add?.meta?.message) {
      dispatch(clearData(ADD_USER));
      history.push(
        (auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-user-list/" + axios.defaults.headers.common["Company"]
      );
    }
    if (user.update?.data?._id) {
      dispatch(clearData(UPDATE_USER));
      dispatch(clearData(GET_USER));
      history.push(
        (auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-user-list/" + axios.defaults.headers.common["Company"]
      );
    }
  }, [user]);

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.emailId) {
      error = true;
      temp.emailId = "Email is required";
    } else if (!/$^|.+@.+..+/.test(field.emailId)) {
      error = true;
      temp.emailId = "Email is wrong";
    }
    if (!field.role) {
      error = true;
      temp.role = "Role is required";
    }

    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = { ...field };
      // data.role = data.role._id;
      data.firstName = data.name.split(" ")[0];
      data.lastName = data.name.replace(data.firstName, "").trim();
      if (userId) dispatch(updateUser(data, userId));
      else dispatch(inviteUser(data));

      setField({
        role: "",
        name: "",
        emailId: "",
      });
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => history.push((auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-user-list/" + companyId)}
              icon=<ArrowBack />
            >
            </IconButton>
            Add User</h2>
        </Grid>
        <Grid spacing={2} container>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Name"
              value={field.name}
              isError={errors.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Email"
              value={field.emailId}
              isError={errors.emailId}
              onChange={(e) => {
                setFields("emailId", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Role"
              value={field.role}
              select={true}
              options={roleList.map((a) => {
                return { label: a.name, value: a._id };
              })}
              onChange={(e) => {
                setFields("role", e.target.value);
              }}
              fullWidth
              isError={errors.role}
            />
            {/* <AutoComplete
              variant="filled"
              label="Role"
              defaultValue={field.role}
              value={field.role}
              options={roleList}
              onChange={(e, newValue) => {
                // console.log(e, newValue)
                setFields("role", newValue);
              }}
              required={true}
              customOptionLabel={(option) => option.name}
              fullWidth
            /> */}
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text={userId ? "Update" : "Submit"}
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
