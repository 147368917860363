import React, { useState, useEffect } from "react";
import "./styles/style.css";
import "./theme/theme.css";
// import './assets/styles/theme.sass';
import "./App.css";
import useMainStyles from "./App.js";
import useAppBarStyles from "./components/Drawer/jss/style";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import AppBar from "./components/AppBar";
import CompanyAppBar from "./components/CompanyAppBar";
import Drawer from "./components/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { setTheme } from "./redux/actions/theme";
import Routes from "./routing/Routes";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// import { listWipStatuses } from "./redux/actions/wipStatus";
import { listRoles } from "./redux/actions/role";
// import { listTaskStatuses } from "./redux/actions/taskStatus";
import { CompanySignIn, Forgot, Onboard, SignIn } from "./components/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mui from "./components/Mui";
import Ui from "./components/Ui";
import { setCurrentUser } from "./redux/actions/auth";
import { setCompanyHeader } from "./utils/setAuthToken";
import { decrypt, encrypt } from "n-krypta";
import axios from "axios";
import { Backdrop } from "./commons/mui";
import "react-datepicker/dist/react-datepicker.css";
import { setLocalCompany } from "./redux/actions/localCompany";
import { EverisignRedirect } from "./components/Evensign";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const theme = useSelector((state) => state.theme);
  const localCompany = useSelector((state) => state.localCompany);
  var savedTheme = localStorage.getItem("theme");
  const main = useMainStyles();
  const appBar = useAppBarStyles();
  const [drawerState, setDrawerState] = useState(window.screen.width <= 820 ? false : true);
  const roles = useSelector(state => state.role.list.data);
  const [companyId, setCompanyId] = useState('');



  useEffect(() => {
    if (roles.length && companyId) {
      setCompanyHeader(encrypt(companyId, 'secret'))
    }
  }, [roles])
  useEffect(() => {
    if (localCompany?.companyId?.data) {
      setCompanyId(decrypt(localCompany.companyId.data, 'secret'));
      setCompanyHeader(localCompany.companyId.data);
      dispatch(listRoles())
    } else {
      setCompanyId(axios.defaults.headers.common["Company"]);
      setCompanyHeader('')
      dispatch(listRoles())
    }
  }, [localCompany])

  useEffect(() => {
    if (!localCompany.companyId.data) {
      let companyId = localStorage.getItem('companyId');
      if (companyId) {
        dispatch(setLocalCompany(companyId))
      }
    }
    if (savedTheme) dispatch(setTheme(savedTheme));

  }, []);

  const handleDrawerState = (forceState) => {
    if (typeof forceState !== "undefined") setDrawerState(forceState);
    else setDrawerState((old) => !old);
  };

  const checkForInactivity = () => {
    if (localStorage.getItem("user")) {
      const expireTime = localStorage.getItem("expireTime");

      if (expireTime < Date.now()) {
        dispatch(setCurrentUser(null));
        localStorage.removeItem("user");
        let companyId = localStorage.getItem("companyId");
        // console.log(companyId)
        if (companyId) {
          // console.log("/company-login/" + companyId)
          window.location.href = "/company-login/" + decrypt(companyId, 'secret');
        }
        // else history?.push("/login");
      }
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 300000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    // const interval = setInterval(() => {
    //   checkForInactivity();
    // }, 5000);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("mouseover", updateExpireTime);
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);

    return () => {
      window.removeEventListener("mouseover", updateExpireTime);
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
    };
  }, []);

  // useEffect(() => {
  //   console.log(window.innerWidth)
  //   if(window.innerWidth < 550){
  //     setDrawerState(false);
  //   }
  // }, [window.innerWidth])


  return (
    <Router>
      <div className={`${theme.selected} body ${main.root}`}>
        <Backdrop
          id="globle-loader"
          className="show-loader"
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        {localCompany.companyId.data ?
          <CompanyAppBar
            drawerState={drawerState}
            handleDrawerOpen={handleDrawerState}
          />
          :
          <AppBar
            drawerState={drawerState}
            handleDrawerOpen={handleDrawerState}
          />
        }
        {auth && window.location.pathname !== '/eversign-redirect' ? (
          <Drawer
            drawerState={drawerState}
            toggleDrawerState={handleDrawerState}
            user={auth}
          />
        ) : null}
        <main className={drawerState ? main.contentDrawerOpen : main.content}>
          <div className={appBar.toolbar} />
          <div className={main.contentGrid}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route exact path="/login" component={SignIn} />
              <Route exact path="/company-login/:companyId" component={CompanySignIn} />
              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/signup/:token" component={Onboard} />
              <Route exact path="/mui" component={Mui} accessLevel={null} />
              <Route exact path="/ui" component={Ui} accessLevel={null} />
              <Route exact path="/eversign-redirect" component={EverisignRedirect} />
              <Routes />
            </Switch>
          </div>
        </main>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Router>
  );
}

export default App;
