import visual from "../../../images/reference_criteria/visual.png";
import feel from "../../../images/reference_criteria/feel.jpg";
import listen from "../../../images/reference_criteria/listen.png";
import smell from "../../../images/reference_criteria/smell.png";
import count from "../../../images/reference_criteria/count.png";
import measure_diameter from "../../../images/reference_criteria/measure_diameter.png";
import measure_length from "../../../images/reference_criteria/measure_length.png";
import spec from "../../../images/reference_criteria/spec.png";
const TABLE_HEADER = {
    equipmentView: {
        data: "Equipment",
        width: "150px",
    }
};
const TABLE_HEADER_ACTIVITY = {
    referenceView: {
        data: "Reference",
        width: "50px",
        className: 'xs-none'
    },
    whatView: {
        data: "What",
        width: "150px",
    },
    howView: {
        data: "How",
        width: "150px",
    },
    whyView: {
        data: "Why",
        width: "150px",
    }
};
const REFERENCE_CRITERIA = [
    {
        name: "Visual",
        image: visual,
        checked: false
    },
    {
        name: "Feel",
        image: feel,
        checked: false
    },
    {
        name: "Listen",
        image: listen,
        checked: false
    },
    {
        name: "Smell",
        image: smell,
        checked: false
    },
    {
        name: "Count",
        image: count,
        checked: false
    },
    {
        name: "Measure Diameter",
        image: measure_diameter,
        checked: false
    },
    {
        name: "Measure Length",
        image: measure_length,
        checked: false
    },
    {
        name: "Refer to Spec",
        image: spec,
        checked: false
    },
]
const PLAN_TYPES = [
    {
        _id: "P",
        name: 'Part'
    },
    {
        _id: "G",
        name: 'Grid'
    },
]
export { TABLE_HEADER, TABLE_HEADER_ACTIVITY, REFERENCE_CRITERIA,PLAN_TYPES };