import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  IconButton,
  Pagination,
  Table,
  Tooltip,
} from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { clearProjectData, getProject } from "../../../redux/actions/project";
import moment from "moment";
import {
  clearPlanData,
  listPlans,
  updatePlan,
  verifyPlanSign,
  addInspactor
} from "../../../redux/actions/projectPlan";
import {
  BRM,
  CUSTOMER,
  TECHNICIAN,
} from "../../Projects/AllProjects/CONSTANTS";
// import ProjectTemplate from "../../Projects/ProjectTemplate";
// import { IllustrationTemplate } from "../../Projects";
import {
  ADD_WORK_PLAN_INSPECTOR,
  GET_PROJECT,
  GET_WORK_PLAN,
  LIST_WORK_PLAN,
  SIGN_WORK_PLAN,
  UPDATE_WORK_PLAN,
} from "../../../redux/action_types";
import AgreementSign from "../../Projects/AllProjects/AgreementSign";
import { ArrowBack } from "@material-ui/icons";
import { listCompanyRoles } from "../../../redux/actions/companyRole";
import { listUsers } from "../../../redux/actions/user";
import { toast } from "react-toastify";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import Close from "@material-ui/icons/Close";
import { Skeleton } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    margin: 0,
    width: 500,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});
export default function Index() {
  const history = useHistory();
  const classes = useStyles(styles)
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [projectData, setProjectData] = useState({});
  // const [planList, setPlanList] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const project = useSelector((state) => state.project);
  const projectPlans = useSelector((state) => state.projectPlan);
  const roles = useSelector((state) => state.companyRole);
  const users = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  const localCompany = useSelector((state) => state.localCompany);
  const { projectId } = useParams();
  const [selectedEsignPlan, setSelectedEsignPlan] = useState({});
  const [openTemplate, setOpenTemplate] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [eversignDetail, setEversignDetail] = useState({});
  const [signLoader, setSignLoader] = useState(false);
  const [selectedWorkPlan, setSelectedWorkPlan] = useState({});
  const [openAddInspactor, setOpenAddInspactor] = useState(false);
  const [technicianList, setTechnicianList] = useState([]);
  const [selectedInspactor, setSelectedInspactor] = useState('');

  useEffect(() => {
    // console.log('localCompany-----', localCompany)
    if (localCompany?.companyId?.data) {
      // dispatch(deletePlan('6504262af2b15c0015185f19'))
      setTimeout(() => {
        if (projectId)
          dispatch(getProject(projectId));
        else {
          setLoader(true);
          dispatch(listPlans({ userId: auth.data?._id, skip: 10 * (page - 1), history: false }));
        }
      }, 100);
      dispatch(listCompanyRoles({ limit: 0 }));
    }
  }, [localCompany, page]);
  useEffect(() => {
    // if (projectId) {
    //   dispatch(listPlans({ project: projectId, skip: 10 * (page - 1) }));
    // } else if (auth.data?.role?.name === TECHNICIAN) {
    //   dispatch(listPlans({ userId: auth.data?._id, skip: 10 * (page - 1) }));
    // }
  }, [page]);
  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail.data);
      if (projectId) {
        setLoader(true);
        dispatch(listPlans({ project: projectId, skip: 10 * (page - 1) }));
      } else if (auth.data?.role?.name === TECHNICIAN) {
        setLoader(true);
        dispatch(listPlans({ userId: auth.data?._id, skip: 10 * (page - 1) }));
      }
    }
  }, [project]);
  useEffect(() => {
    // console.log(projectPlans)
    if (projectPlans.list?.data) {
      setLoader(false);
    }
    if (projectPlans.list?.data?.length) {
      dispatch(clearPlanData(LIST_WORK_PLAN));
      // setPlanList(projectPlans.list?.data);
    }
    if (
      projectPlans.sign &&
      projectPlans.sign.data &&
      projectPlans.sign.data._id
    ) {
      dispatch(clearPlanData(SIGN_WORK_PLAN));
      setSignLoader(false)
      if (projectId) {
        setLoader(true);
        dispatch(listPlans({ project: projectId, skip: 10 * (page - 1) }));
      } else if (auth.data?.role?.name === TECHNICIAN) {
        setLoader(true);
        dispatch(listPlans({ userId: auth.data?._id, skip: 10 * (page - 1) }));
      }
    }
    if (projectPlans.sign?.hasError) {
      setSignLoader(false)
    }
    if (
      projectPlans.update &&
      projectPlans.update.data &&
      projectPlans.update.data._id
    ) {
      dispatch(clearPlanData(UPDATE_WORK_PLAN));
      if (projectId) {
        setLoader(true);
        dispatch(listPlans({ project: projectId, skip: 10 * (page - 1) }));
      } else if (auth.data?.role?.name === TECHNICIAN) {
        setLoader(true);
        dispatch(listPlans({ userId: auth.data?._id, skip: 10 * (page - 1) }));
      }
    }
    if (projectPlans.list?.data?.length) {
      dispatch(clearPlanData(GET_WORK_PLAN));
      let temp = projectPlans.list.data.map((a, i) => {
        let everSign =
          a.eversignList && a.eversignList.length
            ? (a.eversignList || []).find((aa) => aa._id)
            : {};
        // console.log(everSign);
        let signers = Object.keys(everSign?.meta || []).filter((a) =>
          a.includes("signer-")
        );
        // console.log("signers---", signers);
        let signerHead = everSign.meta ? everSign.meta["signer-0"] : {};
        signerHead.key = "signer-0";
        signerHead.position = 0;
        signers.map((it) => {
          if (everSign.meta[it].userId === auth.data?._id) {
            signerHead = everSign.meta ? everSign.meta[it] : {};
            signerHead.key = it;
            signerHead.position = Number(it.replace("signer-", ""));
          }
          return it;
        });
        // console.log("signerHead---", signerHead);
        signerHead.document_hash = everSign.meta?.document_hash;
        if (a._id === selectedEsignPlan._id) {
          if (signerHead.status === "signed" && openTemplate) {
            handleEverSignUpdate(signers);
          } else if (signerHead.status && openTemplate) {
            toast.error("Signature not done yet!");
          }
        }
        signers = signers.splice(2);
        a.quantity = a.quantity || a.part?.quantity || 0;
        a.processed = a.ok + a.nok;
        a.createdAt = moment(a.createdAt).format("YYYY-MM-DD");
        // console.log(a.status, a.status.includes("to Review Assessment"));
        a.action =
          ((a.status === "Awaiting BRM to E-Sign Work Plan" || a.status === "Awaiting BRM to E-Sign Revision Work Plan") && auth.data?.role?.name === BRM) ||
            ((a.status === "Awaiting Customer to E-Sign" || a.status === "Awaiting Customer to E-Sign Revision") && auth.data?.role?.name === CUSTOMER) ||
            (a.status.includes(" to E-Sign Work Plan") && (auth.data?.role?.name === TECHNICIAN) &&
              a.status.includes(signerHead.position - 1)) ||
            (a.status === "Awaiting Inspector to E-Sign Work Plan" && signerHead.userId === auth.data?._id && auth.data?.role?.name === TECHNICIAN) ? (
            <>
              <Tooltip
                title={`E-Sign`}
                placement="bottom"
                Component={() => (
                  <Button
                    onClick={(e) => {
                      setSelectedEsignPlan(a);
                      let everSign =
                        a.eversignList?.length
                          ? a.eversignList.find((a) => a._id == a._id)
                          : {};
                      let signes = Object.keys(everSign.meta).filter((a) =>
                        a.includes("signer-")
                      );
                      // console.log("signes---", signes);
                      let signer = everSign.meta ? everSign.meta["signer-0"] : {};
                      signes.map((it) => {
                        if (everSign.meta[it].userId === auth.data?._id) {
                          signer = everSign.meta ? everSign.meta[it] : {};
                        }
                        return it;
                      });
                      // console.log(signer);
                      signer.document_hash = everSign.meta.document_hash;
                      setEversignDetail(signer);
                      setOpenTemplate(true);
                      // dispatch(updateProject({ status: 'Awaiting Customer to E-Sign' }, project._id))
                    }}
                    text="E-Sign"
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: ".5rem" }}
                  />
                )}
              />
              {auth.data?.role?.name === BRM ?
                <Tooltip
                  title={`Edit`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        history.push('/brm-add/edit-workplan/' + a._id)
                      }}
                      text="Edit"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
                : null
              }
            </>
          ) : (a.status.includes("to Review Assessment") &
            (auth.data?.role?.name === TECHNICIAN) &&
            a.status.includes(signerHead.position)) ||
            (a.status === "Awaiting Inspector to Review Assessment" &&
              signerHead.userId === auth.data?._id && auth.data?.role?.name === TECHNICIAN && a.questionTemplate) ? (
            <>
              <Tooltip
                title={`Review`}
                placement="bottom"
                Component={() => (
                  <Button
                    onClick={(e) => {
                      history.push("/inspector-review-work-plan/" + a._id);
                    }}
                    text="Review"
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: ".5rem" }}
                  />
                )}
              />
              {auth.data?.role?.name === BRM ?
                <Tooltip
                  title={`Edit`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        history.push('/brm-add/edit-workplan/' + a._id)
                      }}
                      text="Edit"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
                : null
              }
            </>
          ) : a.status === "Work Plan Started" && auth.data?.role?.name === TECHNICIAN ? (
            <Tooltip
              title={`Start`}
              placement="bottom"
              Component={() => (
                <Button
                  onClick={(e) => {
                    history.push((a.type == 'P' ? "/inspector-work-plan/" : "/inspect-grid-work-plan/") + a._id);
                    // history.push("/inspector-work-plan/" + a._id);
                  }}
                  text="Start"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: ".5rem" }}
                />
              )}
            />
          ) : a.status !== "Work Plan Started" && auth.data?.role?.name === BRM ?
            <Tooltip
              title={`Edit`}
              placement="bottom"
              Component={() => (
                <Button
                  onClick={(e) => {
                    history.push('/brm-add/edit-workplan/' + a._id)
                  }}
                  text="Edit"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: ".5rem" }}
                />
              )}
            /> : a.status === "Work Plan Started" && auth.data?.role?.name === BRM ?
              <Tooltip
                title={`Add Inspector`}
                placement="bottom"
                Component={() => (
                  <Button
                    onClick={(e) => {
                      setSelectedWorkPlan({ ...selectedWorkPlan, ...a });
                      setOpenAddInspactor(true)
                      // history.push('/brm-add/edit-workplan/' + a._id)
                    }}
                    text="Add Inspector"
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: ".5rem" }}
                  />
                )}
              /> : null;
        a.name = (<Typography>
          {a.name}
          <br />
          (ITEQP{a.project?.projectNo})
        </Typography>);
        return a;
      });
      setdata(temp);
    }
    if (projectPlans.addInspactor?.data?._id) {
      dispatch(clearPlanData(ADD_WORK_PLAN_INSPECTOR));
      setOpenAddInspactor(false)
      toast.success('Inspector successfully added to workPlan ' + selectedWorkPlan.name)
      setSelectedWorkPlan({})
      setSelectedInspactor('');

    }
  }, [projectPlans]);
  useEffect(() => {
    if (users.list?.data?.length) {
      // console.log("ji", users.list?.data, selectedWorkPlan, selectedWorkPlan.project?.workLocation);
      setTechnicianList(
        users.list?.data
          ?.filter(
            (a) =>
              a.location?.filter(
                (aa) =>
                  selectedWorkPlan.project?.workLocation?.filter((aaa) => aaa._id === aa._id)
                    .length
              ).length && selectedWorkPlan.userId?.filter(aaa => aaa._id !== a._id).length
          )
          .map((a) => {
            a.name = a.firstName
              ? a.firstName + (a.lastName ? " " + a.lastName : "")
              : a.emailId;
            return a;
          })
      );
    }
  }, [users, selectedWorkPlan]);
  useEffect(() => {
    if (!technicianList.length && selectedWorkPlan._id) {
      toast.error("No ither technician getting in project work location!");
    }
  }, [technicianList])
  useEffect(() => {
    if (roles.list.data) {
      let tech = roles.list.data.find((a) => a.name === TECHNICIAN) || {};
      // console.log(tech, roles.list.data, TECHNICIAN)
      if (tech && tech._id) dispatch(listUsers({ role: tech._id, limit: 0 }));
    }
  }, [roles]);
  // useEffect(() => {

  // }, [projectPlans]);
  const handleEverSignUpdate = (signer) => {
    let status = "Awaiting BRM to E-Sign Work Plan";
    signer = signer.splice(1);
    if (selectedEsignPlan.status === "Awaiting BRM to E-Sign Work Plan")
      status = "Awaiting Customer to E-Sign";
    if (selectedEsignPlan.status === "Awaiting BRM to E-Sign Revision Work Plan")
      status = "Awaiting Customer to E-Sign Revision";

    // console.log(signer, signer.length, signer.length > 1);
    if (selectedEsignPlan.status === "Awaiting Customer to E-Sign")
      status = signer.length - 1 > 1
        ? (selectedEsignPlan.questionTemplate ? "Awaiting Inspector 1 to Review Assessment" : "Awaiting Inspector 1 to E-Sign Work Plan")
        : selectedEsignPlan.project.revProject && selectedEsignPlan.project.revProject._id ? 'Work Plan Started' : (selectedEsignPlan.questionTemplate ? "Awaiting Inspector to Review Assessment" : "Awaiting Inspector to E-Sign Work Plan");
    if (selectedEsignPlan.status === "Awaiting Customer to E-Sign Revision")
      status = 'Work Plan Started';
    //"Work Plan Started";
    if (selectedEsignPlan.status === "Awaiting Inspector to E-Sign Work Plan")
      status = "Work Plan Started";//"Awaiting Customer to E-Sign";
    signer.pop();
    // console.log(signer);
    for (let i = 0; i < signer.length; i++) {
      // console.log("Awaiting Inspector " + (i + 1) + " to E-Sign Work Plan");
      if (
        selectedEsignPlan.status ===
        "Awaiting Inspector " + (i + 1) + " to E-Sign Work Plan"
      )
        status = signer.length > i + 1 ? "Awaiting Inspector " + (i + 2) + " to E-Sign Work Plan" : "Work Plan Started";//"Awaiting Customer to E-Sign";
      if (
        selectedEsignPlan.status ===
        "Awaiting Inspector to E-Sign Work Plan"
      )
        status = "Work Plan Started"//"Awaiting Customer to E-Sign";
    }
    console.log(status)
    let upData = { status: status };
    dispatch(updatePlan(upData, selectedEsignPlan._id));
    setOpenTemplate(false);
    setIframeLoaded(false);
  };
  return (
    <div>
      <Grid
        container
        style={{
          // display: "flex",
          justifyContent: "space-between",
          // height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>
          {auth.data?.role?.name !== TECHNICIAN ? (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push(
                    auth.data?.role?.name === CUSTOMER
                      ? "/customer-projects"
                      : "/projects"
                  );
                }}
                icon=<ArrowBack />
              ></IconButton>
              {(projectData?.name || "") +
                "(" +
                (projectData?.projectNo || "") +
                ")"}{" "}
            </>
          ) : null}
          Work Plans List
        </h2>
        {auth.data?.role?.name !== BRM ? null : projectId && !data.length ? (
          <Button
            width="200px"
            text="Create Work Plan"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                "add/workplan/" +
                projectId
              )
            }
          />
        ) : null}
      </Grid>
      {!loader ?
        <Grid style={{ marginTop: "1rem" }}>
          <Table
            header={TABLE_HEADER}
            data={data}
            startBtn={false}
            actions={false}
            onStart={(e, i, row) => { }}
          />
          {auth.data?.role?.name === TECHNICIAN ?
            <Grid item container style={{ justifyContent: "center" }}>
              <div className="ui-components">
                <Pagination
                  count={projectPlans.list.totalCount}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </Grid>
            : null}
        </Grid>
        :
        [1, 2, 3, 4, 5].map(a => (
          <Grid item container sm={12} direction='row'>
            <Skeleton variant="rect" width={'100%'} height={66} style={{ marginBottom: 30 }} />

          </Grid>
        ))
      }
      < div >
        <AgreementSign open={openTemplate} eversignDetail={eversignDetail} signLoader={signLoader} title="Work Plan Review" verifySign={() => {
          let upData = {
            meta: { related_document_hash: eversignDetail.document_hash },
          };
          setSignLoader(true)
          dispatch(verifyPlanSign(upData));
        }} />

        <Dialog
          open={openAddInspactor}
          onClose={() => setOpenAddInspactor(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ minWidth: 950 }}
          className="add-inspactor"
        // fullScreen
        >
          <DialogTitle id="alert-dialog-title" >
            {"Add Inspector to work plan " + selectedWorkPlan.name}
            <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() => setOpenAddInspactor(false)} icon={<Close />}>

            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 45, overflow: "hidden" }}>
            <AutoComplete
              variant="filled"
              label="Inspector"
              color="secondary"
              value={technicianList.find(a => a._id === selectedInspactor)}
              // key={selectedInspactor}
              name="userId"
              // multiple={true}
              options={technicianList}
              // helperText=""
              // isError={errors.userId}
              onChange={(e, newValue) => {
                // console.log('newValue-----', newValue)
                setSelectedInspactor(newValue._id);
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let data = {
                  workPlanId: selectedWorkPlan._id,
                  userId: selectedInspactor
                }
                dispatch(addInspactor(data))
              }}
              text="Add Inspector"
              variant="contained"
              color="primary"
              disabled={selectedInspactor ? false : true}
            />
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
}
