import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyRole,
  clearCompanyRoleData,
  getCompanyRole,
  updateCompanyRole,
  listCompanyRoles
} from "../../../redux/actions/companyRole";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ROLES } from "./CONSTANTS";
import axios from "axios";
import { ADD_COMPANY_ROLE } from "../../../redux/action_types";
import { ArrowBack } from "@material-ui/icons";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";

export default function Index() {
  const [field, setField] = useState({
    name: "",
    description: "",
  });

  const roles = useSelector((state) => state.companyRole);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { roleId } = useParams();
  const history = useHistory();
  const [existRoleList, setExistRoleList] = useState([]);
  const [errors, seterrors] = useState({});
  const companyId = axios.defaults.headers.common["Company"];

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    dispatch(listCompanyRoles({ limit: 0 }));
    if (roleId) dispatch(getCompanyRole(roleId));
  }, []);

  useEffect(() => {
    // console.log(roles, roleId);

    if (roles?.list?.data) setExistRoleList(roles.list.data.map((a) => a.name));
    if (roles.detail?.data._id && roleId) {
      setField({
        name: roles.detail.data.name,
        description: roles.detail.data.description,
      });
    } else if (roles.add?.meta && !roleId) {
      dispatch(clearCompanyRoleData(ADD_COMPANY_ROLE));
      history.push(
        (auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-role-list/" + companyId
      );
    }
  }, [roles]);

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.description) {
      error = true;
      temp.description = "Description is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      if (roleId) dispatch(updateCompanyRole(field, roleId));
      else dispatch(addCompanyRole(field));
      setField({
        description: "",
        name: "",
      });
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => history.push((auth.data?.role?.name===BRM ? "/brm-" : "/") + "company-role-list/" + companyId)}
              icon=<ArrowBack />
            >
            </IconButton>
            Add Role</h2>
        </Grid>
        <Grid spacing={3} container component={"form"} noValidate>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Name"
              value={field.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              select={true}
              isError={errors.name}
              options={ROLES.filter(
                (a) => existRoleList.indexOf(a.value) == -1
              )}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Description"
              value={field.description}
              multiline
              isError={errors.description}
              minrow={3}
              onChange={(e) => {
                setFields("description", e.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text={roleId ? "Update" : "Submit"}
            variant="contained"
            color="primary"
            onClick={() => { onSubmit() }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
