import { Grid } from "@material-ui/core";
import { Button, TextField, AutoComplete, ConfirmDialog, IconButton, ErrDialog, InfoDialog } from "./mui";
import { Typography } from '@material-ui/core';
import {
  RemoveCircleOutline as MinusIcon
} from '@material-ui/icons';
import { useState } from "react";

const selectFormElement = (item) => {
  switch (item.component) {
    case 'Textfield': return <TextField
      {...item.props}
    // value={teamObj[item.name]} 
    // onChange={onTFChange} 
    />
    case 'Autocomplete': return <AutoComplete
      {...item.props}
    // value={teamObj[item.name] !== null && DATA[item.source][teamObj[item.name]]}
    // onChange={(event, newValue) => {
    //   onACChange(event, item.name, newValue);
    // }}
    // options={DATA[`${item.name.toUpperCase()}S`]}
    />
    default: return ""
  }
}

const getTFForm = (props) => <form onSubmit={props.onSubmit} onReset={props.onReset}>
  <Grid container justifyContent='center' spacing={3} className={props.container}>
    {
      props.model.map((item, i) => (
        <Grid item container xs={12} justifyContent='center' key={i}>
          {selectFormElement(item)}
        </Grid>
      ))
    }
    <Grid item container xs={12} justifyContent='center'>
      <Grid item>
        <Button type='reset' text='Cancel' variant='contained' />
      </Grid>
      <Grid item>
        <Button type='submit' text='Submit' variant='contained' color='primary' />
      </Grid>
    </Grid>
  </Grid>
</form>

const getConfirmDialog = (props) => <ConfirmDialog {...props}>
  <Grid item container xs={12} justifyContent='center'>
    <Typography variant='body1'>{props.text}</Typography>
  </Grid>
</ConfirmDialog>

const getErrorDialog = (props) => <ErrDialog {...props}>
  <Grid item container xs={12} justifyContent='center'>
    <Typography variant='body1'>{props.text}</Typography>
  </Grid>
</ErrDialog>

const getInfoDialog = (props) => <InfoDialog {...props} />

const GetManageUserDialog = (props) => {
  const [fields, setFields] = useState([]);
  const addNewUser = () => {
    setFields(old => [...old, { emailId: '', role: null }]);
  }

  const onEmailChange = (event, i) => {
    const data = [...fields];
    data[i].emailId = event.target.value;
    setFields(data);
  }

  const onRoleChange = (event, item, i) => {
    const data = [...fields];
    data[i].role = item;
    setFields(data);
  }

  const removeUser = (i) => {
    const data = [...fields];
    data.splice(i, 1);
    setFields(data)
  }

  return <ConfirmDialog
    {...props}
    btn1={{
      ...props.btn1,
      onClick: () => {
        props.btn1.onClick();
        setFields([]);
      }
    }}
    btn2={{
      ...props.btn2,
      onClick: () => {
        props.btn2.onClick(fields);
        setFields([]);
      }
    }}
  >
    <Grid item container xs={12} justifyContent='center' spacing={3}>
      <Grid item container xs={12} justifyContent='flex-start'>
        <Grid item xs={5}>
          <TextField variant='standard' color='primary' label='Team' fullWidth={true} value={props.team} disabled={true} InputProps={{ disableUnderline: true }} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='flex-start'>
        <Grid item container xs={6}>
          <Grid item xs={3}><IconButton color='primary' icon={<MinusIcon style={{ height: '32px', width: '32px' }} />} /></Grid>
          <Grid item xs={9}>
            <TextField variant='filled' color='primary' label='User Email ID' fullWidth={true} value={props.emailId} disabled={true} />
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item container xs={4}>
          <AutoComplete variant='filled' color='primary' label='Role' fullWidth={true} value={props.role} disabled={true} />
        </Grid>
      </Grid>
      {
        fields.map((item, index) => {
          return <Grid item container xs={12} justifyContent='flex-start' key={index}>
            <Grid item container xs={6}>
              <Grid item xs={3}><IconButton color='primary' icon={<MinusIcon style={{ height: '32px', width: '32px' }} />} onClick={() => removeUser(index)} /></Grid>
              <Grid item xs={9}>
                <TextField variant='filled' color='primary' label='User Email ID' fullWidth={true} value={item.emailId} onChange={(event) => onEmailChange(event, index)} />
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item container xs={4}>
              <AutoComplete variant='filled' color='primary' label='Role' fullWidth={true}
                value={item.role}
                onChange={(event, newValue) => onRoleChange(event, newValue, index)}
                {...props.roleProps}
              />
            </Grid>
          </Grid>
        })
      }
      <Grid item container xs={12} justifyContent='center'>
        <Button text='Add Another' variant='contained' color='primary' onClick={() => addNewUser()} />
      </Grid>
    </Grid>
  </ConfirmDialog>
}

const GetDialogWithInput = (props) => {
  const [data, setData] = useState('');

  const onChange = (event, newValue) => {
    if (newValue && newValue._id) {
      setData((newValue._id).trim());
      props.tfProps.value = newValue
    } else {
      setData((event.target.value).trim());
      props.tfProps.value = (event.target.value).trim()
    }
  }

  return <ConfirmDialog
    {...props}
    btn1={{
      ...props.btn1,

      onClick: () => {
        props.btn1.onClick();
        setData('');
      }
    }}
    btn2={{
      ...props.btn2,
      disabled: props.btn2.required ? data === '' : false,
      onClick: () => {
        props.btn2.onClick(data);
        setData('');
      }
    }}
  >
    <Grid item container xs={12} justifyContent='center' spacing={3}>
      <Grid item container xs={12} justifyContent='flex-start'>
        {props.tfProps.autocomplete ?
          <AutoComplete variant='filled' color='primary' fullWidth={true} {...props.tfProps} onChange={onChange} />
          :
          <TextField {...props.tfProps} onChange={onChange} />
        }
      </Grid>
    </Grid>
  </ConfirmDialog>
}

const GetFinishJobDialog = (props) => {
  const [data, setData] = useState({
    endOdo: 0,
    comment: ''
  });

  const onChange = (event) => {
    setData(old => ({
      ...old,
      [event.target.name]: event.target.value,
    }));
  }

  // ? Check if endOdo can be 0 or not
  return <ConfirmDialog
    {...props}
    btn1={{
      ...props.btn1,
      onClick: () => {
        props.btn1.onClick();
        setData({ endOdo: 0, comment: '' });
      }
    }}
    btn2={{
      ...props.btn2,
      disabled: (props.mileageRequired ? !data.endOdo || (data.comment === "") : false || (data.comment === "")),
      onClick: () => {
        props.btn2.onClick(data);
        setData({ endOdo: 0, comment: '' });
      }
    }}
  >
    <Grid item container xs={12} justifyContent='center' spacing={3}>
      {
        props.mileageRequired && <Grid item container xs={12} justifyContent='flex-start'>
          <TextField
            name='endOdo'
            label='End mileage'
            variant='filled'
            color='primary'
            fullWidth={true}
            type='number'
            required={true}
            onChange={onChange} />
        </Grid>
      }
      <Grid item container xs={12} justifyContent='flex-start'>
        <TextField name='comment' {...props.tfProps} onChange={onChange} />
      </Grid>
    </Grid>
  </ConfirmDialog>
}

const GetSkipFinishDialog = (props) => {
  const [comment, setComment] = useState('');

  const onChange = (event) => {
    setComment((event.target.value).trim());
  }

  return <ConfirmDialog
    {...props}
    btn1={{
      ...props.btn1,
      onClick: () => {
        props.btn1.onClick();
        setComment('');
      }
    }}
    btn2={{
      ...props.btn2,
      disabled: comment === '',
      onClick: () => {
        props.btn2.onClick(comment);
        setComment('');
      }
    }}
  >
    <Grid item container xs={12} justifyContent='center' spacing={3}>
      <Grid item container xs={12} justifyContent='flex-start'>
        <Grid item xs={3}>
          <Typography variant='h6'>Task name:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant='h6' noWrap>{props?.task?.module?.name || ''}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='flex-start'>
        <Grid item xs={3}>
          <Typography variant='h6'>Ideal Time:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant='h6' noWrap>{props?.task?.module?.resolutionTime ? `${props?.task?.module?.resolutionTime} mins` : ''}</Typography>
        </Grid>
      </Grid>
      {
        props?.finish && <Grid item container xs={12} justifyContent='flex-start'>
          <Grid item xs={3}>
            <Typography variant='h6'>Effort Time:</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h6' noWrap>{props?.effortTime || 0}</Typography>
          </Grid>
        </Grid>
      }
      <Grid item container xs={12} justifyContent='flex-start'>
        <Grid item xs={12}>
          <Typography variant='h6'>{props?.finish ? `Comment` : `Reason`}:</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth={true} variant='filled' color='primary' multiline={true} rows={3} onChange={onChange} />
        </Grid>
      </Grid>
    </Grid>
  </ConfirmDialog>
}

const FORMS = {
  GENERAL_TF_FORM: (props) => getTFForm(props),
}

const DIALOGS = {
  CONFIRM_DIALOG: (props) => getConfirmDialog(props),
  ERROR_DIALOG: (props) => getErrorDialog(props),
  INFO_DIALOG: (props) => getInfoDialog(props),
  MANAGE_USER: (props) => GetManageUserDialog(props),
  DIALOG_WITH_INPUT: (props) => GetDialogWithInput(props),
  FINISH_JOB_DIALOG: (props) => GetFinishJobDialog(props),
  SKIP_FINISH_TASK_DIALOG: (props) => GetSkipFinishDialog(props),
}

const ROLES = {
  'manager': "60092284c5e755331c2f05ab",
  'engineer': "60092291c5e755331c2f05ac",
  'supervisor': "6009225ec5e755331c2f05aa",
  'admin': "60091c18c5e755331c2f05a9",
  'reworkManager': '602f2206a4236d036cb15a57',
}

const TABLE_STICKY_TYPES = {
  "CORNER_CELL": "CORNER_CELL",
  "COLUMN_CELL": "COLUMN_CELL",
  "ROW_CELL": "ROW_CELL",
}
export {
  FORMS,
  DIALOGS,
  ROLES,
  TABLE_STICKY_TYPES,
}