import React, { useEffect, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Tooltip } from "../../index";
import { Typography } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles({
  redColor: {
    backgroundColor: "#ff0000",
    color: '#fff'
  },
  greenColor: {
    backgroundColor: "#00b050",
    color: '#fff'
  },
  amberColor: {
    backgroundColor: "#ef6c00",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
    fontSize: 12,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 13,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    // maxWidth: "20px",
  },
}))(TableCell);

// const SecondHeaderStyledTableCell = withStyles((theme) => ({
//   head: {
//     fontWeight: "bold",
//     fontSize: 12,
//   },
// }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(even)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const Index = ({
  data = [],
  onEdit = null,
  editPrefix = "/edit",
  onDelete = null,
  header = {},
  actions = true,
  onLogin = null,
  showDelete = true,
  startBtn = false,
  controlMeasureColumn = true,
  hazardColumn = true,
  hazardListColumn = false,
  onStart = null,
  onControlMeasure = null,
  onHazard = null,
  onHazardList = null,
  setAction = null,
  onDeleteControlMeasure = null,
  onDeleteHazard = null,
  onDeleteHazardList = null,
  onEditControlMeasure = null,
  color = false,
  actionValue = false,
  controlMeasureHeader = 'Control Measure',
  hazardHeader = 'Hazard',
  hazardListHeader = 'Hazard',
  style = {},
  onScrollTop = null,
  scrollTop = null,
}) => {
  const scrollRef = useRef(null);
  if (scrollRef.current) {
    scrollRef.current.scrollTop = scrollTop;
  }
  const classes = useStyles();
  const [toggle, settoggle] = useState(
    data.map((itm) => {
      return false;
    })
  );
  const [enableAction, setenableAction] = useState(
    data.map((itm) => {
      return false;
    })
  );

  const getKeys = (obj) => Object.keys(obj);

  const renderHeader = () => {
    let keys = getKeys(header);
    return keys.map((key, index) => (
      <StyledTableCell
        key={index}
        align={header[key]?.align || `center`}
        style={{ width: header[key]?.width || "auto" }}
      >
        {header[key]?.data}
      </StyledTableCell>
    ));
  };

  const renderRows = () => {
    let keys = getKeys(header);
    // console.log(data)

    return data.map((item, i) => (
      <StyledTableRow
        key={i}
        className={
          color
            ? item.color ? (classes[item.color] || '') : ((item?.controlMeasure || item?.hazardList?.length) &&
              ((item.assessmentAnswer === "No" && (item.riskAcceptable === '')))
              ? classes.amberColor
              : (!item?.controlMeasure && item.assessmentAnswer === "No" && (item.riskAcceptable === 'No' || item.riskAcceptable === '')) || (item?.hazardList?.length
                && item.riskAcceptable === 'No')
                ? classes.redColor
                : item.assessmentAnswer === "Yes" || item.riskAcceptable === 'Yes'
                  ? classes.greenColor
                  : null)
            : null
        }
      >
        {renderDataCells(item, keys, i)}
      </StyledTableRow >
    ));
  };

  // useEffect(() => {
  //   console.log("color", color);
  // }, [color]);

  const renderDataCells = (row, keys, i) => {
    let dataCells = keys.map((key, j) => (
      <StyledTableCell
        key={j}
        align={header[key]?.align || `center`}
        style={{ maxWidth: header[key]?.width || "auto", fontWeight: (row.type?.toLowerCase() !== 'option' ? 'bold' : 'normal') }}
      >
        {renderComponent(header[key], row, key)}
      </StyledTableCell>
    ));
    let action = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        {row.type === 'Option' ?
          <div>
            <Button
              onClick={(e) => {
                setAction("Yes", i, row);
                settoggle(
                  toggle.map((itm, index) => {
                    if (index === i) {
                      return true;
                    } else {
                      return itm;
                    }
                  })
                );
                setenableAction(
                  enableAction.map((itm, index) => {
                    if (index === i) {
                      return true;
                    } else {
                      return itm;
                    }
                  })
                );
              }}
              text="Yes"
              disabled={
                row.assessmentAnswer === "Yes" || row.project?.assessmentDone
              }
              variant="contained"
              color={
                row.assessmentAnswer === "Yes" || row.project?.assessmentDone
                  ? ""
                  : "primary"
              }

            />
            <Button
              onClick={(e) => {
                setAction("No", i, row);
                settoggle(
                  toggle.map((itm, index) => {
                    if (index === i) {
                      return false;
                    } else {
                      return itm;
                    }
                  })
                );
                setenableAction(
                  enableAction.map((itm, index) => {
                    if (index === i) {
                      return true;
                    } else {
                      return itm;
                    }
                  })
                );

              }}
              text="No"
              disabled={
                row.assessmentAnswer === "No" || row.project?.assessmentDone
              }
              variant="contained"
              color={"primary"}

            />
          </div>
          : null}
        {row.assessmentAnswer && actionValue ? (
          <Typography style={{ fontWeight: "bold", marginTop: ".5rem" }}>
            {row.assessmentAnswer}
          </Typography>
        ) : null}
      </StyledTableCell>
    );
    let controlMeasure = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        {row.type === 'Option' ?
          <div>
            {data[i].controlMeasure ? (
              <Button
                onClick={(e) => {
                  onDeleteControlMeasure(i);
                }}
                text="Delete"
                variant="contained"
                color="secondary"
                disabled={row.project?.assessmentDone}
              />
            ) : (
              <>
                <Button
                  onClick={(e) => {
                    onControlMeasure(i);
                  }}
                  text={data[i]?.controlMeasure ? "Edit" : "Add"}
                  variant="contained"
                  color="primary"
                  disabled={
                    row.assessmentAnswer != "No" || row.project?.assessmentDone
                  }
                />
              </>
            )}
            {data[i].controlMeasure && actionValue ? (
              <>
                {/* <p style={{ fontWeight: "bold" }}>{data[i].controlMeasure}</p> */}
                <Typography style={{ fontWeight: "bold", marginTop: ".5rem" }}>
                  {data[i].controlMeasure}
                </Typography>
              </>
            ) : null}
          </div>
          : null}
      </StyledTableCell>
    );
    let hazard = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        {row.type === 'Option' ?
          <div>
            {data[i].hazard ? (
              <Button
                onClick={(e) => {
                  onDeleteHazard(i);
                }}
                text="Delete"
                variant="contained"
                color="secondary"
                disabled={row.project?.assessmentDone}
              />
            ) : (
              <>
                <Button
                  onClick={(e) => {
                    onHazard(i);
                  }}
                  text={data[i]?.hazard ? "Edit" : "Add"}
                  variant="contained"
                  color="primary"
                  disabled={
                    row.assessmentAnswer != "No" || row.project?.assessmentDone
                  }
                />
              </>
            )}
            {data[i].hazard && actionValue ? (
              <>
                {/* <p style={{ fontWeight: "bold" }}>{data[i].hazard}</p> */}
                <Typography style={{ fontWeight: "bold", marginTop: ".5rem" }}>
                  {data[i].hazard}
                </Typography>
              </>
            ) : null}
          </div>
          : null}
      </StyledTableCell>
    );
    let hazardListRow = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        {row.type === 'Option' ?
          <div>
            {data[i].hazardList?.length ? (
              <Button
                onClick={(e) => {
                  onDeleteHazardList(i);
                }}
                text="Delete"
                variant="contained"
                color="secondary"
                disabled={row.project?.assessmentDone}
              />
            ) : (
              <>
                <Button
                  onClick={(e) => {
                    onHazardList(i);
                  }}
                  text={data[i]?.hazardList?.length ? "Edit" : "Add"}
                  variant="contained"
                  color="primary"
                  disabled={
                    row.assessmentAnswer != "No" || row.project?.assessmentDone
                  }
                />
              </>
            )}
            {data[i].hazardList && actionValue ? (
              <>
                {/* <p style={{ fontWeight: "bold" }}>{data[i].hazard}</p> */}
                <Typography style={{ fontWeight: "bold", marginTop: ".5rem" }}>
                  {data[i].hazardList?.map(a => a.hazard + ' | ' + a.controlMeasure).join()}
                </Typography>
              </>
            ) : null}
          </div>
          : null}
      </StyledTableCell>
    );
    return (
      <>
        {dataCells}
        {actions && action}
        {hazardColumn ? hazard : null}
        {hazardListColumn ? hazardListRow : null}
        {controlMeasureColumn ? controlMeasure : null}
      </>
    );
  };

  const renderComponent = (column, row, key) => {
    if (!column?.component)
      if (!row[key]) return "";
      else return row[key];
    // return ''
    if (!row[key]) return "";
    switch (column?.component) {
      case "Tooltip":
        return (
          <Tooltip
            {...column.props}
            title={row[column.props.title]?.map((item, i) => {
              return <div key={i}>{item}</div>;
            })}
            Component={() => <Typography noWrap={true}>{row[key]}</Typography>}
          />
        );
      case "img":
        return (
          <img
            width="100%"
            className={classes.img}
            src={row[key]}
            alt={"image-" + key}
          />
        );
      case "link":
        return (
          <>
            <Button
              onClick={(e) => {
                onLogin(row, column);
              }}
              text={column.label}
              variant="contained"
              color="primary"
              style={{ marginBottom: ".5rem" }}
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <TableContainer component={Paper} style={style || {}} onScroll={(e) => { onScrollTop(e.target.scrollTop) }} ref={scrollRef}
    >
      {/* <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}> */}
      <Table>
        <TableHead>
          <TableRow>
            {renderHeader()}
            {actions && (
              <StyledTableCell align="center" style={{ width: "200px" }}>
                Action
              </StyledTableCell>
            )}
            {hazardColumn ? (
              <StyledTableCell align="center" style={{ width: "200px" }}>
                {hazardHeader}
              </StyledTableCell>
            ) : null}
            {hazardListColumn ? (
              <StyledTableCell align="center" style={{ width: "200px" }}>
                {hazardListHeader}
              </StyledTableCell>
            ) : null}
            {controlMeasureColumn ? (
              <StyledTableCell align="center" style={{ width: "300px" }}>
                {controlMeasureHeader}
              </StyledTableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Index;
