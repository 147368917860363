import { decrypt } from 'n-krypta';
import {
  LIST_COMPANY_ROLE,
  GET_COMPANY_ROLE,
  ADD_COMPANY_ROLE,
  UPDATE_COMPANY_ROLE,
  DELETE_COMPANY_ROLE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listCompanyRoles = (params, companyId = '') => async dispatch => {
  if (companyId) {
    axios.defaults.headers.common["Company"] = decrypt(companyId, 'secret');
  }
  try {
    let res = await axios({
      method: 'GET',
      url: `/role`,
      params
    });
    dispatch({
      type: LIST_COMPANY_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_COMPANY_ROLE,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getCompanyRole = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/role/${id}`,
    });
    dispatch({
      type: GET_COMPANY_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_COMPANY_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addCompanyRole = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/role`,
      data
    });
    dispatch({
      type: ADD_COMPANY_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_COMPANY_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const updateCompanyRole = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/role/${id}`,
      data
    });
    dispatch({
      type: UPDATE_COMPANY_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_COMPANY_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const deleteCompanyRole = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/role/${id}`,
    });
    dispatch({
      type: DELETE_COMPANY_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_COMPANY_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}
export const clearCompanyRoleData = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};