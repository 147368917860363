import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme, Typography, Link, Card, CardContent, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog,
  List,
  CardHeader,
  Avatar,
  makeStyles
} from '@material-ui/core';
import Bay from '../../../Cards/Bay';
import { clearTechnicianPlanData } from '../../../../redux/actions/technicianPlan';
import { GET_WORK_PLAN, LIST_WORK_PLAN_REPORT, LIST_WORK_PLAN_VEHICLE_REPORT } from '../../../../redux/action_types';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowBack, MoreVert } from '@material-ui/icons';
import { Button, IconButton, Table } from '../../../../commons/mui';
import { clearPlanData, listPlanVehicleReports } from '../../../../redux/actions/projectPlan';
import ReactApexChart from "react-apexcharts";
import { TABLE_HEADER } from './CONSTANTS';
import Carousel from 'react-material-ui-carousel';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const Index = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const technicianPlan = useSelector((state) => state.technicianPlan);
  const projectPlans = useSelector((state) => state.projectPlan);
  const localCompany = useSelector((state) => state.localCompany);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(null);
  const [nokReview, setNokReview] = useState(false);
  const [rectifyReview, setRectifyReview] = useState(false);
  const [noOkList, setNokList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [plan, setPlan] = useState({});
  const { projectId, planId } = useParams();

  useEffect(() => {
    let query = {};
    if (projectId) {
      query.project = projectId;
    }
    if (planId) {
      query.workPlanId = planId;
      dispatch(listPlanVehicleReports(planId));
    }

  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [localCompany]);
  useEffect(() => {
    if (projectPlans.detail?.data?._id) {
      dispatch(clearPlanData(GET_WORK_PLAN))
      setPlan({ ...plan, ...projectPlans.detail?.data })
    }
    // console.log(projectPlans);
    if (projectPlans.vehicleReports?.data?.length) {
      dispatch(clearPlanData(LIST_WORK_PLAN_VEHICLE_REPORT))
      setReportList(projectPlans.vehicleReports?.data?.map(a => {
        a.completedQuantity = (a.totalOk + a.totalNok);
        a.completedPercent = ((a.completedQuantity * 100) / a.totalQuantity).toFixed(2)
        return a;
      }))
    }
  }, [projectPlans]);

  useEffect(() => {
    if (technicianPlan.reports?.data?.length) {
      dispatch(clearTechnicianPlanData(LIST_WORK_PLAN_REPORT))
      setReportList(technicianPlan.reports?.data.map(a => {
        a.completedQuantity = (a.totalOk + a.totalNok);
        a.completedPercent = ((a.completedQuantity * 100) / a.totalQuantity).toFixed(2);
        return a;
      }))
    }
  }, [technicianPlan]);


  return (
    <Grid container spacing={2}>
      <Grid item container >
        <Typography variant='h6' align='left' gutterBottom>
          <IconButton
            color="primary"
            onClick={() => history.goBack()}
            icon=<ArrowBack />
          >
          </IconButton>
          List of Vehicles</Typography>
      </Grid>
      {reportList.map(item => (
        <Grid item container sm={12} direction='row'>
          <h4 style={{ width: '100%' }} className="ui-component-headings">{item.vin}</h4>
          <Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
            <Grid item sm={3} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Task Completed' color={item.completedPercent < 25 ? theme.palette.red : item.completedPercent < 75 ? theme.palette.amber : theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item>{item.completedQuantity + (item.totalQuantity ? '/' + item.totalQuantity : '')}</Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Ok Task' color={theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item>{item.totalOk}</Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Not OK Task' color={theme.palette.error} titleAlign='center' width='250px' height='80px' content={<Grid item><Link style={{ cursor: 'pointer' }} onClick={() => { setNokList(item.nokHistory); setNokReview(true); setRectifyReview(false); }}>{item.totalNok}</Link></Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Rectify Task' color={theme.palette.amber} titleAlign='center' width='250px' height='80px' content={<Grid item><Link style={{ cursor: 'pointer' }} onClick={() => { if (item.rectifyTotal) { setNokList(item.nokHistory); setNokReview(true); setRectifyReview(true); } }}>{item.rectifyTotal}</Link></Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {item.totalQuantity ?
              <>
                <Grid item sm={6} >
                  <Card>
                    <CardContent style={{ minHeight: '32rem' }}>
                      <ReactApexChart options={{
                        chart: {
                          width: 300,
                          type: 'donut',
                        },
                        labels: ['Pending', 'Ok', 'Nok'],
                        colors: ['#FFC300', '#00E396', '#F44336'],
                        fill: {
                          type: 'gradient',
                        },
                        title: {
                          text: 'Task Performance',
                          align: 'center'
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 270
                          }
                        },
                        legend: {
                          position: 'bottom',
                          show: true
                        }
                      }} series={[item.totalQuantity - item.completedQuantity, item.totalOk, item.totalNok]} type="donut" />
                    </CardContent>
                  </Card>
                </Grid>

              </>
              : null}
          </Grid>
        </Grid>
      ))
      }
      <Dialog
        open={nokReview}
        onClose={() => { setNokReview(false); setRectifyReview(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{!rectifyReview ? "Not Ok Comments Review" : "Rectify Review"}</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <DialogContentText id="alert-dialog-description">
            <List component="nav" aria-label="main mailbox folders">
              {noOkList.map((level, i) => (
                <Card key={'card-' + i} className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        T
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVert />
                      </IconButton>
                    }
                    title={"Defect " + ((i + 1))}
                    subheader={moment(level.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  />
                  {level.images?.length ?
                    <Carousel key={'Carousel-' + i}>
                      {
                        level.images?.map((item) => (
                          <img src={axios.defaults.baseURL + '/file/' + item} style={{ width: '100%', height: 300 }} />
                        ))
                      }
                    </Carousel>
                    : null}
                  <CardContent>

                    <Typography variant="body2" color="textSecondary" component="p">
                      <strong>{level.rectifyComment ? 'Rectify Comment: ' : 'Comment: '}</strong> {level.rectifyComment || level.comment}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setNokReview(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Grid >
  )
}

export default Index