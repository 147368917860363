import React, { useEffect } from "react";
import { EQUIPMENTS, TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_2, TABLE_HEADER_HAZARDLIST } from "./CONSTANTS";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  ActionTable,
  Button,
  Pagination,
  Table,
  Checkbox,
  IconButton,
  TextField
} from "../../../commons/mui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getProject,
  updateProject,
  clearProjectData,
} from "../../../redux/actions/project";
import {
  clearData,
  listProjectAssessment,
  updateProjectAssessment,
} from "../../../redux/actions/projectAssessment";
import {
  listCompanyRoles
} from "../../../redux/actions/companyRole";
import { Alert } from "@material-ui/lab";
import { GET_PROJECT, UPDATE_PROJECT } from "../../../redux/action_types";
import { ADMIN } from "../../Company/ListProject/CONSTANTS";
import { listUsers } from "../../../redux/actions/user";
import { ASSOCIATE, BRM, PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import { ProjectDetailComponent } from "../../Projects";
import axios from "axios";
import { ArrowBack, Done } from "@material-ui/icons";




export default function Index() {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [hazardOpen, setHazardOpen] = React.useState(false);
  const [openReview, setOpenReview] = React.useState(false);
  const [controlMeasure, setcontrolMeasure] = useState("");
  const [hazard, setHazard] = useState("");
  const auth = useSelector((state) => state.auth);
  const [index, setindex] = useState(null);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [projectDetails, setprojectDetails] = useState({});
  const project = useSelector((state) => state.project);
  const companyRoles = useSelector((state) => state.companyRole);
  const users = useSelector((state) => state.user);
  // const [assessmentList, setassessmentList] = useState([])
  const projectAssessments = useSelector((state) => state.projectAssessments);
  const [page, setPage] = useState(1);
  const [brm, setBRM] = useState({});
  const [equipments, setEquipments] = useState(EQUIPMENTS);
  const [isValid, setIsValid] = useState(false);
  //HazardList Start
  const [hazardListOpen, setHazardListOpen] = React.useState(false);
  const [hazardList, setHazardList] = useState([]);
  const [processedAssesmentList, setProcessedAssesmentList] = useState([]);
  const setHazardListFields = (type, val, i, hzList = []) => {
    let list = hazardList.length ? hazardList : hzList;
    // console.log('type', type, val, i, list);
    if (list[i]) {
      list[i][type] = val;
      setHazardList(processHazardList(list));
    }
  };
  const processHazardList = (activities, row) => {
    let reference = 1;
    return activities.map((a, i) => {
      a.errors = a.errors || {};
      a.referenceView = reference;
      a.hazardView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <TextField
            variant="filled"
            placeholder="Hazard"
            value={a.hazard}
            isError={a.errors?.hazard}
            onChange={(e) => {
              setHazardListFields("hazard", e.target.value, i, activities);
            }}
            fullWidth
          // multiline
          // minRows={10}
          // maxRows={10}
          />
        </Grid>
      );
      a.controlMeasureView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <TextField
            variant="filled"
            placeholder="Control Measure"
            value={a.controlMeasure}
            isError={a.errors?.controlMeasure}
            onChange={(e) => {
              setHazardListFields("controlMeasure", e.target.value, i, activities);
            }}
            fullWidth
          // multiline
          // minRows={10}
          // maxRows={10}
          />
        </Grid>
      );
      // reference = Number(reference + 0.1).toFixed(1);
      reference = reference + 1;
      return a;
    });
  };
  //HazardList end
  useEffect(() => {
    dispatch(getProject(projectId));
    dispatch(listCompanyRoles());
  }, []);
  useEffect(() => {
    if (companyRoles.list.data && companyRoles.list.data.length) {
      let brmRole = companyRoles.list.data.filter(a => a.name === ADMIN) || [];
      if (brmRole.length) {
        dispatch(listUsers({ limit: 0, role: brmRole.map(a => a._id).join() }))
      }
    }
  }, [companyRoles]);
  useEffect(() => {
    // console.log(users)
    if (users.list.data && users.list.data.length) {
      setBRM(users.list.data.find(a => a.role.name === ADMIN))
    }
  }, [users]);
  useEffect(() => {

  }, [projectDetails])

  useEffect(() => {
    // console.log(project.detail)
    if (project.detail.data && project.detail.data._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setprojectDetails(project.detail.data);
    }
    if (project?.update?.data?._id) {
      dispatch(clearProjectData(UPDATE_PROJECT));
      dispatch(getProject(projectId));
      dispatch(
        // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
        listProjectAssessment({ project: projectId, limit: 0 })
      );
      if (project?.update?.data.assessmentDone) {
        // setTimeout(() => {
        if (auth.data?.role?.name === BRM)
          history.push('/projects');
        if (auth.data?.role?.name === ASSOCIATE)
          history.push('/associate');
        if (auth.data?.role?.name === PDSLADMIN && axios.defaults.headers.common["Company"])
          history.push('/company-project-list/' + axios.defaults.headers.common["Company"]);
        // }, 5000)
      }
    }
  }, [project]);

  useEffect(() => {
    dispatch(
      // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
      listProjectAssessment({ project: projectId, limit: 0 })
    );
  }, [page]);
  useEffect(() => {
    let plist = data.filter(a => a.assessmentAnswer && a.assessmentAnswer == 'No');
    let processList = [];
    // console.log(plist,data)
    plist.map(itm => {
      if (itm.hazardList?.length) {
        // processList.push(itm);
        let ref = 0;
        // console.log(itm, itm.hazardList,itm.sno);
        itm.hazardList.map((hz) => {
          ref = ref + 1;
          let row = { ...itm };
          row.sno = row.sno + '.' + ref;
          // console.log(row.sno,"-----", row.sno,"----", ref);
          row.hazard = hz.hazard;
          row.controlMeasure = hz.controlMeasure;
          // console.log(row, ref)
          processList.push(row);
          return hz;
        })
      } else {
        // processList.push(itm);
      }
      return itm;
    });
    // console.log(processList)
    setProcessedAssesmentList(processList);
  }, [data]);

  useEffect(() => {
    // console.log(projectAssessments);
    if (projectAssessments.list.data) {
      let list = projectAssessments.list.data.reverse();
      if (list.filter(a => a.assessmentAnswer).length === list.filter(a => a.assessmentQuestion.type === 'Option').length) {
        if (list.filter(a => a.assessmentAnswer == 'No').length === list.filter(a => a.hazardList.length).length)
          setIsValid(true)
        else
          setIsValid(false);
      } else {
        setIsValid(false);
      }
      let ref = 0, dref = 0;
      let datta = list.map((itm) => {
        if (itm.assessmentQuestion.type !== 'Option') {
          dref = dref + 1;
          ref = dref;
          itm.sno = dref;
          itm.type = itm.assessmentQuestion.type;
        } else {
          ref = ref + 0.1;
          itm.sno = Number(ref).toFixed(1);
          itm.type = itm.assessmentQuestion.type;
        }
        itm.question = itm.assessmentQuestion.question;
        return itm;
      });
      setdata(
        datta
      );

    }
    if (projectAssessments?.update?.data) {
      // console.log(projectAssessments?.update)
      dispatch(clearData());
      dispatch(
        // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
        listProjectAssessment({ project: projectId, limit: 0 })
      );
      dispatch(getProject(projectId));
    }
  }, [projectAssessments]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleHazardClickOpen = () => {
    setHazardOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenReview = () => {
    setOpenReview(true);
  };

  const handleCloseReview = () => {
    setOpenReview(false);
  };

  return (
    <div>
      <Grid container>
        <Grid style={{ margin: "0" }}>
          <h2>
            <IconButton
              color="primary"
              onClick={() => {
                history.push("/projects");
              }}
              icon=<ArrowBack />
            ></IconButton>
            Company Assessee
          </h2>
        </Grid>
        <ProjectDetailComponent margin="1rem 0.1rem" data={projectDetails} show={['projectNo', 'rev', 'sapNo', 'companyName', 'poNo', 'workLocation', '']} additional={(
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Environment :
            </Typography>
            <Typography>Quality CC</Typography>
          </Grid>
        )}></ProjectDetailComponent>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        {projectDetails.assessmentDone ? (
          <Grid style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Alert severity="success">Assessment Done for this project!</Alert>
          </Grid>
        ) : null}
        <ActionTable
          header={TABLE_HEADER}
          data={data}
          color={projectDetails.assessmentDone ? false : true}
          controlMeasureColumn={false}
          hazardListHeader={'Hazard/Control Measure'}
          hazardListColumn={true}
          hazardColumn={false}
          onControlMeasure={(i) => {
            setindex(i);
            handleClickOpen();
          }}
          onHazard={(i) => {
            setindex(i);
            setHazardOpen(true);
          }}
          onHazardList={(i) => {
            setindex(i);
            setHazardList(processHazardList([{ errors: {} }]))
            setHazardListOpen(true);
          }}
          setAction={(val, i, row) => {
            let upData = { assessmentAnswer: val };
            dispatch(updateProjectAssessment(upData, row._id));
          }}
          onDeleteControlMeasure={(i) => {
            let upData = { controlMeasure: "" };
            dispatch(updateProjectAssessment(upData, data[i]._id));
          }}
          onDeleteHazardList={(i) => {
            let upData = { hazardList: [] };
            dispatch(updateProjectAssessment(upData, data[i]._id));
          }}
        />
        {projectAssessments.list.totalCount > projectAssessments.list.pagination.to ?
          <Grid style={{ marginTop: "1rem" }}>
            <div className="ui-component-block">
              <div className="ui-components">
                <Pagination
                  count={projectAssessments.list.totalCount}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </Grid>
          : null}
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Typography variant="h5" style={{ justifyContent: 'center', textAlign: 'center', padding: '2rem 2rem' }}>If 'No' has been entered for any of the questions above, please explain variation(s) and confirm actions
          authorised and taken below to mitigate resulting risk(s)</Typography>
        {processedAssesmentList ? <Table
          header={TABLE_HEADER_1}
          data={processedAssesmentList}
          actions={false}
          color={true}
          onStart={() => {
            // history.push("/action");
          }}
        />
          : null}
      </Grid>

      {/* <div>
        <Grid style={{ margin: "1rem 1rem" }}>
          <b> Safety Equipments</b>
        </Grid>
        <Grid container></Grid>
      </div> */}
      <div>
        <Grid style={{ margin: "1rem 1rem" }}>
          <b>PPE indicate with <Done style={{ color: 'green' }} /> the PPE (Personal Protective Equipment) Required</b>
        </Grid>
        <Grid container>
          {equipments.map((item, i) => (
            <Grid item sm={3} xs={12} className="safety-checkboxes review">
              <Checkbox
                color="primary"
                checked={item.checked}
                onChange={(e) => {
                  let list = [...equipments]
                  list[i].checked = e.target.checked;
                  setEquipments([...list]);
                }}
                style={{ height: "36px", }}
                value={item.name}
                name={'safety-checkboxes'}
                text={(
                  <Grid className={item.checked ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"}>
                    <img className="checkbox-img" src={item.image} width={70} />
                    <Typography>{item.name}</Typography>
                    <Done />
                  </Grid>
                )}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid style={{ marginTop: "1rem", textAlign: "center" }}>
        <Button
          onClick={(e) => {
            //   onEdit(i, row);
            // settoggle(true);
            // handleClickOpenReview();
            let upData = {
              status: "Awaiting to Risk Review",
              assessmentDone: new Date(),
              auditOn: new Date(),
              auditBy: brm._id || null,
              safetyEquipments: equipments.filter(a => a.checked).map(a => a.name)
            };
            if (projectDetails.assessmentAssignTo?.role?.name === ADMIN) {
              upData.auditBy = projectDetails.assessmentAssignTo?._id
            }
            dispatch(updateProject(upData, projectId));
            handleCloseReview();
          }}
          text="Submit"
          variant="contained"
          color="primary"
          disabled={projectDetails.assessmentDone !== null || !isValid}
          style={{ marginBottom: ".5rem" }}
        />
      </Grid>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Control Measure"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                variant="filled"
                label="Control Measure"
                value={controlMeasure}
                onChange={(e) => {
                  // setField("partName", e.target.value);
                  // setpartName(e.target.value);
                  setcontrolMeasure(e.target.value);
                }}
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let upData = { controlMeasure: controlMeasure };
                dispatch(updateProjectAssessment(upData, data[index]._id));
                setcontrolMeasure("");
                handleClose();
              }}
              text={data[index]?.controlMeasure ? "Edit" : "Add"}
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={hazardOpen}
          onClose={() => setHazardOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hazard"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                variant="filled"
                label="Hazard"
                value={hazard}
                onChange={(e) => {
                  // setField("partName", e.target.value);
                  // setpartName(e.target.value);
                  setHazard(e.target.value);
                }}
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let upData = { hazard: hazard };
                dispatch(updateProjectAssessment(upData, data[index]._id));
                setHazard("");
                setHazardOpen(false);
              }}
              text={data[index]?.hazard ? "Edit" : "Add"}
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={hazardListOpen}
          onClose={() => setHazardListOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">{"Hazards"}</DialogTitle>
          <DialogContent>
            <Table
              header={TABLE_HEADER_HAZARDLIST}
              data={hazardList}
              onDelete={(i, e, row) => {
                let list = hazardList;
                delete list[i];
                setHazardList(processHazardList(list));
              }}
              onEdit={(i, row) => {
                // console.log(row)
              }}
              editBtn={false}
              addRow={true}
              addRowText="Add More"
              addRowMarginRight={'4rem'}
              onAddRow={(e) => {
                let list = hazardList;
                list.push({ errors: {} });
                setHazardList(processHazardList(list));
              }}
            />
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={(e) => {
                  let err = false;
                  let list = hazardList.map(a => {
                    a.errors = {};
                    if (!a.hazard) {
                      err = true;
                      a.errors.hazard = 'Hazard is required!';
                    }
                    if (!a.controlMeasure) {
                      err = true;
                      a.errors.controlMeasure = 'Control Measure is required!';
                    }
                    return a;
                  });
                  if (!err) {
                    let upData = { hazardList: hazardList.filter(a => a.hazard && a.controlMeasure).map(a => ({ hazard: a.hazard, controlMeasure: a.controlMeasure })) };
                    dispatch(updateProjectAssessment(upData, data[index]._id));
                    setHazardList([]);
                    setHazardListOpen(false);
                  } else {
                    // console.log(list)
                    setHazardList(processHazardList(list))
                  }
                }}
                text={data[index]?.hazard ? "Edit" : "Submit"}
                variant="contained"
                color="primary"
              />
            </DialogActions>
          </DialogContent>

        </Dialog>
      </div>
      <div>
        <Dialog
          open={openReview}
          onClose={handleCloseReview}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Review"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Table
                header={TABLE_HEADER_2}
                data={data}
                actions={false}
                color={true}
                onStart={() => {
                  // history.push("/action");
                }}
              />
              {equipments.filter(a => a.checked).length ?
                <Typography>
                  <Typography style={{ display: 'inline' }}>Safety Equipments Available: </Typography>
                  <Typography style={{ fontWeight: 'bold', display: 'inline' }}>{equipments.filter(a => a.checked).map(a => a.name).join()}</Typography>
                </Typography>
                : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let upData = {
                  status: "Awaiting to Risk Review",
                  assessmentDone: new Date(),
                  auditOn: new Date(),
                  auditBy: brm._id || null,
                  safetyEquipments: equipments.filter(a => a.checked).map(a => a.name)
                };
                if (projectDetails.assessmentAssignTo?.role?.name === ADMIN) {
                  upData.auditBy = projectDetails.assessmentAssignTo?._id
                }
                dispatch(updateProject(upData, projectId));
                handleCloseReview();
              }}
              text="Submit"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}