import React, { useEffect } from "react";
import { TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_REVIEW } from "./CONSTANTS";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { ActionTable, Button, Checkbox, IconButton, Pagination, Table } from "../../../commons/mui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProject, updateProject, clearProjectData } from "../../../redux/actions/project";
import { clearData, listProjectAssessment, updateProjectAssessment } from "../../../redux/actions/projectAssessment";
import { Alert } from "@material-ui/lab";
import { EQUIPMENTS } from "../../Associate/ActionScreen/CONSTANTS";
import { ASSOCIATE, BRM, PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import axios from "axios";
import { GET_PROJECT } from "../../../redux/action_types";
import { ArrowBack, Done } from "@material-ui/icons";
import { ProjectDetailComponent } from "../../Projects";


export default function Index() {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [dataKey, setDataKey] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openReview, setOpenReview] = React.useState(false);
  const [controlMeasure, setcontrolMeasure] = useState("");
  const [projectData, setProjectData] = useState({});
  const auth = useSelector((state) => state.auth);
  const [index, setindex] = useState(null);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const projectAssessments = useSelector((state) => state.projectAssessments);
  const [page, setPage] = useState(1);
  const [equipments, setEquipments] = useState(EQUIPMENTS);
  const [isValid, setIsValid] = useState(false);
  const [processedAssesmentList, setProcessedAssesmentList] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (projectId) {
      dispatch(getProject(projectId));
    }
  }, [])
  useEffect(() => {
    if (projectData.auditDone) {
      // setTimeout(() => {
      // if (auth.data?.role?.name === BRM)
      //   history.push('/projects');
      // if (auth.data?.role?.name === ASSOCIATE)
      //   history.push('/associate');
      // if (auth.data?.role?.name === PDSLADMIN && axios.defaults.headers.common["Company"])
      //   history.push('/company-project-list/' + axios.defaults.headers.common["Company"]);
      // }, 5000)
    }
  }, [projectData])
  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail?.data);
      setEquipments(EQUIPMENTS.map(a => { a.checked = project.detail?.data.safetyEquipments.indexOf(a.name) == -1 ? false : true; return a; }))
      dispatch(
        // listProjectAssessment({ project: project.detail?.data?._id, skip: (page - 1) * 10 })
        listProjectAssessment({ project: project.detail?.data?._id, limit: 0 })
      );
    }
    if (project?.update?.data && project?.update?.data._id) {
      // console.log(project)
      dispatch(clearProjectData());
      dispatch(getProject(projectId));
      if (project?.update?.data.auditDone) {
        // setTimeout(() => {
        if (auth.data?.role?.name === BRM)
          history.push('/projects');
        if (auth.data?.role?.name === ASSOCIATE)
          history.push('/associate');
        if (auth.data?.role?.name === PDSLADMIN && axios.defaults.headers.common["Company"])
          history.push('/company-project-list/' + axios.defaults.headers.common["Company"]);
        // }, 5000)
      }
    }
  }, [project])
  useEffect(() => {
    if (projectAssessments.list?.data) {
      let list = projectAssessments.list?.data.reverse()
      // .map(a => {
      //   a.sort = a.assessmentAnswer === 'Yes' ? 1 : 0;
      //   return a
      // }).sort((a, b) => a.sort - b.sort);
      // console.log(list.filter(a => a.assessmentAnswer === 'Yes' || a.riskAcceptable).length, list.filter(a => a.assessmentQuestion.type === 'Option').length);
      if (list.filter(a => a.assessmentAnswer === 'Yes' || a.riskAcceptable).length === list.filter(a => a.assessmentQuestion.type === 'Option').length)
        setIsValid(true)
      else {
        setIsValid(false)
      }
      let ref = 0, dref = 0;
      let datta = list.map((itm) => {
        if (itm.assessmentQuestion.type !== 'Option') {
          dref = dref + 1;
          ref = dref;
          itm.sno = dref;
          itm.type = itm.assessmentQuestion.type;
        } else {
          ref = ref + 0.1;
          itm.sno = Number(ref).toFixed(1);
          itm.type = itm.assessmentQuestion.type;
        }
        itm.question = itm.assessmentQuestion.question;
        return itm;
      });
      // console.log(datta)
      setdata(datta.map((a, i) => {
        a.question = a.assessmentQuestion.question;

        a.riskAcceptableView = (
          a.assessmentAnswer == 'No' ?
            <div>
              {/* {a.riskAcceptable ? <><Typography style={{ fontWeight: 'bold' }}>{a.riskAcceptable}</Typography><br /></> : null} */}

              <Button
                onClick={(e) => {
                  let upData = { riskAcceptable: 'Yes' };
                  dispatch(updateProjectAssessment(upData, a._id));
                  setTimeout(() => {
                    // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
                    listProjectAssessment({ project: projectId, limit: 0 })
                  }, 100)
                }}
                text="Yes"
                variant="contained"
                disabled={a.riskAcceptable == 'Yes' || projectData.auditDone}
                color="primary"

              />
              <Button
                onClick={(e) => {
                  let upData = { riskAcceptable: 'No' };
                  dispatch(updateProjectAssessment(upData, a._id));
                  setTimeout(() => {
                    // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
                    listProjectAssessment({ project: projectId, limit: 0 })
                  }, 100)
                }}
                text="No"
                variant="contained"
                color="primary"
                disabled={a.riskAcceptable == 'No' || projectData.auditDone}
              />
            </div>
            : a.type === 'Option' ? <Typography style={{ fontWeight: 'bold' }}>No Risk Identified</Typography> : null
        );
        return a;
      }));
    }
    if (projectAssessments?.update?.data) {
      dispatch(clearData())
      dispatch(
        // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
        listProjectAssessment({ project: projectId, limit: 0 })
      );
    }
  }, [projectAssessments])
  useEffect(() => {
    dispatch(
      // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
      listProjectAssessment({ project: projectId, limit: 0 })
    );
  }, [page]);
  useEffect(() => {
    let plist = data.filter(a => a.assessmentAnswer && a.assessmentAnswer == 'No');
    let processList = [];
    // console.log(plist,data)
    plist.map(itm => {
      if (itm.hazardList?.length) {
        // processList.push(itm);
        let ref = 0;
        // console.log(itm, itm.hazardList,itm.sno);
        itm.hazardList.map((hz, hzIndex) => {
          ref = ref + 1;
          let row = { ...itm };
          row.sno = row.sno + '.' + ref;
          // console.log(row.sno,"-----", row.sno,"----", ref);
          row.hazard = hz.hazard;
          row.controlMeasure = hz.controlMeasure;
          // console.log(row, ref)
          row.riskAcceptableView = (
            row.assessmentAnswer == 'No' ?
              <div>
                {/* {a.riskAcceptable ? <><Typography style={{ fontWeight: 'bold' }}>{a.riskAcceptable}</Typography><br /></> : null} */}

                <Button
                  onClick={(e) => {
                    let list = itm.hazardList.map((iit, iitindex) => {
                      if (iitindex === hzIndex) {
                        iit.riskAcceptable = 'Yes'
                      }
                      return iit;
                    });
                    let upData = { hazardList: list };
                    if (list.filter(ll => ll.riskAcceptable === 'No').length && list.filter(ll => ll.riskAcceptable).length === list.length) {
                      upData.riskAcceptable = 'No';
                    }
                    if (list.filter(ll => ll.riskAcceptable === 'Yes').length === list.length) {
                      upData.riskAcceptable = 'Yes';
                    }
                    // console.log(upData.riskAcceptable, list.filter(ll => ll.riskAcceptable === 'Yes').length, list.length, list.filter(ll => ll.riskAcceptable === 'No').length, list.filter(ll => ll.riskAcceptable).length)
                    dispatch(updateProjectAssessment(upData, row._id));
                    setTimeout(() => {
                      // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
                      listProjectAssessment({ project: projectId, limit: 0 })
                    }, 100)
                  }}
                  text="Yes"
                  variant="contained"
                  disabled={hz.riskAcceptable == 'Yes' || projectData.auditDone}
                  color="primary"

                />
                <Button
                  onClick={(e) => {
                    let list = itm.hazardList.map((iit, iitindex) => {
                      if (iitindex === hzIndex) {
                        iit.riskAcceptable = 'No'
                      }
                      return iit;
                    });
                    let upData = { hazardList: list };
                    if (list.filter(ll => ll.riskAcceptable === 'No').length && list.filter(ll => ll.riskAcceptable).length === list.length) {
                      upData.riskAcceptable = 'No';
                    }
                    if (list.filter(ll => ll.riskAcceptable === 'Yes').length === list.length) {
                      upData.riskAcceptable = 'Yes';
                    }
                    // console.log(upData.riskAcceptable, list.filter(ll => ll.riskAcceptable === 'Yes').length, list.length, list.filter(ll => ll.riskAcceptable === 'No').length, list.filter(ll => ll.riskAcceptable).length)
                    dispatch(updateProjectAssessment(upData, row._id));
                    setTimeout(() => {
                      // listProjectAssessment({ project: projectId, skip: (page - 1) * 10 })
                      listProjectAssessment({ project: projectId, limit: 0 })
                    }, 100)
                  }}
                  text="No"
                  variant="contained"
                  color="primary"
                  disabled={hz.riskAcceptable == 'No' || projectData.auditDone}
                />
              </div>
              : row.type === 'Option' ? <Typography style={{ fontWeight: 'bold' }}>No Risk Identified</Typography> : null
          );
          processList.push(row);
          return hz;
        })
      } else {
        // processList.push(itm);
      }
      return itm;
    });
    // console.log(processList)
    setProcessedAssesmentList(processList);
  }, [data]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenReview = () => {
    setOpenReview(true);
  };

  const handleCloseReview = () => {
    setOpenReview(false);
  };

  return (
    <div>
      <Grid container>
        <Grid style={{ margin: "0" }}>
          <h2>
            <IconButton
              color="primary"
              onClick={() => {
                history.push("/projects");
              }}
              icon=<ArrowBack />
            ></IconButton>
            Audit By {auth.data.firstName ? (auth.data.firstName + (auth.data.lastName ? ' ' + auth.data.lastName : '')) : auth.data.emailId}
          </h2>
        </Grid>
        <ProjectDetailComponent margin="1rem 0.1rem" data={projectData} show={['projectNo', 'rev', 'sapNo', 'companyName', 'poNo', 'workLocation', '']} additional={(
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Environment :
            </Typography>
            <Typography>Quality CC</Typography>
          </Grid>
        )}></ProjectDetailComponent>
      </Grid>
      <Grid index style={{ marginTop: "1rem" }}>
        {projectData.auditDone ?
          <Grid style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Alert severity="success">Audit Done for this project!</Alert>
          </Grid>
          : null
        }
        <ActionTable
          actions={false}
          header={TABLE_HEADER_REVIEW}
          data={data}
          hazardColumn={false}
          controlMeasureColumn={false}

          onControlMeasure={(i) => {
            setDataKey('controlMeasure')
            setindex(i);
            handleClickOpen();
          }}
          color={true}
          onDeleteControlMeasure={(i) => {
            let upData = { controlMeasure: '' };
            dispatch(updateProjectAssessment(upData, data[i]._id));
          }}
        />
        {projectAssessments.list.totalCount > projectAssessments.list.pagination.to ?

          <Grid index style={{ marginTop: "1rem" }}>
            <div className="ui-component-block">
              <div className="ui-components">
                <Pagination
                  count={projectAssessments.list.totalCount}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </Grid>
          : null}
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Typography variant="h5" style={{ justifyContent: 'center', textAlign: 'center', padding: '2rem 2rem' }}>If 'No' has been entered for any of the questions above, please explain variation(s) and confirm actions
          authorised and taken below to mitigate resulting risk(s)</Typography>
        <Table
          header={TABLE_HEADER}
          data={processedAssesmentList}
          actions={false}
          color={true}
          onStart={() => {
            // history.push("/action");
          }}
        />

      </Grid>

      <div key={'div-1'}>
        <Grid index style={{ margin: "1rem 1rem" }}>
          <b> PPE indicate with <Done style={{ color: 'green' }} /> the PPE (Personal Protective Equipment) Required</b>
        </Grid>
        <Grid index container>
          {equipments.map((item, i) => (
            <Grid item sm={3} xs={12} className="safety-checkboxes review">
              <Checkbox
                color="primary"
                checked={item.checked}
                onChange={(e) => {
                  // let list = [...equipments]
                  // list[i].checked = e.target.checked;
                  // setEquipments([...list]);
                }}
                style={{ height: "36px", }}
                value={item.name}
                name={'safety-checkboxes'}
                text={(
                  <Grid className={item.checked ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"}>
                    <img className="checkbox-img" src={item.image} width={70} />
                    <Typography>{item.name}</Typography>
                    <Done />
                  </Grid>
                )}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid index style={{ marginTop: "1rem", textAlign: "center" }}>
        <Button
          onClick={(e) => {
            //   onEdit(i, row);
            // settoggle(true);
            if (processedAssesmentList.filter(a => a.riskAcceptable == 'No').length)
              handleClickOpenReview();
            else {
              let upData = { status: 'Awaiting Review E-Sign BRM', auditDone: new Date() };
              dispatch(updateProject(upData, projectId));
              handleCloseReview();
            }
          }}
          text="Submit"
          variant="contained"
          color="primary"
          style={{ marginBottom: ".5rem" }}
          disabled={projectData.auditDone || !isValid}
        />
      </Grid>
      <div key={'div-2'}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dataKey == 'controlMeasure' ? "Control Measure" : 'Hazard'}</DialogTitle>
          <DialogContent>
            <TextField
              variant="filled"
              label={dataKey == 'controlMeasure' ? "Control Measure" : 'Hazard'}
              value={controlMeasure}
              onChange={(e) => {
                // setField("partName", e.target.value);
                // setpartName(e.target.value);
                // console.log(e.target.value)
                setcontrolMeasure(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                // console.log(controlMeasure, index, dataKey);
                let upData = { [dataKey]: controlMeasure };
                dispatch(updateProjectAssessment(upData, data[index]._id));
                setcontrolMeasure("");
                handleClose();
              }}
              text="Submit"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
      <div key={'div-3'}>
        <Dialog
          open={openReview}
          onClose={handleCloseReview}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Review"}</DialogTitle>
          <DialogContent>
            <TextField
              variant="filled"
              label="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let upData = { status: 'Safety Issue', auditDone: new Date(), comment: comment };
                dispatch(updateProject(upData, projectId));
                handleCloseReview();
              }}
              text="Submit"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
