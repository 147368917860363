import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, makeStyles, styled, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getPlan, updatePlan } from "../../../redux/actions/projectPlan";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import LinearProgress from '@material-ui/core/LinearProgress';
import { clearProjectData, getProject, updateProject } from "../../../redux/actions/project";
import { ActionTable, AutoComplete, Button, IconButton, Table, TextField } from "../../../commons/mui";
import { clearTechnicianPlanData, listTechnicianPlans, updateTechnicianPlan } from "../../../redux/actions/technicianPlan";
import { ArrowBack, ArrowRightAlt, KeyboardBackspace, CloudUpload as CloudUploadIcon, Close } from "@material-ui/icons";
import { CHECKLIST_TABLE_HEADER, TABLE_HEADER, TABLE_HEADER_ACTIVITY } from "./CONSTANTS";
import { ADD_FILE, GET_PROJECT, LIST_TECHNICIAN_WORK_PLAN, UPDATE_TECHNICIAN_WORK_PLAN } from "../../../redux/action_types";
import moment from "moment";
import { ProjectDetailComponent } from "../../Projects";
import { listVehicles } from "../../../redux/actions/vehicle";
import { clearFileData, uploadFiles } from "../../../redux/actions/file";
import { DIALOGS } from "../../../commons/CONSTANTS";
import { listTechnicianPlanLogs } from "../../../redux/actions/technicianPlanLog";
import ForwardIcon from '@material-ui/icons/Forward';
import { addCheckpointLog, listCheckpointLogs } from "../../../redux/actions/objectCheckpointLog";

const useStyles = makeStyles((theme) => ({
  divider: {
    borderRight: "1px solid #838584",
    // height: '80vh',
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  align: {
    textAlign: "center",
  },
  space: {
    marginBottom: ".5rem",
  },
  selected: {
    cursor: "pointer",
    border: ".2rem solid green",
  },
  nokselected: {
    cursor: "pointer",
    border: ".2rem solid red",
  },
  notSelected: {
    cursor: "pointer",
  },
  btn: {
    // position: "absolute",
    width: "100%",
    textAlign: 'center',
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      bottom: "-5rem"
    },
    [theme.breakpoints.up("sm")]: {
      // position: "inline",
      bottom: "0rem"
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);



export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [list, setlist] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [technicianPlanData, setTechnicianPlanData] = useState({});
  const [planDetailList, setPlanDetailList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [allOk, setallOk] = useState(false);
  const [action, setAction] = useState(false);
  const { planId } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.projectPlan);
  const project = useSelector((state) => state.project);
  const technicianPlan = useSelector((state) => state.technicianPlan);
  const technicianPlanHistory = useSelector((state) => state.technicianPlanHistory);
  const objectCheckpointLogs = useSelector((state) => state.objectCheckpointLog);
  const uploads = useSelector((state) => state.file);
  const auth = useSelector((state) => state.auth);
  const vehicles = useSelector(state => state.vehicle);
  const localCompany = useSelector((state) => state.localCompany);
  const [open, setOpen] = useState(false);
  const [okSelected, setOkSelected] = useState(false);
  const [isRectify, setIsRectify] = useState(false);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activeSide, setActiveSide] = useState("");
  const [vin, setVin] = useState({});
  const [sides, setSides] = useState([]);
  const [actualGrids, setActualGrids] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [allCheckList, setAllCheckList] = useState([]);
  const [allVehicleList, setAllVehicleList] = useState([]);
  const [selectedGridRow, setSelectedGridRow] = useState(null);
  const [selectedGridColumn, setSelectedGridColumn] = useState(null);
  const [selectedCheckPoint, setSelectedCheckPoint] = useState(null);
  const [selectedCheckPointPart, setSelectedCheckPointPart] = useState(null);
  const [checkListOpen, setCheckListOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPlanDetails, setOpenPlanDetails] = useState(false);
  const [checkListTitle, setCheckListTitle] = useState('');
  const [document, setDocument] = useState('');
  const [checkpointLog, setCheckpointLog] = useState([]);
  const [showInfoDialog, setShowInfoDialog] = useState({
    title: "sample",
    open: false,
    children: <div></div>,
    btn1: {
      text: "Close",
      onClick: () => { },
    },
  });
  const [selectedActivity, setSelectedActivity] = useState({});

  useEffect(() => {
    // console.log(uploads)
    if (
      uploads.add &&
      uploads.add.data &&
      uploads.add.data &&
      uploads.add.data.data &&
      uploads.add.data.data.length
    ) {
      dispatch(clearFileData(ADD_FILE));
      setUploadedFiles(uploads.add.data.data.map(a => a.key));
      setFiles([]);
    }
  }, [uploads]);
  useEffect(() => {
    if (objectCheckpointLogs.list?.data?.length) {
      setCheckpointLog(objectCheckpointLogs.list?.data)
    }
  }, [objectCheckpointLogs]);
  useEffect(() => {
    setCheckList(processCheckList(checkList.map(a => {
      a.history = checkpointLog.filter(aa => aa.side === activeSide && aa.selectedRow === selectedGridRow && aa.selectedColumn === selectedGridColumn && aa.selectedCheckpoint === selectedCheckPoint)
      return a;
    })))
  }, [checkpointLog])
  useEffect(() => {
    // console.log(uploadedFiles)
    if (
      uploadedFiles.length
    ) {
      handleAction(allOk ? true : false, allOk ? false : true, false, false);
    }
  }, [uploadedFiles]);
  useEffect(() => {
    let check = true;
    list?.map((itm) => {
      if (!itm.selected || itm.selected == "notOkImage") {
        check = false;
      }
    });
    setallOk(check);
  }, [list]);

  useEffect(() => {
    if (localCompany.companyId?.data) {
      setTimeout(() => {
        if (planId) {
          dispatch(getPlan(planId));
        }
        dispatch(listVehicles({ limit: 0 }));
      }, 100);
    }
  }, [localCompany]);
  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail?.data);
    }
  }, [project]);
  useEffect(() => {
    if (vehicles.list?.data?.length) {
      setAllVehicleList(vehicles.list?.data?.map((a) => {
        a.name = a.vin;
        return a;
      }));
    }
  }, [vehicles]);
  useEffect(() => {
    if (list.length) {
      let selected = list.filter(a => a.selected);
      setAction(selected.length == list.length ? true : false)
    }
  }, [list]);
  useEffect(() => {
    let tplanList = [];
    if (technicianPlanData._id) {
      // console.log(technicianPlanData)
      let per = technicianPlanData.ok + technicianPlanData.nok + technicianPlanData.skip;
      // console.log(per);
      per = (per * 100) / technicianPlanData.quantity;
      // console.log(per);
      setProgress(per);
      let tplan = technicianPlanData || {};
      if (tplan._id) {
        let tplanList = [
          { name: 'Total Amount to be Checked', value: tplan.quantity },
        ];
        if (tplan.target) {
          tplanList.push({ name: 'Target Amount to be Checked', value: tplan.target });
        }
        tplanList.push({
          name: 'NOK Total', value: (
            <Typography style={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }} >{tplan.nok}</Typography>
          )
        });
        tplanList.push({
          name: 'Total OK', value: (
            <Typography style={{ fontSize: '16px', fontWeight: 'bold', color: 'green' }} >{tplan.ok}</Typography>

          )
        });
        tplanList.push({ name: 'Pending', value: tplan.pending });
        setPlanDetailList(tplanList);
        if (technicianPlanData.quantity - (technicianPlanData.ok + technicianPlanData.nok) == 0 && technicianPlanData.workPlanId.status !== 'Completed') {
          dispatch(updatePlan({ status: "Completed" }, technicianPlanData.workPlanId._id));
          dispatch(updateProject({ status: "Completed" }, technicianPlanData.workPlanId.project));
        }
      }
    }
  }, [technicianPlanData]);
  useEffect(() => {
    if (technicianPlan.list?.data?.length) {
      dispatch(clearTechnicianPlanData(LIST_TECHNICIAN_WORK_PLAN))

      setTechnicianPlanData({
        ...technicianPlanData, ...(technicianPlan.list?.data.map(a => {
          a.quantity = a.workPlanId.quantity;
          a.skip = a.workPlanId.skip || a.skip || 0;
          a.ok = a.workPlanId.ok || a.ok || 0;
          a.nok = a.workPlanId.nok || a.nok || 0;
          a.pending = (a.workPlanId.quantity || a.quantity) - ((a.workPlanId.ok || a.ok) + (a.workPlanId.nok || a.nok) + (a.workPlanId.skip || a.skip));
          a.pending = a.pending < 0 ? 0 : a.pending;
          return a;
        })[0] || {})
      });

    }
    if (technicianPlan.update?.data?._id) {
      dispatch(clearTechnicianPlanData(UPDATE_TECHNICIAN_WORK_PLAN))
      dispatch(listTechnicianPlans({ workPlanId: plan?.detail?.data?._id, userId: auth.data?._id, limit: 0 }));
      // dispatch(getPlan(planId));
      setallOk(false);
      setLoading(false)
      setUploadedFiles([]);
      setComment("");
      setOpen(false);
      setCheckListOpen(false);
      setlist([...list.map(a => { a.selected = ''; return a; })]);

      setAction(false);

    }
  }, [technicianPlan]);
  useEffect(() => {
    if (sides && sides.length && !activeSide)
      setActiveSide(sides[0]);
  }, [sides]);

  useEffect(() => {
    // console.log('plan-----', plan);
    if (plan?.detail?.data?._id) {
      setSides(plan?.detail?.data?.grid?.images.map(a => a.side));
      if (plan?.detail?.data?.project?._id)
        dispatch(getProject(plan?.detail?.data?.project?._id));
      dispatch(listTechnicianPlans({ workPlanId: plan?.detail?.data?._id, userId: auth.data?._id, limit: 0 }));
      if (plan?.detail?.data?.part?._id)
        setlist(
          plan?.detail?.data?.part?.illustration?.map((itm) => {
            itm.selected = "";
            return itm;
          })
        );
      // setTechnicianPlanData({
      //   ...technicianPlanData, ...{
      //     skip: plan?.detail?.data?.skip || 0,
      //     quantity: plan?.detail?.data?.quantity || plan?.detail?.data?.part?.quantity || 0,
      //     target: plan?.detail?.data?.part?.target || 0,
      //     ok: plan?.detail?.data?.ok || 0,
      //     nok: plan?.detail?.data?.nok || 0,
      //     pending: plan?.detail?.data?.quantity - (plan?.detail?.data?.ok + plan?.detail?.data?.nok + plan?.detail?.data?.skip),
      //     _id: plan?.detail?.data?._id
      //   }
      // });
      setDocument(plan?.detail?.data?.eversignList[0]?.meta?.finalPdf);

    }
  }, [plan]);


  const handleAction = (ok, nok, skip, upload = false) => {
    if (upload && files.length) {
      setLoading(true)
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      dispatch(uploadFiles(formData));
    } else {
      let illustration = [...list];
      // console.log(illustration)
      let tplan = technicianPlanData;
      let data = {
        ok: tplan.ok,
        nok: tplan.nok,
        skip: tplan.skip,
        comment: comment || '',
        // grids: grids,
        illustration: illustration.map(a => {
          return a;
        })
      };
      if (ok)
        data = { ...data, ok: data.ok + 1, okItem: 1, nokItem: 0, skipItem: 0 };
      if (nok)
        data = { ...data, nok: data.nok + 1, nokItem: 1, okItem: 0, skipItem: 0 };
      if (skip)
        data = { ...data, skip: data.skip + 1, skipItem: 1, okItem: 0, nokItem: 0 };
      if (uploadedFiles.length) {
        data.images = uploadedFiles;
      }

      // console.log('=====', isRectify, selectedGridRow, selectedGridColumn, selectedCheckPoint, comment, data)

      setOpen(false);
      setIsRectify(false);
      // console.log(tplan, data);
      if (tplan._id) {
        let logData = {
          technicianWorkPlan: tplan._id,
          side: activeSide,
          selectedRow: selectedGridRow,
          selectedColumn: selectedGridColumn,
          selectedCheckpoint: selectedCheckPoint,
          vin: vin.vin || '',
          illustration: data.illustration,
          ok: data.ok,
          nok: data.nok,
          skip: data.skip,
        }
        if (data.images && data.images.length) {
          logData.images = data.images
        }
        if (isRectify && comment) {
          logData.rectifyComment = comment
        } else if (comment) {
          logData.comment = comment
        }
        // dispatch(addCheckpointLog(logData))

        dispatch(updateTechnicianPlan(data, tplan._id));

      }
    }

  }
  const handleCheckList = (itm, i, j) => {
    // console.log(vin);
    if (vin.vin) {
      setCheckList(processCheckList(itm.checkList))
      setSelectedGridRow(i);
      setSelectedGridColumn(j);
      setCheckListTitle(itm.tag);
      dispatch(listCheckpointLogs({
        technicianWorkPlan: technicianPlanData._id,
        vin: vin.vin
      }))
      // setCheckListOpen(true)
    } else {
      setShowInfoDialog((old) => ({
        ...old,
        title: "VIN",
        open: true,
        children: <Typography>You have select VIN first</Typography>,
      }));
    }
  }
  const uploadSelectedFiles = (files, k) => {
    setSelectedCheckPoint(k);
    setLoading(true)
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    dispatch(uploadFiles(formData));
  }
  const processCheckList = (list) => {
    return list.map((item, k) => {
      item.partView = item.part?.name || '';
      if (item.ok) {
        item.color = 'greenColor'
      } else if (item.nok && item.rectifyComment) {
        item.color = 'amberColor'
      } else if (item.nok && !item.rectifyComment) {
        item.color = 'redColor'
      } else {
        item.color = ''
      }
      if (item.part?._id) {

        item.status = (
          item.part?.illustration?.length !== item.part?.illustration?.filter(a => a.selected || a.checked).length ?
            <Button
              onClick={(e) => {
                setSelectedCheckPoint(k);
                // setCheckListOpen(true)
              }}
              text="Select"
              variant="contained"
              color="primary"
            />
            :
            item.part?.illustration?.filter(a => a.selected == 'notOkImage').length ?
              <Grid style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <Typography style={{ width: 50, float: 'left' }}>Nok</Typography>
                {item.rectifyComment ? null :
                  <Button
                    onClick={(e) => {
                      setSelectedCheckPoint(k);
                      setIsRectify(true)
                      setOpen(true)
                      // setCheckListOpen(true)
                    }}
                    text="Rectify"
                    variant="contained"
                    color="primary"
                    style={{ width: 'fit-content' }}
                  />
                }
              </Grid> : <>
                <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
                {item.images && item.images.length ? null : <><input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file-ok"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    uploadSelectedFiles(e.target.files, k)
                  }}
                />
                  <label htmlFor="contained-button-file-ok">
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                    </Button>
                  </label>
                </>
                }
              </>
        );
      } else
        item.status = !item.ok && !item.nok ? (
          <div>
            <Button
              onClick={(e) => {
                let list1 = [...list];
                // console.log(list1, list1[k]);
                list1[k].checked = true;
                list1[k].ok = 1;

                setTimeout(() => {
                  handleAction(allOk ? true : false, allOk ? false : true, false, true)
                }, 100)
              }}
              text="Ok"
              variant="contained"
              color="primary"
            />
            <Button
              onClick={(e) => {
                setSelectedCheckPoint(k);
                setCheckListOpen(true)
              }}
              text="Nok"
              variant="contained"
              color="primary"
            />
          </div>
        ) : item.ok ? <>
          <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
          {item.images && item.images.length ? null : <><input
            accept="image/*"
            className={classes.input}
            id="contained-button-file-ok"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              uploadSelectedFiles(e.target.files, k)
            }}
          />
            <label htmlFor="contained-button-file-ok">
              <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

              </Button>
            </label>
          </>
          }
        </> : <Grid style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <Typography style={{ width: 50, float: 'left' }}>Nok</Typography>
          {item.rectifyComment ? null :
            <Button
              onClick={(e) => {
                setSelectedCheckPoint(k);
                setIsRectify(true)
                setOpen(true)
                // setCheckListOpen(true)
              }}
              text="Rectify"
              variant="contained"
              color="primary"
              style={{ width: 'fit-content' }}
            />
          }
        </Grid>;
      // console.log(item)
      return item;
    });
  }
  return (
    <Grid container className="technician-action">
      <Grid item sm={12}>
        <h2>
          <IconButton
            color="primary"
            onClick={() => history.push(`/inspector-workplan-list`)}
            icon=<ArrowBack />
            props={{
              style: {}
            }}
          ></IconButton>
          {projectData?.name} Work Plan - {plan.detail?.data?.name}
        </h2>
      </Grid>
      <Grid item container>
        <Grid item sm={6} style={{ borderRight: '1px solid' }}>
          <Grid container>
            <ProjectDetailComponent data={projectData} show={[]} margin="0" additional={
              <>
                <Grid item sm={8} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    PDSL Project No. :
                  </Typography>
                  <Typography>{projectData?.projectNo}</Typography>
                </Grid>
                <Grid item sm={4} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    Rev :
                  </Typography>
                  <Typography>{projectData?.rev}</Typography>
                </Grid>
                <Grid item sm={12} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    SAP/Customer Ref :
                  </Typography>
                  <Typography>{projectData?.sapNo}</Typography>
                </Grid>
              </>
            }></ProjectDetailComponent>
            {plan?.detail?.data?.part?.name ?
              <Grid item sm={12} className={`${classes.align}`}>
                <Grid item style={{ textAlign: "center", position: 'relative' }} className="main-image">
                  <img
                    src={axios.defaults.baseURL + "/file/" + plan?.detail?.data?.part?.image}
                    width={150}
                    height={150}
                  />
                  <KeyboardBackspace style={{ position: 'absolute', top: '50px', fontSize: '55px', color: 'green' }} />
                  <Typography style={{ position: 'absolute', top: '45px', marginLeft: '3.5rem' }} variant="span">
                    {plan?.detail?.data?.part?.name}<br />
                    {plan?.detail?.data?.part?.partNo}<br />
                    (Please See Samples)
                  </Typography>
                </Grid>
                <Grid item container style={{ justifyContent: 'center' }}>
                  <Grid item sm={6} style={{ textAlign: "center" }}>
                    <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                      <Grid
                        item
                        style={{ background: "red", fontWeight: "bold", padding: '10px 30px', color: '#fff' }}
                      >

                        Not Ok Image (Example)
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center', }}>
                      {list?.map((itm, i) => (
                        <Grid item sm={12}>
                          <img
                            src={axios.defaults.baseURL + "/file/" + itm.notOkImage}
                            width={150}
                            height={150}
                            style={{ marginBottom: '2rem' }}
                            className={
                              !itm.selected
                                ? `${classes.notSelected}`
                                : itm.selected == "notOkImage"
                                  ? `${classes.nokselected}`
                                  : `${classes.notSelected}`
                            }
                            onClick={() => {
                              if (progress < 100) {
                                setOkSelected(false);
                                list[i].selected = "notOkImage";
                                setlist([...list]);
                              }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item sm={6} style={{ textAlign: "center" }}>
                    <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                      <Grid
                        item
                        style={{ background: "green", fontWeight: "bold", padding: '10px 30px', color: '#fff' }}
                      >

                        Ok Image (Example)
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center' }}>
                      {list?.map((itm, i) => (
                        <Grid item sm={12}>
                          <img
                            src={axios.defaults.baseURL + "/file/" + itm.okImage}
                            width={150}
                            height={150}
                            style={{ marginBottom: '2rem' }}
                            className={
                              !itm.selected
                                ? `${classes.notSelected}`
                                : itm.selected == "okImage"
                                  ? `${classes.selected}`
                                  : `${classes.notSelected}`
                            }
                            onClick={() => {
                              if (progress < 100) {
                                setOkSelected(true);
                                list[i].selected = "okImage";
                                setlist([...list]);
                              }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              : null
            }
          </Grid>
        </Grid>
        <Grid item sm={6} style={{ paddingLeft: "1rem", position: 'relative', minHeight: '30rem' }}>
          <Grid item container>

            <Grid item xs={12} style={{ display: "flex", marginBottom: 10 }}>
              <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Batch/Production Date(If Applicable):
              </Typography>
              <Typography item>{plan?.detail?.data?.batch}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", marginBottom: 10 }}>
              <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Delivery/Advice No(If Applicable):
              </Typography>
              <Typography item>{moment(projectData.expectedEndDate).format('YYYY-MM-DD')}</Typography>
            </Grid>

            <Grid item xs={12} style={{ display: "flex", marginBottom: 10 }}>
              <Typography item style={{ fontWeight: "bold", marginRight: ".5rem", width: '100%' }}>
                Plan details
              </Typography>
              {/* <Button variant="contained" color="primary" onClick={() => setOpenPlanDetails(true)} text='View' /> */}

            </Grid>
            <Grid item xs={12} container spacing={2} style={{ display: "flex", marginBottom: 10 }}>
              <ActionTable
                header={TABLE_HEADER_ACTIVITY}
                data={plan.detail?.data?.activity}
                actions={false}
                hazardColumn={false}
                controlMeasureColumn={false}
                color={true}
                style={{ maxHeight: 275 }}
              />

            </Grid>
            <Grid item xs={12} style={{}}>
              <Grid item sm={12} style={{}}>
                <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                  Task Progress
                </Typography>
              </Grid>
              <Grid item xs={12} style={{}}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                {<>
                  <Table
                    header={TABLE_HEADER}
                    data={vin._id ? [...planDetailList, ...[{
                      name: 'VIN',
                      value: vin.vin,
                      type: 'vin'
                    }]] : planDetailList}
                    actions={false}
                  />
                  <Grid item className={classes.btn}>
                    <Grid item>
                      {technicianPlanData.quantity != (technicianPlanData.ok + technicianPlanData.nok) ?
                        <Button
                          text={allOk ? "Ok" : "Not Ok"}
                          variant="contained"
                          color="primary"
                          onClick={() => { allOk && technicianPlanData.quantity - (technicianPlanData.ok + technicianPlanData.nok) > 1 ? handleAction(allOk ? true : false, allOk ? false : true, false, true) : setOpen(true) }}
                          style={{ marginBottom: "1rem", width: "14rem" }}
                          disabled={progress >= 100 || !action}
                        /> :
                        null}
                    </Grid>

                  </Grid>
                </>
                }
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      <div key={"div-2"}>
        {DIALOGS.INFO_DIALOG({
          ...showInfoDialog,
          setOpen: setShowInfoDialog,
          container: `${classes.paddingX}`,
        })}
        <Dialog
          open={open}
          // onClose={setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{isRectify ? "Rectify Comment" : ((technicianPlanData?.nok === 2 ? 'TIP Level 3' : technicianPlanData?.nok === 3 ? 'TIP Level 4' : technicianPlanData?.nok > 3 ? 'TIP Level 5' : '') + (okSelected ? ("Completion Comment") : (technicianPlanData.quantity - (technicianPlanData.ok + technicianPlanData.nok) == 0 ? "Completion Comment" : " Defect Description")))}</DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label={okSelected ? ("Completion Comment") : (technicianPlanData.quantity - (technicianPlanData.ok + technicianPlanData.nok) == 0 ? "Completion Comment" : " Defect Description")}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Grid>
              {technicianPlanData?.nok >= 2 && technicianPlanData?.nok < 5 ?
                <Grid item xs={12} >
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => setFiles(e.target.files)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                    </Button>
                  </label>

                </Grid>
                : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setOkSelected(false);
                handleAction(allOk ? true : false, allOk ? false : true, false, true)

              }}
              text="Submit"
              variant="contained"
              color="primary"
              disabled={!comment && allCheckList.filter(a => a.part?.illustration?.filter(aa => aa.selected).length === a.part?.illustration?.length).length == 0}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          // onClose={() => setCheckListOpen(false)} 
          open={checkListOpen}>
          <DialogTitle>Grid {checkListTitle} checklist</DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label={"Defect Description"}
                  value={comment}
                  multiline
                  rows={5}
                  fullWidth
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => setFiles(e.target.files)}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                  </Button>
                </label>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'block' }}>
            <Button
              onClick={(e) => {
                setCheckListOpen(false)
              }}
              text="Cancel"
              variant="contained"
              color="default"
              style={{ float: 'left' }}
            />
            <Button
              onClick={(e) => {
                handleAction(false, true, false, true)
              }}
              text="Submit"
              variant="contained"
              color="primary"
              style={{ float: 'right' }}
              disabled={!comment && allCheckList.filter(a => a.checked).length == 0}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setOpenPlanDetails(false)}
          maxWidth={'xl'}
          open={openPlanDetails}>
          <DialogTitle>Plan details
            <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() => setOpenPlanDetails(false)} icon={<Close />} props={{ style: { float: 'right' } }}>

            </IconButton>
          </DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2}>
              <Table
                header={TABLE_HEADER_ACTIVITY}
                data={plan.detail?.data?.activity}
                actions={false}
              />
            </Grid>
          </DialogContent>

        </Dialog>
      </div>
    </Grid >
  );
}
