import {
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Backdrop, IconButton, TextField } from "../../../commons/mui";
import { TABLE_HEADER as header } from "./CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { addPart, clearPartData } from "../../../redux/actions/part";
import axios from "axios";
import { clearFileData, uploadFile } from "../../../redux/actions/file";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowBack } from "@material-ui/icons";
import { ADD_FILE, ADD_PART } from "../../../redux/action_types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
    fontSize: 12,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 13,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    // maxWidth: "20px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(even)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  roots: {
    width: 400,
  },
  root: {
    width: 200,
    display: "flex",
    justifyContent: "center",
  },
  input: {
    display: "none",
  },
  img: {
    width: 100,
    height: 100,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  imgErr: {
    color: "red",
    fontSize: ".8rem",
    marginTop: "2rem",
    marginLeft: "2rem",
  },
});

export default function Index(props) {
  const { breadcumb, onComplete } = props
  const classes = useStyles();
  const [illustration, setIllustration] = useState([]);
  const dispatch = useDispatch();
  const file = useSelector((state) => state.file);
  const history = useHistory();
  const [fields, setfields] = useState({
    name: "",
    description: "",
    quantity: "",
    target: "",
    image: null,
    illustration: [],
  });
  const [data, setData] = useState([]);
  const [actions, setactions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [imgField, setImgField] = useState("");
  const part = useSelector((state) => state.parts);
  const [errors, seterrors] = useState({});

  const handleImage = (event, img) => {
    setImgField(img);
    // console.log(img)
    let formData = new FormData();
    if (event.target.files[0]) {
      setLoading(true);
      formData.append("file", event.target.files[0]);
      dispatch(uploadFile(formData));
    }
  };

  useEffect(() => {
    if (
      file.add &&
      file.add.data &&
      file.add.data &&
      file.add.data.data &&
      file.add.data.data.key
    ) {
      dispatch(clearFileData(ADD_FILE))
      setLoading(false);
      if (imgField == "image")
        setfields({ ...fields, image: file.add.data.data.key });
      else {
        let field = imgField.split("-");
        // console.log(field)
        let ills = illustration||{};
        ills[parseInt(field[1])][field[0]] = file.add?.data?.data?.key;
        // console.log(ills)
        setIllustration(ills);
      }
    }
  }, [file]);

  useEffect(() => {
    setData(illustration);
  }, [illustration]);
  useEffect(() => {
    // console.log(part)
    if (part.add?.data?._id) {
      dispatch(clearPartData(ADD_PART))
      if (onComplete) {
        onComplete(part.add?.data)
      } else
        history.push("/part/list");
    }
  }, [part]);
  const renderRows = () => {
    let keys = getKeys(header);
    return data.map((item, i) => (
      <StyledTableRow key={i}>{renderDataCells(item, keys, i)}</StyledTableRow>
    ));
  };

  const renderDataCells = (row, keys, i) => {
    let dataCells = keys.map((key, j) => (
      <StyledTableCell
        key={j}
        align={header[key]?.align || `center`}
        style={{ maxWidth: header[key]?.width || "auto" }}
      >
        {renderComponent(header[key], row, key, i)}
      </StyledTableCell>
    ));
    let action = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        <div>
          <Button
            onClick={(e) => {
              // onDelete(i);
              illustration.splice(i, 1);
              setIllustration([...illustration]);
            }}
            variant="contained"
            color="primary"
            style={{ marginBottom: ".5rem" }}
          >
            Delete
          </Button>
        </div>
      </StyledTableCell>
    );
    return (
      <>
        {dataCells}
        {actions && action}
      </>
    );
  };

  const renderComponent = (column, row, key, i) => {
    if (!column?.component)
      if (!row[key]) return "";
      else return row[key];
    // return ''
    // if (!row[key]) return "";
    switch (column?.component) {
      case "Tooltip":
        return (
          <Tooltip
            {...column.props}
            title={row[column.props.title]?.map((item, i) => {
              return <div key={i}>{item}</div>;
            })}
            Component={() => <Typography noWrap={true}>{row[key]}</Typography>}
          />
        );
      case "img":
        return (
          <>
            {!row[key] ? (
              <>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleImage(e, key + "-" + i)}
                  fullWidth
                  style={{ float: 'left' }}
                ></input>
                <TextField
                  variant="filled"
                  label="Label"
                  value={row[key.replace('Image', 'Label')]}
                  onChange={(e) => {
                    let list = illustration;
                    list = list.map((a, ii) => {
                      if (i == ii)
                        a[key.replace('Image', 'Label')] = e.target.value;
                      return a;
                    })
                    setIllustration([...list]);
                  }}
                  style={{ marginTop: 10 }}
                  fullWidth
                />
              </>
            ) : (
              <div>
                <img
                  src={axios.defaults.baseURL + "/file/" + row[key]}
                  style={{ height: 100, width: 100 }}
                ></img>
                <input
                  style={{ paddingLeft: "0", float: 'left' }}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleImage(e, key + "-" + i)}
                  fullWidth
                ></input>
                <TextField
                  variant="filled"
                  label="Label"
                  value={row[key.replace('Image', 'Label')]}
                  onChange={(e) => {
                    let list = illustration;
                    list = list.map((a, ii) => {
                      if (i == ii)
                        a[key.replace('Image', 'Label')] = e.target.value;
                      return a;
                    })
                    setIllustration([...list]);
                  }}
                  style={{ marginTop: 10 }}
                  fullWidth
                />
              </div>
            )}
          </>
        );
    }
  };

  const getKeys = (obj) => Object.keys(obj);

  const renderHeader = () => {
    let keys = getKeys(header);
    return keys.map((key, index) => (
      <StyledTableCell
        key={index}
        align={header[key]?.align || `center`}
        style={{ width: header[key]?.width || "auto" }}
      >
        {header[key]?.data}
      </StyledTableCell>
    ));
  };

  const validate = () => {
    let temp = {};
    let error = false;
    if (!fields.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!fields.description) {
      error = true;
      temp.description = "Description is required";
    }
    if (!fields.image) {
      error = true;
      temp.image = "Image is required";
    }
    if (!fields.partNo) {
      error = true;
      temp.partNo = "Part Number is required";
    }
    if (!fields.quantity) {
      error = true;
      temp.quantity = "Part Number is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = fields;
      fields.illustration = illustration;
      dispatch(addPart(data));

    }
  };

  return (
    <Grid container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {breadcumb == false ? null :
        <Grid sm={12}>
          <h2>
            {" "}

            <IconButton
              color="primary"
              onClick={() => {
                history.push("/part/list");
              }}
              icon=<ArrowBack />
            ></IconButton>

            Add Part
          </h2>

        </Grid>
      }
      <Grid
        container
        // component={"form"}
        // nonValidate
        spacing={2}
      >
        <Grid item sm={4}>
          <TextField
            variant="filled"
            label="Name"
            value={fields.name}
            onChange={(e) => {
              // setField("partName", e.target.value);
              // setpartName(e.target.value);
              setfields({ ...fields, ["name"]: e.target.value });
            }}
            isError={errors.name}
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            variant="filled"
            label="Part Number"
            value={fields.partNo}
            onChange={(e) => {
              // setField("partName", e.target.value);
              // setpartName(e.target.value);
              setfields({ ...fields, ["partNo"]: e.target.value });
            }}
            isError={errors.partNo}
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            variant="filled"
            label="Description"
            value={fields.description}
            onChange={(e) => {
              setfields({ ...fields, ["description"]: e.target.value });
            }}
            isError={errors.description}
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            variant="filled"
            label="Quantity"
            type="number"
            value={fields.quantity}
            onChange={(e) => {
              setfields({ ...fields, ["quantity"]: e.target.value });
            }}
            isError={errors.quantity}
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            variant="filled"
            label="Daily target per inspector"
            type="number"
            value={fields.target}
            onChange={(e) => {
              setfields({ ...fields, ["target"]: e.target.value });
            }}
            isError={errors.target}
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <Typography fullWidth>Part Image</Typography>
          {fields.image ? (
            <img
              src={axios.defaults.baseURL + "/file/" + fields.image}
              style={{ height: 100, width: 100 }}
            ></img>
          ) : null}
          <TextField
            style={{}}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              handleImage(e, "image");
            }}
          ></TextField>
          <span className={classes.imgErr}>
            {errors.image ? errors.image : ""}
          </span>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "2rem" }}>
        <TableContainer component={Paper}>
          {/* <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}> */}
          <Table>
            <TableHead>
              <TableRow>
                {renderHeader()}
                <StyledTableCell align="center" style={{ width: "200px" }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
        <Grid
          item
          sm={12}
          justifyContent="center"
          style={{ display: "flex", marginTop: "2rem" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              illustration.push({
                okImage: "",
                okLabel: "",
                notOkImage: "",
                nokLabel: ""
              });
              setIllustration([...illustration]);
            }}
            style={{ width: "200px" }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "200px", marginLeft: "1rem" }}
            disabled={!fields.name}
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
