import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { AutoComplete, Button, IconButton, Table, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccident,
  getAccident,
  updateAccident,
} from "../../../redux/actions/accident";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    width: '95%',
  },
});

export default function Index() {
  const [field, setField] = useState({
    title: "",
    type: "",
    comment: "",
  });
  const classes = useStyles();
  const [errors, seterrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { accidentId } = useParams();
  const accident = useSelector((state) => state.accident);


  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm === type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    if (accidentId) {
      dispatch(getAccident(accidentId));
    }
  }, []);

  useEffect(() => {
    if (accidentId && accident.detail.data._id) {
      setField({
        title: accident.detail.data.title,
        type: accident.detail.data.type,
        comment: accident.detail.data.comment,
      });
    }
  }, [accident]);
  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.type) {
      error = true;
      temp.type = "Type is required";
    }
    if (!field.title) {
      error = true;
      temp.title = "Title is required";
    }
    if (!field.comment) {
      error = true;
      temp.comment = "Comment is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = { ...field };
      // console.log(field);
      if (accidentId) {
        dispatch(updateAccident(data, accidentId));
      } else {
        dispatch(addAccident(data));
      }
      setTimeout(() => {
        history.push("/accident");
      }, 500);
    }
  };



  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() =>
                history.push(
                  '/accident'
                )
              }
              icon=<ArrowBack />
            ></IconButton>
            {!accidentId ? "Add" : "Edit"} Incident
          </h2>
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "space-around" }}
          container
        >
          <Grid sm={12} md={4} style={{ marginTop: "1rem" }}>
            <TextField
              variant="filled"
              label="Title"
              value={field.title}
              isError={errors.title}
              onChange={(e) => {
                setFields("title", e.target.value);
              }}
              customClass={classes.root}
            />
          </Grid>
          <Grid sm={12} md={4} style={{ marginTop: "1rem", position: 'relative' }}>
            <AutoComplete
              variant="filled"
              label="Type"
              value={[
                { name: "Accident", _id: "Accident" },
                { name: "Near Misses", _id: "Near Misses" },
                { name: "Pro-Active Near Misses", _id: "Pro-Active Near Misses" },
                { name: "Incidents", _id: "Incidents" },
              ].find((itm) => itm._id === field.type)}
              isError={errors.type}
              onChange={(e,newValue) => {
                setFields("type", newValue._id);
              }}
              name={'type'}
              options={[
                { name: "Accident", _id: "Accident" },
                { name: "Near Misses", _id: "Near Misses" },
                { name: "Pro-Active Near Misses", _id: "Pro-Active Near Misses" },
                { name: "Incidents", _id: "Incidents" },
              ]}
              // customClass={classes.root}
              style={{ width: '95%' }}
            />
          </Grid>
          <Grid sm={12} md={4} style={{ marginTop: "1rem" }}>
            <TextField
              variant="filled"
              label="Detail"
              value={field.comment}
              isError={errors.comment}
              onChange={(e) => {
                setFields("comment", e.target.value);
              }}
              multiline
              rows={4}
              customClass={classes.root}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ textAlign: "center" }}>
        <Button
          style={{ marginTop: "1.7rem" }}
          width="150px"
          text={accidentId ? "Edit" : "Add"}
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit();
          }}
        />
      </Grid>
    </Grid>
  );
}
