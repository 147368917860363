import {
  LIST_ASSESSMENT,
  GET_ASSESSMENT,
  ADD_ASSESSMENT,
  UPDATE_ASSESSMENT,
  DELETE_ASSESSMENT,
} from "../action_types";

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false,
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false,
  },
};

const assessment = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_ASSESSMENT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case GET_ASSESSMENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case ADD_ASSESSMENT:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        },
      };
    case UPDATE_ASSESSMENT:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload,
          isLoading: false,
        },
      };
    case DELETE_ASSESSMENT:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        },
      };
    default:
      return state;
  }
};

export default assessment;
