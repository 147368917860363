import {
  LIST_LOCATION,
  GET_LOCATION,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listLocations = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/location`,
      params
    });
    dispatch({
      type: LIST_LOCATION,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_LOCATION,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getLocation = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/location/${id}`,
    });
    dispatch({
      type: GET_LOCATION,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_LOCATION,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addLocation = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/location`,
      data
    });
    dispatch({
      type: ADD_LOCATION,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_LOCATION,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateLocation = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/location/${id}`,
      data
    });
    dispatch({
      type: UPDATE_LOCATION,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_LOCATION,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteLocation = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/location/${id}`,
    });
    dispatch({
      type: DELETE_LOCATION,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_LOCATION,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}
export const clearLocationData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_LOCATION,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};