import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
} from "@material-ui/core";
import {
  Button,
  IconButton,
  Pagination,
  Table,
  Tooltip,
} from "../../../commons/mui";
import useStyles from "../../../styles/jss/style";
import { ADMIN, ASSOCIATE, AUDITOR, TABLE_HEADER } from "./CONSTANTS";

import {
  deleteProject,
  listProjects,
  updateProject,
  clearProjectData,
} from "../../../redux/actions/project";
import ProjectTemplate from "../../Projects/ProjectTemplate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DIALOGS } from "../../../commons/CONSTANTS";
import { listCompanyRoles } from "../../../redux/actions/companyRole";
import { getUser, listUsers } from "../../../redux/actions/user";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { encrypt } from "n-krypta";
import { BRM, PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";

const Index = ({ styleObj = {} }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project);
  const users = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const companyRoles = useSelector((state) => state.companyRole);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [associates, setAssociates] = useState([]);
  const [auditors, setAuditors] = useState([]);
  const [showProject, setShowProject] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [companyLId, setCompanyLId] = useState("");
  const { companyId } = useParams();
  // const companyId = axios.defaults.headers.common["Company"];
  const [selectedEsignProject, setSelectedEsignProject] = useState({});
  const [showCloneBtn, setshowCloneBtn] = useState(false);
  const [projectId, setprojectId] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  const [showAssignDialog, setShowAssignDialog] = useState({
    open: false,
    title: "Assign Project",
    tfProps: {
      variant: "filled",
      color: "primary",
      select: true,
      options: [],
      label: "Assign To",
      fullWidth: true,
    },
    btn1: {
      text: "Cancel",
      onClick: () => {},
    },
    btn2: {
      text: "Submit",
      onClick: () => {},
    },
  });
  const [showInfoDialog, setShowInfoDialog] = useState({
    title: "sample",
    open: false,
    children: <div></div>,
    btn1: {
      text: "Close",
      onClick: () => {},
    },
  });

  useEffect(() => {
    if (companyId) {
      setCompanyHeader(encrypt(companyId, "secret"));
      dispatch(listCompanyRoles());
    }
    if (localStorage.getItem("companyId"))
      setCompanyLId(localStorage.getItem("companyId"));

    // if (userId)
    //   dispatch(getUser(userId));
  }, []);

  useEffect(() => {
    let query = { skip: 10 * (page - 1) };
    dispatch(listProjects(query));
  }, [page, users]);
  useEffect(() => {
    // console.log(AUDITOR, ASSOCIATE, companyRoles.list.data)
    if (companyRoles.list.data && companyRoles.list.data.length) {
      let customerRole =
        companyRoles.list.data.filter(
          (a) => a.name === ASSOCIATE || a.name === AUDITOR || a.name === ADMIN
        ) || [];
      if (customerRole.length) {
        dispatch(
          listUsers({ limit: 0, role: customerRole.map((a) => a._id).join() })
        );
      }
    }
  }, [companyRoles]);
  useEffect(() => {
    // console.log(users)
    if (users.list.data && users.list.data.length) {
      setAssociates(
        users.list.data.filter(
          (a) => a.role.name === ASSOCIATE || a.role.name === ADMIN
        )
      );
      setAuditors(
        users.list.data.filter(
          (a) => a.role.name === AUDITOR || a.role.name === ADMIN
        )
      );
    }
    if (users.detail?.data) {
      let query = {};
      if (users.detail?.data?.role?.name == ASSOCIATE)
        query.assessmentAssignTo = users.detail.data._id;
      if (users.detail?.data?.role?.name == AUDITOR)
        query.auditBy = users.detail.data._id;
      dispatch(listProjects(query));
    }
  }, [users]);
  useEffect(() => {
    // console.log(associates, auditors, auth)
  }, [associates, auditors, auth]);

  useEffect(() => {
    const construct = async () => {
      if (projects.list.data && projects.list.data.length > 0) {
        let temp = constructTableData(
          projects.list.data.map((a) => {
            a.customerName = a.customer
              ? a.customer.firstName
                ? a.customer.firstName +
                  (a.customer.lastName ? " " + a.customer.lastName : "")
                : a.customer.emailId
              : "";
            let str = "";
            if (
              a.location &&
              typeof a.location != "string" &&
              a.location.length
            ) {
              a?.location?.map((itm, i) => {
                if (i == a.location.length - 1) {
                  str += itm.name;
                } else {
                  str += itm.name + ",";
                }
              });
              a.locationContact = str;
            }
            return a;
          })
        );
        setData(temp);
        //
      } else setData([]);
    };
    construct();
    if (projects.update && projects.update.data) {
      dispatch(clearProjectData());
      dispatch(listProjects());
    }
  }, [projects, associates]);

  useEffect(() => {
    if (showProject && projects.detail.data) {
      setShowInfoDialog((old) => ({
        ...old,
        title: "Project",
        open: true,
        children: <ProjectTemplate data={projects.detail.data} />,
      }));
    }
  }, [showProject, projects.detail.data]);

  const showAssementAssingDialog = (e, project) => {
    // console.log(associates, auditors, project.assessmentDone, (project.assessmentDone ? auditors : associates))
    setShowAssignDialog((old) => ({
      ...old,
      open: true,
      title:
        "Assign assessment of project " +
        (project.name || project.projectNo) +
        " to " +
        (project.assessmentDone ? "Auditor" : "Assessee"),
      tfProps: {
        ...old.tfProps,
        options: (project.assessmentDone ? auditors : associates).map((a) => {
          // console.log(a);
          return {
            label: a.firstName
              ? a.firstName + (a.lastName ? " " + a.lastName : "")
              : a.emailId,
            value: a._id,
          };
        }),
      },
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: (data) => {
          if (project._id) {
            let udata = {};
            if (!project.assessmentDone) {
              udata = {
                assessmentAssignTo: data,
                status: "Awaiting to Start Assessment",
                assessmentAssignOn: new Date(),
              };
            } else {
              udata = {
                auditBy: data,
                status: "Assigned to Auditor",
                auditOn: new Date(),
              };
            }
            dispatch(updateProject(udata, project._id));
            setTimeout(() => {
              dispatch(listProjects());
            }, 1000);
          }
        },
      },
    }));
  };
  const constructTableData = (raw) => {
    let result = [];
    for (let i = 0; i < raw.length; i++) {
      let project = raw[i];
      let row = {};
      Object.keys(TABLE_HEADER).map((a) => {
        if (a == "createdAt")
          row[a] = moment(project[a]).format("YYYY-MM-DD hh:mm A");
        else if (a == "assessmentAssignTo") {
          if (
            project.status === "Awaiting BRM to E-Sign" ||
            project.status === "Awaiting Risk Assessment" ||
            project.status === "Awaiting to Assign Auditor" ||
            project.status === "Awaiting Review E-Sign BRM" ||
            project.status === "Awaiting Work plan"
          ) {
            row[a] =
              project.status === "Awaiting BRM to E-Sign" ||
              project.status === "Awaiting Review E-Sign BRM" ? (
                <Tooltip
                  title={`E-Sign`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        setSelectedEsignProject(project);
                        setOpenTemplate(true);
                        // dispatch(updateProject({ status: 'Awaiting Customer to E-Sign' }, project._id))
                      }}
                      text="E-Sign"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : project.status === "Awaiting Risk Assessment" ||
                project.status === "Awaiting to Assign Auditor" ? (
                <Tooltip
                  title={
                    !project.assessmentDone
                      ? `Assign to Assessee`
                      : `Assign to Auditor`
                  }
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => showAssementAssingDialog(e, project)}
                      text="Assign"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : project.status === "Awaiting Work plan" ? (
                <Tooltip
                  title={`Work Plans`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        history.push(
                          (auth.data?.role?.name == BRM ? "/brm-" : "/") +
                            "workplan-list/" +
                            project._id
                        );
                      }}
                      text="Work Plans"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : null;
          } else if (
            (project[a] && !project.assessmentDone) ||
            project.auditBy
          ) {
            row[a] =
              project[a] && !project.assessmentDone
                ? project[a].firstName
                  ? project[a].firstName +
                    (project[a].lastName ? " " + project[a].lastName : "")
                  : project[a].emailId
                : project.auditBy.firstName
                ? project.auditBy.firstName +
                  (project.auditBy.lastName
                    ? " " + project.auditBy.lastName
                    : "")
                : project.auditBy.emailId;
            if (
              ((project.auditBy && project.auditBy._id === auth.data._id) ||
                (project.auditBy &&
                  project.auditBy._id &&
                  (auth.data?.role?.name === PDSLADMIN ||
                    auth.data?.role?.name === BRM))) &&
              project.assessmentDone &&
              !project.auditDone
            ) {
              row.startButtonTitle = "Start Audit";
              row.assessmentDone = project.assessmentDone;
              row.auditByDetail = project.auditBy;
            } else if (
              project.assessmentAssignTo &&
              (project.assessmentAssignTo._id === auth.data._id ||
                auth.data?.role?.name === BRM) &&
              !project.assessmentDone
            ) {
              row.startButtonTitle = "Start Assessment";
              row.assessmentDone = project.assessmentDone;
              row.assessmentAssignToDetail = project.assessmentAssignTo;
            }
          } else {
            row[a] = "";
          }
        } else if (a === "projectNoView") {
          row[a] = (
            <Grid>
              <Link
                onClick={() => {
                  history.push(`/project-detail/${project._id}`);
                }}
                style={{ cursor: "pointer" }}
              >
                {project.projectNo}
              </Link>
            </Grid>
          );
        } else row[a] = project[a];
        return a;
      });
      row._id = project._id;
      // console.log('row----', row)
      result.push(row);
    }
    return result;
  };

  const onEdit = (e, item) => {
    // alert(`Edit clicked on ${item.projectId}`)
    history.push(
      (auth.data?.role?.name === BRM ? "/brm-" : "/") +
        "edit-project/" +
        item._id
    );
  };
  const onStart = (e, i, row) => {
    // console.log(row, auth.data, row.assessmentDone);
    if (
      row.auditByDetail &&
      (row.auditByDetail._id === auth.data._id ||
        auth.data?.role?.name === PDSLADMIN ||
        auth.data?.role?.name === BRM) &&
      row.assessmentDone
    ) {
      history.push(
        (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "admin-audit/" +
          row._id
      );
    } else if (
      row.assessmentAssignToDetail &&
      row.assessmentAssignToDetail._id === auth.data._id &&
      !row.assessmentDone
    ) {
      history.push(
        (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "admin-action/" +
          row._id
      );
    }
  };

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete project "${
        item.name || item.projectNo
      }".  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => dispatch(deleteProject(item._id)),
      },
    }));
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <h2>
          <IconButton
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "company-user-list/" +
                  companyId
              )
            }
            icon=<ArrowBack />
          ></IconButton>
          Project List
        </h2>
        <Grid>
          <Button
            width="200px"
            text="Clone Project"
            variant="contained"
            color="primary"
            disabled={!showCloneBtn}
            onClick={() => history.push(`/clone-project/${projectId}`)}
          />
          <Button
            width="200px"
            text="Create Project"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "create-project"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        className={`${classes.height90} ${classes.hideScrollbar}`}
        style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
      >
        <Table
          header={TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onStart={onStart}
          projectTable={true}
          selectedRowFunc={(val) => {
            if (val != null) {
              setprojectId(val._id);
              setshowCloneBtn(true);
            } else {
              setshowCloneBtn(false);
            }
          }}
          editPrefix="project/edit/"
          // actions={false}
        />
        <Grid item container style={{ justifyContent: "center" }}>
          <div className="ui-components">
            <Pagination
              count={projects.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      {DIALOGS.DIALOG_WITH_INPUT({
        ...showAssignDialog,
        setOpen: setShowAssignDialog,
        container: `${classes.paddingX}`,
      })}
      {DIALOGS.INFO_DIALOG({
        ...showInfoDialog,
        setOpen: setShowInfoDialog,
        container: `${classes.paddingX}`,
      })}
      <div>
        <Dialog
          open={openTemplate}
          onClose={() => setOpenTemplate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {selectedEsignProject.status === "Awaiting Review E-Sign BRM"
              ? "Assessment Review"
              : "Assessment"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ProjectTemplate data={selectedEsignProject}></ProjectTemplate>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                let upData = {
                  status:
                    selectedEsignProject.status === "Awaiting Review E-Sign BRM"
                      ? "Awaiting Work plan"
                      : "Awaiting Customer to E-Sign",
                };
                dispatch(updateProject(upData, selectedEsignProject._id));
                setOpenTemplate(false);
              }}
              text="E-Sign"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Index;
