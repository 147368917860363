import axios from 'axios';
import { toast } from "react-toastify";

const setBaseUrl = (url) => {

  if (url) {
    axios.defaults.baseURL = url
  } else {
    delete axios.defaults.baseURL;
  }
  // Request interceptor
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log(config)
    if (config.method === 'post' || config.method === 'put') {
      document.getElementById('globle-loader').classList.add('show-loader')
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      document.getElementById('globle-loader').classList.remove('show-loader')
      // Modify the response data here (e.g., parse, transform)
      if (response.data && !response.data.data)
        toast.success(response.data.meta.message);
      return response;
    },
    (error) => {
      console.log(error)
      document.getElementById('globle-loader').classList.remove('show-loader')
      // Handle response errors here
      if (error.response && error.response.data && error.response.data.meta && error.response.data.meta.message)
        toast.error(error.response.data.meta.message)
      // else
      //   toast.error("Some internal error")

      return (error);
    }
  );
}

export default setBaseUrl;