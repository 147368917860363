import {
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AutoComplete, Button, IconButton, Table, TextField, Checkbox } from "../../../commons/mui";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { clearProjectData, getProject } from "../../../redux/actions/project";
import { listParts } from "../../../redux/actions/part";
import { listCompanyRoles } from "../../../redux/actions/companyRole";
import { listUsers } from "../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { BRM, TECHNICIAN } from "../../Projects/AllProjects/CONSTANTS";
import axios from "axios";
import { addPlan, clearPlanData, getPlan, updatePlan } from "../../../redux/actions/projectPlan";
import { ADD_WORK_PLAN, GET_PROJECT, GET_WORK_PLAN, LIST_PLAN_ACTIVITY, LIST_VEHICLE, UPDATE_WORK_PLAN } from "../../../redux/action_types";
import { MSDSISSUED } from "../PlanList/CONSTANTS";
import { ArrowBack, Delete as DeleteIcon, Add as PlusIcon, Done, KeyboardBackspace } from "@material-ui/icons";
import { PLAN_TYPES, REFERENCE_CRITERIA, TABLE_HEADER, TABLE_HEADER_ACTIVITY } from "./CONSTANTS";
import { listTemplates } from "../../../redux/actions/assessmentTemplate";
import { ProjectDetailComponent } from "../../Projects";
import { EQUIPMENTS } from "../../Associate/ActionScreen/CONSTANTS";
import ForwardIcon from '@material-ui/icons/Forward';
import { listGrids } from "../../../redux/actions/grid";
import { clearPlanActivityData, listPlanActivities } from "../../../redux/actions/planActivity";
import { clearVechicleData, listVehicles } from "../../../redux/actions/vehicle";

const useStyles = makeStyles({
  field: {
    // width: 500,
  },
});

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [field, setField] = useState({ equipmentRequired: [], vehicles: [] });
  const [errors, setErrors] = useState({});
  const [activeGrid, setActiveGrid] = useState({});
  const [technicianList, setTechnicianList] = useState([]);
  const [partList, setPartList] = useState([]);
  const [gridList, setGridList] = useState([]);
  const [vechicleList, setVechicleList] = useState([]);
  const history = useHistory();
  let { projectId } = useParams();
  let { planId } = useParams();
  const project = useSelector((state) => state.project);
  const projectPlans = useSelector((state) => state.projectPlan);
  const assessmentTemplate = useSelector((state) => state.assessmentTemplate);
  const parts = useSelector((state) => state.parts);
  const grids = useSelector((state) => state.grid);
  const vehicles = useSelector((state) => state.vehicle);
  const roles = useSelector((state) => state.companyRole);
  const users = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const planActivity = useSelector((state) => state.planActivity);

  const [projectData, setProjectData] = useState({});
  const [equipmentList, setEquipmentList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [showIllestration, setShowIllestration] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [availableEquipments, setAvailableEquipments] = useState([]);
  const [references, setReferences] = useState(REFERENCE_CRITERIA);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(clearProjectData(GET_PROJECT));
    dispatch(clearPlanData(GET_WORK_PLAN));
    dispatch(listTemplates({ type: 'T', limit: 0 }));
    if (projectId) {
      dispatch(getProject(projectId));
    } else if (planId) {
      dispatch(getPlan(planId));
    }
    dispatch(listParts({ limit: 0 }));
    dispatch(listGrids({ limit: 0 }));
    dispatch(listCompanyRoles({ limit: 0 }));
    dispatch(listVehicles({ limit: 0 }));
    // interVal = setInterval(() => {

    // }, 30000)
  }, []);
  useEffect(() => {
    if (planActivity?.list?.data) {
      dispatch(clearPlanActivityData(LIST_PLAN_ACTIVITY))
      setField({ ...field, activity: processActivities(planActivity?.list?.data.sort((a, b) => a?.sortOrder - b?.sortOrder)) });
    }
  }, [planActivity])
  useEffect(() => {
    if (vehicles?.list?.data) {
      dispatch(clearVechicleData(LIST_VEHICLE))
      setVechicleList(vehicles?.list?.data);
    }
  }, [vehicles])
  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail.data);
    }
  }, [project]);
  useEffect(() => {
    if (parts.list?.data?.length) {
      setPartList(parts.list.data);
    }
  }, [parts]);
  useEffect(() => {
    if (grids.list?.data?.length) {
      setGridList(grids.list.data);
    }
  }, [grids]);
  useEffect(() => {
    // console.log(technicianList, equipmentList);
  }, [technicianList, equipmentList]);

  useEffect(() => {
    if (assessmentTemplate.list.data && assessmentTemplate.list.data.length) {
      setTemplateList(
        assessmentTemplate.list.data.map((a) => ({ _id: a._id, name: a.name }))
      );
    }
  }, [assessmentTemplate]);
  useEffect(() => {
    // console.log(projectPlans);
    if (projectPlans.add?.data?._id) {
      dispatch(clearPlanData(ADD_WORK_PLAN));
      setLoading(false);
      if (submit) {
        setSubmit(false);
        history.push(
          (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "workplan-list/" +
          projectId
        );
      } else {
        history.push('/brm-add/edit-workplan/' + projectPlans.add?.data?._id)
      }
    }
    if (projectPlans.update?.data?._id) {
      dispatch(clearPlanData(UPDATE_WORK_PLAN));
      setLoading(false);
      if (submit) {
        history.push(
          (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "workplan-list/" +
          projectPlans.update?.data?.project
        );
      } else {
        history.push('/brm-add/edit-workplan/' + (planId || projectPlans.update?.data?._id))
      }
    }
    if (projectPlans.detail?.data?._id) {
      dispatch(clearPlanData(GET_WORK_PLAN));
      setReferences(REFERENCE_CRITERIA.map(a => {
        if (projectPlans.detail?.data?.referenceCriteria.split(',').indexOf(a.name) != -1)
          a.checked = true;
        return a;
      }))
      if (planId && projectPlans.detail?.data?.project?._id) {
        projectId = projectPlans.detail?.data?.project?._id;
        dispatch(getProject(projectPlans.detail?.data?.project?._id));
      }
      setTimeout(() => {
        setField({ ...projectPlans.detail?.data, activity: processActivities(projectPlans.detail?.data?.activity?.sort((a, b) => a?.sortOrder - b?.sortOrder)), equipmentRequired: processEquipments(projectPlans.detail?.data?.equipmentRequired.map(a => ({ equipment: a }))), project: projectPlans.detail?.data?.project?._id, vehicles: projectPlans.detail?.data.vehicles.map(a => a.vehicleId._id) });
        // console.log(grd, newValue,gridList)
        setActiveGrid(projectPlans.detail?.data?.grid);
        dispatch(listPlanActivities({ workPlan: planId, vin: '', limit: 0, sortByDescending: '_id' }));
      }, 100)
    }
  }, [projectPlans]);
  useEffect(() => {
    if (users.list?.data?.length) {
      // console.log("ji", users.list?.data, projectData, projectData.workLocation);
      setTechnicianList(
        users.list?.data
          ?.filter(
            (a) =>
              a.location?.filter(
                (aa) =>
                  projectData.workLocation?.filter((aaa) => aaa._id === aa._id)
                    .length
              ).length
          )
          .map((a) => {
            a.name = a.firstName
              ? a.firstName + (a.lastName ? " " + a.lastName : "")
              : a.emailId;
            return a;
          })
      );
    }
    if (projectData._id) {
      // console.log(EQUIPMENTS, EQUIPMENTS.filter(a => projectData.safetyEquipments.indexOf(a.name) !== -1))
      setAvailableEquipments(EQUIPMENTS.map(a => { a.checked = projectData.safetyEquipments.indexOf(a.name) !== -1; return a; }))
    }
  }, [users, projectData]);
  useEffect(() => {
    if (roles.list.data) {
      let tech = roles.list.data.find((a) => a.name === TECHNICIAN) || {};
      // console.log(tech, roles.list.data, TECHNICIAN)
      if (tech && tech._id) dispatch(listUsers({ role: tech._id, limit: 0 }));
    }
  }, [roles]);
  useEffect(() => {
    setField({ ...field, equipmentRequired: processEquipments(equipmentList) });
  }, [equipmentList]);
  useEffect(() => {
    setField({ ...field, activity: processActivities(activityList.sort((a, b) => a?.sortOrder - b?.sortOrder)) });
  }, [activityList]);
  const validate = () => {
    let err = false,
      error = {};
    if (!field.name) {
      error.name = "Plan name is required";
      err = true;
    } else {
      error.name = "";
    }
    if (!field.part && field.type === 'P') {
      error.part = "Part Name is required";
      err = true;
    } else {
      error.part = "";
    }
    if (!field.grid && field.type === 'G') {
      error.grid = "Grid is required";
      err = true;
    } else {
      error.grid = "";
    }
    if (!field.vehicles.length && field.type === 'G') {
      error.vehicles = "Vehicle is required";
      err = true;
    } else {
      error.vehicles = '';
    }
    if (!field.userId) {
      error.userId = "Inspector is required";
      err = true;
    } else {
      error.userId = "";
    }
    if (!field.batch) {
      error.batch = "Batch is required";
      err = true;
    } else {
      error.batch = "";
    }
    if (!references.filter(a => a.checked).length) {
      error.referenceCriteria = "Reference Criteria is required";
      err = true;
    } else {
      error.referenceCriteria = "";
    }
    if (field.activity.filter(a => a.what && a.how && a.why).length !== field.activity.length) {
      error.activity = "All Activity is required";
      err = true;
    } else {
      error.activity = "";
    }
    if (field.equipmentRequired.filter(a => a.equipment).length !== field.equipmentRequired.length) {
      error.equipmentRequired = "All Tools is required";
      err = true;
    } else {
      error.equipmentRequired = "";
    }
    setErrors({ ...errors, ...error });
    console.log(error, field.activity)
    return !err;
  };
  const onSubmit = () => {
    if (validate()) {
      setShowIllestration(true);
    }
  };
  const setEquipmentFields = (type, val, i) => {
    let equipments = equipmentList;
    if (equipments[i]) {
      equipments[i][type] = val;
      setEquipmentList(equipments);
      setField({ ...field, equipmentRequired: processEquipments(equipments) });
    }
  };
  const setActivityFields = (type, val, i) => {
    let activities = activityList;
    if (activities[i]) {
      activities[i][type] = val;
      setActivityList(activities);
      setField({ ...field, activity: processActivities(activities.sort((a, b) => a?.sortOrder - b?.sortOrder)) });
    }
  };
  const processActivities = (activities) => {
    let reference = 1;
    return activities.map((a, i) => {
      a.errors = a.errors || {};
      a.referenceView = reference;
      a.whatView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <ForwardIcon />
          <TextField
            variant="filled"
            placeholder="What"
            value={a.what}
            isError={a.errors?.what}
            onChange={(e) => {
              setActivityFields("what", e.target.value, i);
            }}
            // onBlur={() => savePlan()}
            fullWidth
            multiline
            minRows={10}
            maxRows={10}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      );
      a.howView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <ForwardIcon />
          <TextField
            variant="filled"
            placeholder="How"
            value={a.how}
            isError={a.errors?.how}
            onChange={(e) => {
              setActivityFields("how", e.target.value, i);
            }}
            // onBlur={() => savePlan()}
            fullWidth
            multiline
            minRows={10}
            maxRows={10}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      );
      a.whyView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <ForwardIcon />
          <TextField
            variant="filled"
            placeholder="Why"
            value={a.why}
            isError={a.errors?.why}
            onChange={(e) => {
              setActivityFields("why", e.target.value, i);
            }}
            // onBlur={() => savePlan()}
            multiline
            minRows={10}
            maxRows={10}
            inputProps={{ maxLength: 100 }}
            fullWidth
          />
        </Grid>
      );
      reference++;
      return a;
    });
  };
  const processEquipments = (equipments) => {
    return equipments.map((a, i) => {
      a.errors = a.errors || {};
      a.equipmentView = (
        <TextField
          variant="filled"
          placeholder="Equipment"
          value={a.equipment}
          isError={a.errors?.equipment}
          onChange={(e) => {
            setEquipmentFields("equipment", e.target.value, i);
          }}
          // onBlur={() => savePlan()}
          fullWidth
        />
      );
      return a;
    });
  };
  const handleCheckList = (itm, i, j) => {
    // setSelectedGridRow(i);
    // setSelectedGridColumn(j);
    // setCheckList(itm.checkList || []);
    // setOpenCheckList(true);
  }
  const savePlan = () => {
    // console.log(field)
    let data = { ...field };
    data.project = data.project || projectId;
    data.userId = data.userId?.map((itm) => itm._id) || [];
    data.vehicles = data.vehicles?.map((itm) => itm._id || itm);
    data.quantity = data.vehicles?.length;
    data.equipmentRequired = data.equipmentRequired?.map(
      (itm) => itm.equipment
    );
    data.activity = data.activity.map(
      (itm, i) => ({ what: itm.what, how: itm.how, why: itm.why, sortOrder: itm.sortOrder || i })
    );
    data.msdsIssued = data.msdsIssued?._id || '';
    if (data.type === 'P')
      data.part = data.part?._id;
    data.questionTemplate = data.questionTemplate
      ? data.questionTemplate?._id
      : null;
    let referenceCriteria = references.filter(a => a.checked) || {};
    data.referenceCriteria = referenceCriteria?.map(a => a.name).join();
    if (!data.project) {
      data.project = projectId || projectPlans.detail?.data?.project?._id
    }
    if (!loading) {
      setLoading(true);
      if (planId) {
        delete data._id;
        delete data.planNo;
        delete data.createdAt;
        // data.reinitiateAgreement = true;
        data.status = 'Awaiting BRM to E-Sign Work Plan';
        // console.log(data, projectId, field)
        dispatch(updatePlan(data, planId));
      } else
        dispatch(addPlan(data));
    }
  }
  return (
    <Grid container>
      <h2>
        <IconButton
          color="primary"
          onClick={() => history.push(`/workplan-list/${projectId || projectData._id}`)}
          icon=<ArrowBack />
          props={{
            style: {}
          }}
        ></IconButton>
        Add {projectData?.name} Work Plan
      </h2>

      <>
        {
          showIllestration && (field.part || field.grid) ? (
            <Grid container>
              <ProjectDetailComponent data={projectData} show={['projectNo', 'rev', 'sapNo']} margin="0" additional={(
                <Grid item sm={12} style={{ display: "flex" }} >
                  <Grid item container sm={12}>
                    <Grid item sm={5} container>
                      {references.filter(a => a.checked).map((item, i) => (
                        <Grid item sm={3} className="safety-checkboxes selected-chb" style={{ paddingLeft: `2rem !important`, height: '5rem' }}>
                          <Checkbox
                            color="primary"
                            checked={item.checked}
                            onChange={(e) => {
                              // let list = [...references]
                              // list[i].checked = e.target.checked;
                              // setReferences([...list.map((a, it) => { a.checked = it == i ? true : false; return a; })]);
                            }}
                            style={{ height: "36px", }}
                            value={item.name}
                            name={'safety-checkboxes'}
                            text={(
                              <Grid className={"safety-checkboxes-label"}>
                                <img className="checkbox-img" src={item.image} width={70} />
                                {/* <Typography>{item.name}</Typography> */}
                              </Grid>
                            )}
                          />
                        </Grid>
                      ))}
                      <Typography variant="subtitle1" style={{ width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: 'bold', marginBottom: '0' }}>Product Identification</Typography>
                    </Grid>
                    <Grid item sm={7} style={{ display: "flex" }}>
                      <Typography variant="h2" style={{ width: '100%', textAlign: 'left', fontWeight: 'bold', alignItems: 'center', display: 'flex', fontSize: '1.5em' }}>Photo / Illustration</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )} ></ProjectDetailComponent>
              <Grid container className="technician-action">
                {/* <Typography variant="subtitle1" style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginBottom: '1rem' }}>Photo/Illustration</Typography> */}
                {/* <Typography variant="subtitle1" style={{ width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: 'bold', marginBottom: '2rem' }}>Product Identification</Typography> */}
                <Grid item sm={12} className={`${classes.align} ${classes.divider}`}>
                  {activeGrid && activeGrid.images ?
                    activeGrid.images.map((a, i) => (
                      <Grid item container>
                        <Grid item sm={3} style={{ textAlign: 'center' }}>
                          <Typography>{a.side}</Typography>
                        </Grid>
                        <Grid item sm={7}>
                          <Grid item
                            // ref={gridRef}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: `repeat(${a.column}, 0fr)`,
                              gridTemplateRows: `repeat(${a.row}, 1fr)`,
                              gridGap: '5px',
                            }}
                          >

                            {a.devideList.map((itm, j) => (
                              <Grid item style={{ position: 'relative' }}>
                                <img src={itm.imageBase64.indexOf('base64') != -1 ? itm.imageBase64 : axios.defaults.baseURL + '/file/' + itm.imageBase64} style={{ cursor: 'pointer', width: 100, height: 100 }} onClick={() => { handleCheckList(itm, i, j) }} />
                                <label style={{ position: 'absolute', left: '40%', top: '30%', fontWeight: 'bold', fontSize: 'xx-large', color: 'green' }}>{itm.tag}</label>
                              </Grid>
                            ))}

                          </Grid>

                        </Grid>

                        <Divider style={{ margin: '25px 0', width: '100%' }} />
                      </Grid>
                    ))
                    :
                    <>
                      <Grid className="main-image" item style={{ textAlign: "center", position: 'relative' }}>
                        <img
                          src={axios.defaults.baseURL + "/file/" + field.part?.image}
                          width={150}
                          height={150}
                        />
                        <KeyboardBackspace style={{ position: 'absolute', top: '50px', fontSize: '55px', color: 'green' }} />
                        <Typography style={{ position: 'absolute', top: '45px', marginLeft: '3.5rem' }} variant="span">
                          {field.part?.name}<br />
                          {field.part?.partNo}<br />
                          (Please See Samples)
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Grid item sm={6} style={{ textAlign: "center" }}>
                          <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                            <Grid
                              item
                              style={{ background: "red", fontWeight: "bold", fontSize: "1.5rem", padding: '10px 30px', color: '#fff' }}
                            >

                              Not Ok Image (Example)
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center', }}>
                            {field.part?.illustration?.map((itm, i) => (
                              <Grid item sm={12} style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Typography style={{ maxWidth: 200 }} variant="span">
                                  {itm?.notOkLabel}
                                </Typography>
                                <img
                                  src={axios.defaults.baseURL + "/file/" + itm.notOkImage}
                                  width={150}
                                  height={150}
                                  style={{ marginBottom: '2rem' }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item sm={6} style={{ textAlign: "center" }}>
                          <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                            <Grid
                              item
                              style={{ background: "green", fontWeight: "bold", fontSize: "1.5rem", padding: '10px 30px', color: '#fff' }}
                            >

                              Ok Image (Example)
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center' }}>
                            {field.part?.illustration?.map((itm, i) => (
                              <Grid item sm={12} style={{ position: 'relative', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Typography style={{ maxWidth: 200 }} variant="span">
                                  {itm?.okLabel}
                                </Typography>
                                <img
                                  src={axios.defaults.baseURL + "/file/" + itm.okImage}
                                  width={150}
                                  height={150}
                                  style={{ marginBottom: '2rem' }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  }
                  <Grid style={{ marginTop: "2rem", textAlign: "center" }} item>
                    {/* <Typography>Speci</Typography> */}
                    <TextField
                      variant="filled"
                      label="Special Care Points"
                      value={field.specialCareDescription}
                      name="criteria"
                      onChange={(e) => {
                        setField({ ...field, specialCareDescription: e.target.value });
                      }}
                      // onBlur={() => savePlan()}
                      fullWidth
                      multiline={true}
                      minRows={3}
                      maxRows={5}
                      isError={errors.specialCareDescription}
                    />
                  </Grid>
                  <Grid style={{ textAlign: "center" }}>
                    <Button
                      style={{ marginTop: "20px", marginBottom: "10px" }}
                      width="150px"
                      text="Edit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowIllestration(false);
                      }}
                    />
                    <Button
                      style={{ marginTop: "20px", marginBottom: "10px" }}
                      width="150px"
                      text="Submit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSubmit(true);
                        let data = { ...field };
                        data.project = data.project || projectId;
                        data.userId = data.userId?.map((itm) => itm._id);
                        data.vehicles = data.vehicles?.map((itm) => itm._id || itm);
                        data.quantity = data.vehicles?.length;
                        data.equipmentRequired = data.equipmentRequired.map(
                          (itm) => itm.equipment
                        );
                        data.activity = data.activity.map(
                          (itm, i) => ({ what: itm.what, how: itm.how, why: itm.why, sortOrder: itm.sortOrder || i })
                        );
                        data.msdsIssued = data.msdsIssued?._id || '';
                        if (data.type === 'P')
                          data.part = data.part?._id;
                        data.questionTemplate = data.questionTemplate
                          ? data.questionTemplate?._id
                          : null;
                        let referenceCriteria = references.filter(a => a.checked) || {};
                        data.referenceCriteria = referenceCriteria?.map(a => a.name).join();
                        if (!data.project) {
                          data.project = projectId || projectPlans.detail?.data?.project?._id
                        }
                        if (planId) {
                          delete data._id;
                          delete data.planNo;
                          delete data.createdAt;
                          data.reinitiateAgreement = true;
                          data.status = 'Awaiting BRM to E-Sign Work Plan';
                          // console.log(data, projectId, field)
                          dispatch(updatePlan(data, planId));
                        } else
                          dispatch(addPlan(data));

                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <ProjectDetailComponent data={projectData} show={[]} margin={"0 0 0 3px"} additional={(
                <>
                  <Grid container spacing={2}>
                    <Grid item sm={10} xs={10}>
                      <Typography variant="subtitle1" style={{ fontWeight: "normal", marginRight: 0 }}>
                        <strong>Safety First</strong>

                        &nbsp; Has The Dynamic Risk Assessment been completed? &nbsp;
                        {/* <br /> */}
                        Area / Location Quality Confirmation Center
                      </Typography>

                    </Grid>
                    <Grid item sm={2} xs={2}>
                      <Typography variant="subtitle1">
                        Yes
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <Typography variant="subtitle1" style={{ fontWeight: "600", marginRight: ".5rem" }}>
                        PPE indicate with <Done style={{ color: 'green' }} /> the PPE (Personal Protective Equipment) Required
                      </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12} container>
                      {availableEquipments.filter(a => a.checked).map((item, i) => (
                        <Grid item style={{ width: '12%' }} className="safety-checkboxes add-plan">
                          <Checkbox
                            color="primary"
                            checked={item.checked}
                            onChange={(e) => {
                              // let list = [...equipments]
                              // list[i].checked = e.target.checked;
                              // setEquipments([...list]);
                            }}
                            style={{ height: "36px", }}
                            value={item.name}
                            name={'safety-checkboxes'}
                            text={(
                              <Grid className={item.checked ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"} >
                                <img className="checkbox-img" src={item.image} width={70} style={{}} />
                                <Typography >{item.name}</Typography>
                                <Done />
                              </Grid>
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item sm={4} xs={12} style={{ display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                        PDSL Project No. :
                      </Typography>
                      <Typography>{projectData?.projectNo}</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} style={{ display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                        Rev :
                      </Typography>
                      <Typography>{projectData?.rev}</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} style={{ display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                        SAP/Customer Ref :
                      </Typography>
                      <Typography>{projectData?.sapNo}</Typography>
                    </Grid>
                  </Grid>
                </>
              )}></ProjectDetailComponent>
              <Grid
                style={{ marginTop: "2rem", textAlign: "center", maxWidth: '100%', position: 'relative', }}
                container
                spacing={5}
                item
              >
                
                <Grid item sm={4} xs={12}>
                  <TextField
                    variant="filled"
                    label="Work Plan Name"
                    value={field.name}
                    onChange={(e) => {
                      setField({ ...field, name: e.target.value });
                    }}
                    // onBlur={() => savePlan()}
                    isError={errors.name}
                    fullWidth
                    customClass={classes.field}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <AutoComplete
                    variant="filled"
                    label="Plan Type"
                    value={PLAN_TYPES.find(a => a._id == field.type)}
                    key={field.type}
                    onChange={(e, newValue) => {
                      setField({ ...field, type: newValue._id });
                    }}
                    // onBlur={() => savePlan()}
                    options={PLAN_TYPES}
                    // select
                    isError={errors.type}
                    fullWidth
                    customClass={classes.field}
                  />
                </Grid>
                {field.type === 'P' ?
                  <Grid item sm={4} xs={12}>
                    <AutoComplete
                      variant="filled"
                      label="Part Name"
                      color="secondary"
                      value={field.part}
                      key={field.part}
                      name="part"
                      options={partList}
                      isError={errors.part}
                      // helperText=""
                      onChange={(e, newValue) => {
                        setField({ ...field, part: newValue, quantity: field.quantity || newValue.quantity });
                      }}
                      // onBlur={() => savePlan()}
                      fullWidth
                    />
                  </Grid>
                  : field.type === 'G' ?
                    <Grid item sm={4} xs={12}>
                      <AutoComplete
                        variant="filled"
                        label="Grid"
                        color="secondary"
                        value={field.grid}
                        key={field.grid}
                        name="grid"
                        // multiple={true}
                        options={gridList.map(a => {
                          return {
                            _id: a._id,
                            name: a.name,
                          };
                        })}
                        // helperText=""
                        isError={errors.grid}
                        onChange={(e, newValue) => {
                          let grd = gridList.find(a => a._id === newValue._id);
                          // console.log(grd, newValue,gridList)
                          setActiveGrid(grd);
                          setField({ ...field, grid: newValue });
                        }}
                        // onBlur={() => savePlan()}
                        fullWidth
                      />
                    </Grid>
                    : null}

                <Grid item sm={4} xs={12}>
                  {field.type != 'G' ?
                    <TextField
                      variant="filled"
                      label="Quantity"
                      value={field.quantity}
                      onChange={(e) => {
                        setField({ ...field, quantity: e.target.value });
                      }}
                      // onBlur={() => savePlan()}
                      isError={errors.quantity}
                      fullWidth
                      customClass={classes.field}
                    />
                    :
                    <AutoComplete
                      variant="filled"
                      label="Vechicles"
                      color="secondary"
                      value={vechicleList.map(a => {
                        return {
                          _id: a._id,
                          name: a.vin,
                        };
                      }).filter(a => (field.vehicles || []).indexOf(a._id) != -1)}
                      key={field.vehicles?.length}
                      name="vehicles"
                      multiple={true}
                      options={vechicleList.map(a => {
                        return {
                          _id: a._id,
                          name: a.vin,
                        };
                      })}
                      // helperText=""
                      isError={errors.vehicles}
                      onChange={(e, newValue) => {
                        // console.log(newValue, e)
                        // let grd = vechicleList.find(a => a._id === newValue._id);
                        // // console.log(grd, newValue,gridList)
                        // setActiveGrid(grd);
                        setField({ ...field, vehicles: newValue.map(a => a._id) });
                      }}
                      // onBlur={() => savePlan()}
                      fullWidth
                    />
                  }
                </Grid>
                <Grid item sm={4} xs={12}>
                  <AutoComplete
                    variant="filled"
                    label="Inspector"
                    color="secondary"
                    value={field.userId?.length ? technicianList.filter(a => field.userId?.map(it => it._id).indexOf(a._id) !== -1) : []}
                    key={field.userId?.length ? technicianList.filter(a => field.userId?.map(it => it._id).indexOf(a._id) !== -1) : []}
                    name="userId"
                    multiple={true}
                    options={technicianList}
                    // helperText=""
                    isError={errors.userId}
                    onChange={(e, newValue) => {
                      setField({ ...field, userId: newValue });
                    }}
                    // onBlur={() => savePlan()}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <AutoComplete
                    variant="filled"
                    label="Inspector Question Template"
                    color="secondary"
                    value={{
                      ...templateList.find(
                        (a) =>
                          a._id ===
                          (typeof field.questionTemplate == "object"
                            ? field.questionTemplate?._id
                            : field.questionTemplate)
                      ),
                    }}
                    key={{
                      ...templateList.find(
                        (a) =>
                          a._id ===
                          (typeof field.questionTemplate == "object"
                            ? field.questionTemplate?._id
                            : field.questionTemplate)
                      ),
                    }}
                    name="questionTemplate"
                    // select={true}
                    options={templateList}
                    // helperText=""
                    isError={errors.questionTemplate}
                    onChange={(e, newValue) => {
                      setField({ ...field, questionTemplate: newValue });
                    }}
                    // onBlur={() => savePlan()}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    variant="filled"
                    label="Tip Level"
                    type="number"
                    value={field.tipLevel}
                    onChange={(e) => {
                      setField({ ...field, tipLevel: e.target.value });
                    }}
                    // onBlur={() => savePlan()}
                    isError={errors.tipLevel}
                    fullWidth
                    customClass={classes.field}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    variant="filled"
                    label="Safe VIN/ Serial No"
                    value={field.vin}
                    onChange={(e) => {
                      setField({ ...field, vin: e.target.value });
                    }}
                    // onBlur={() => savePlan()}
                    isError={errors.vin}
                    fullWidth
                    customClass={classes.field}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    variant="filled"
                    label="Batch/Production Date(If Applicable)"
                    value={field.batch}
                    onChange={(e) => {
                      setField({ ...field, batch: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    // onBlur={() => savePlan()}
                    isError={errors.batch}
                    fullWidth
                    type={'date'}
                    customClass={classes.field}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <AutoComplete
                    variant="filled"
                    label="MSDS Issued"
                    color="secondary"
                    value={MSDSISSUED.find((a) => a._id === field.msdsIssued)}
                    key={MSDSISSUED.find((a) => a._id === field.msdsIssued)}
                    name="msdsIssued"
                    // multiple={true}
                    options={MSDSISSUED}
                    // helperText=""
                    isError={errors.msdsIssued}
                    onChange={(e, newValue) => {
                      setField({ ...field, msdsIssued: newValue });
                    }}
                    // onBlur={() => savePlan()}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    variant="filled"
                    label="Additional PPE (where required)"
                    value={field.additionalPPE}
                    onChange={(e) => {
                      setField({ ...field, additionalPPE: e.target.value });
                    }}
                    // onBlur={() => savePlan()}
                    isError={errors.additionalPPE}
                    fullWidth
                    customClass={classes.field}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: "2rem", textAlign: "center", maxWidth: '100%' }}
                container
                item
                spacing={1}
              >
                <Grid item sm={12} xs={12}>
                  <Typography variant={"h5"}>
                    Simple Description of Activity from Product Receipt to
                    Packing / Completion
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Table
                    header={TABLE_HEADER_ACTIVITY}
                    data={field.activity}
                    onDelete={(i, e, row) => {
                      let activities = field.activity;
                      // equipments.splice(i, 1);
                      delete activities[i];
                      // console.log(activities.filter(a => a))
                      setActivityList(activities.filter(a => a));
                    }}
                    onEdit={(i, row) => {
                      // console.log(row)
                    }}
                    editBtn={false}
                    addRow={field.activity && field.activity.length < 500 ? true : false}
                    addRowText="Add Activity"
                    addRowMarginRight={'2.5rem'}
                    onAddRow={(e) => {
                      let activities = field.activity;
                      activities.push({ errors: {} });
                      setActivityList(activities);
                    }}
                  />
                  <Grid item sm={12} xs={12}>
                    <Typography variant="h5" style={{ color: 'red' }}>{errors.activity}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: "2rem", textAlign: "center", justifyContent: 'center', padding: '20px 0', maxWidth: '100%' }}
                container
                item
                spacing={5}
              >
                <Typography variant="h6">Tooling/ Equipment Required</Typography>
                <Grid item xs={12}>
                  <Table
                    header={TABLE_HEADER}
                    data={field.equipmentRequired}
                    onDelete={(i, e, row) => {
                      let equipments = field.equipmentRequired;
                      // equipments.splice(i, 1);
                      delete equipments[i];
                      setEquipmentList(equipments.filter(a => a));
                    }}
                    onEdit={(i, row) => {
                      // console.log(row)
                    }}
                    editBtn={false}
                    addRow={true}
                    addRowText="Add Equipment"
                    addRowMarginRight={'11.3rem'}
                    onAddRow={(e) => {
                      let equipments = field.equipmentRequired;
                      equipments.push({ errors: {} });
                      setEquipmentList(equipments);
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="h5" style={{ color: 'red' }}>{errors.equipment}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ maxWidth: '100%' }}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                    Reference Criteria Example
                  </Typography>
                </Grid>
                <Grid item sm={12} container spacing={2}>
                  {references.map((item, i) => (
                    <Grid item sm={3} xs={12} className="safety-checkboxes review">
                      <Checkbox
                        color="primary"
                        checked={item.checked}
                        onChange={(e) => {
                          let list = [...references]
                          setReferences([...list.map((a, it) => { a.checked = it == i ? !a.checked : a.checked; return a; })]);
                          // savePlan()
                        }}
                        style={{ height: "36px", }}
                        value={item.name}
                        name={'safety-checkboxes'}
                        text={(
                          <Grid className={item.checked ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"}>
                            <img className="checkbox-img" src={item.image} width={70} />
                            <Typography variant="subtitle1">{item.name}</Typography>
                            <Done />
                          </Grid>
                        )}
                      />
                    </Grid>
                  ))}
                  <Grid item sm={12} xs={12}>
                    <Typography variant="h5" style={{ color: 'red' }}>{errors.referenceCriteria}</Typography>
                  </Grid>
                </Grid>
              </Grid>


              <Grid style={{ marginTop: "2rem", textAlign: "center" }} item>
                <Button
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                  width="150px"
                  text="Next"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </Grid>
            </Grid>
          )
        }
      </>
    </Grid>
  );
}
