import React from 'react'
import { MenuItem, TextField } from '@material-ui/core';
// import '../../styles/style.sass';
import './css/style.css';


const Index = ({
  id = Math.random(),
  variant = 'standard',
  customClass = '',
  isError,
  label = '',
  options = [],
  optionInfo = { value: 'value', label: 'label' },
  ...rest
}) => {
  return (
    <TextField
      // id={id}
      label={label}
      variant={variant}
      {...rest}
      {...(isError && { error: true, helperText: isError })}
      className={`${customClass}`}
      InputLabelProps={rest.InputLabelProps ? rest.InputLabelProps : {
        shrink: rest.value ? true : false,
        
      }}
      MenuProps={{
        disablePortal: true,
      }}
    >
      {
        options.map((option, index) => (
          <MenuItem key={index} value={option[optionInfo.value]}>
            {option[optionInfo.label]}
          </MenuItem>
        ))
      }
    </TextField>
  )
}

export default Index;
