import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "..";
import { Chip, makeStyles, Popper } from "@material-ui/core";
import { matchSorter } from "match-sorter";

const defaultMatchSorterOptions = { keys: ["name"] };
const defaultFilterOptions = (options, { inputValue }, matchSorterOptions) =>
  matchSorter(options, inputValue, matchSorterOptions);

const defaultOptionLabel = (option) => option.title || option.name||'';
const defaultOptions = [];
// const defaultOptions = [
//   {title: 'Option 1', name: "Option 1", age: 13, id: 0,},
//   {title: 'Option 2', name: "Option 2", age: 16, id: 1,},
//   {title: 'Option 3', name: "Option 3", age: 18, id: 2,},
//   {title: 'Option 4', name: "Option 4", age: 24, id: 3,},
//   {title: 'Option 5', name: "Option 5", age: 40, id: 4,},
//   {title: 'Option 6', name: "Option 6", age: 60, id: 5,},
// ];

const useStyles = makeStyles({
  imgErr: {
    color: "red",
    fontSize: ".8rem",
    // marginTop: "2rem",
    // marginLeft: "2rem",
    float:'left'
  },
});

const Index = ({
  options = defaultOptions,
  customOptionLabel = defaultOptionLabel, //function
  label = "Sample Multiselect",
  isError,
  variant = "standard",
  color = "primary",
  required = false,
  textFieldParams = {},
  matchSorterOptions = defaultMatchSorterOptions,
  filterOptions = defaultFilterOptions,
  renderInput = (params) => (
    <TextField
      {...params}
      {...textFieldParams}
      label={label}
      variant={variant}
      color={color}
      required={required}
    />
  ),
  renderTags = (value, getTagProps, customOptionLabel) =>
    value.map((option, i) => (
      <Chip
        key={i}
        {...getTagProps({ i })}
        label={customOptionLabel(option)}
        variant={variant === "filled" ? "outlined" : "standard"}
      />
    )),
  // getOptionSelected=(option, value) => {console.log(value, option); return option.id === value.id},
  ...rest
}) => {
  const classes = useStyles()
  return (
    <>
      <Autocomplete
        {...rest}
        options={options}
        getOptionLabel={customOptionLabel}
        renderTags={(value, tagProps) =>
          renderTags(value, tagProps, customOptionLabel)
        }
        renderInput={renderInput}
        filterOptions={(...props) =>
          filterOptions(...props, matchSorterOptions)
        }
        // getOptionSelected={getOptionSelected}
        PopperComponent={(props) => (
          <Popper {...props} style={rest.poperStyle||{ zIndex: 1302 }} />
        )}
        disablePortal
      />
      {
        isError ? <span className={classes.imgErr}>{isError}</span> : null
      }
      
    </>
  );
};

export default Index;
