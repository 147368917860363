import React, { useState } from 'react';
import { Tooltip, Zoom } from '@material-ui/core';
// import '../../styles/style.sass';
import './css/style.css';

const Index = ({
  title = 'This is a tooltip',
  Component = null,
  placement = 'bottom',
  TransitionComponent = Zoom,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const handleTooltip = (flag) => setOpen(flag);

  return (
    <Tooltip
      open={open}
      className={`lgs-tooltip`}
      {...rest}
      title={title}
      placement={placement}
      disablePortal
    >
      <div
        onMouseEnter={() => handleTooltip(true)}
        onMouseLeave={() => handleTooltip(false)}
        onClick={() => handleTooltip(false)}
      >{Component && <Component />}</div>
      {/* <Button>Text</Button> */}
    </Tooltip>
  )
}

export default Index;
