import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider,
  Grid, Hidden,
  Paper, useTheme,
  Typography, withStyles
} from '@material-ui/core';
import {
  TextField as CustomTextField,
  Button,
  AutoComplete as CustomAutoComplete,
  IconButton, DynamicForm,
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import useVehicleStyles from './jss/style';
import { AddCircleOutline } from '@material-ui/icons';
import { FORMCONFIG, SAMPLE_DATA as DATA, TYPE_COLORS, HIERARCHY_TABLE, MAPPED_OBJECTS } from './CONSTANTS';
import {
  DIALOGS,
  FORMS,
} from '../../../commons/CONSTANTS';
import CategoryDisplayBox from './CategoryDisplayBox';
import CategoryDescriptionBox from './CategoryDescriptionBox';
import Search from '../../Search';
import { addPlatform, clearPlateformData, listPlatforms, updatePlatform } from '../../../redux/actions/platform';
import { addMake, clearMakeData, listMakes, updateMake } from '../../../redux/actions/make';
import { addModel, clearModelData, listModels, updateModel } from '../../../redux/actions/model';
import { addVariant, clearVariantData, listVariants, updateVariant } from '../../../redux/actions/variant';
import { addVehicle, clearVechicleData, getVehicle, listVehicles, updateVehicle } from '../../../redux/actions/vehicle';
import { Link, useHistory } from 'react-router-dom';
import { schema } from '../CONSTANTS';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ADD_MAKE, ADD_MODEL, ADD_PLATFORM, ADD_VARIANT, ADD_VEHICLE, DELETE_MAKE, DELETE_MODEL, DELETE_PLATFORM, DELETE_VARIANT, GET_MAKE, GET_MODEL, GET_PLATFORM, GET_VARIANT, GET_VEHICLE, LIST_MAKE, LIST_MODEL, LIST_PLATFORM, LIST_VARIANT, LIST_VEHICLE, UPDATE_MAKE, UPDATE_MODEL, UPDATE_PLATFORM, UPDATE_VARIANT, UPDATE_VEHICLE } from '../../../redux/action_types';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomTextField);

const AutoComplete = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomAutoComplete);

const Index = ({ vehicleId = null }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const localClasses = useVehicleStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const platforms = useSelector(state => state.platform);
  const makes = useSelector(state => state.make);
  const models = useSelector(state => state.model);
  const variants = useSelector(state => state.variant);
  const vehicles = useSelector(state => state.vehicle);
  const [vehicleObj, setVehicleObj] = useState({
    vin: '',
    regNo: '',
    tag: '',
    chipId: '',
    slNo: '',
    bodyStyle: '',
    engine: '',
    transmission: '',
    platform: null,
    make: null,
    model: null,
    variant: null,
    modelYear: '',
    fuelType: '',
    color: '',
    market: ''
  });
  const [errors, setErrors] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [hiddenElements, setHiddenElements] = useState({
    vin: false,
    regNo: false,
    tag: false,
    chipId: false,
    slNo: false,
    bodyStyle: false,
    engine: false,
    transmission: false,
    platform: false,
    make: false,
    model: false,
    variant: false,
    modelYear: false,
    fuelType: false,
    color: false,
    market: false,
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: 'sample',
    text: 'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => { }
    },
    btn2: {
      text: 'Yes',
      onClick: () => { }
    }
  });
  const [showInfoDialog, setShowInfoDialog] = useState({
    title: 'sample',
    open: false,
    children: <div></div>,
    btn1: {
      text: 'Okay',
      onClick: () => { }
    },
  });

  const [activeObject, setActiveObject] = useState({ type: '', data: null });
  const [selectedObjects, setSelectedObjects] = useState({
    platform: null,
    make: null,
    model: null,
    variant: null,
  });
  const [addButton, setAddButton] = useState('platform');
  const [platformList, setPlatformList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  useEffect(() => {
    dispatch(listPlatforms({ limit: 0 }));
    dispatch(listMakes({ limit: 0 }));
    dispatch(listModels({ limit: 0 }));
    dispatch(listVariants({ limit: 0 }));
    dispatch(listVehicles({ limit: 0 }));
    if (vehicleId || id) {
      dispatch(getVehicle(vehicleId || id));
    }
  }, [])
  useEffect(() => {
    if (platforms.list?.data?.length) {
      dispatch(clearPlateformData(LIST_PLATFORM));
      setPlatformList(platforms.list.data);
    }
    if (platforms.detail?.data?._id) {
      dispatch(clearPlateformData(GET_PLATFORM));
    }
    if (platforms.add?.data?._id) {
      dispatch(clearPlateformData(ADD_PLATFORM));
      dispatch(listPlatforms({ limit: 0 }));
    }
    if (platforms.update?.data?._id) {
      dispatch(clearPlateformData(UPDATE_PLATFORM));
      dispatch(listPlatforms({ limit: 0 }));
    }
    if (platforms.delete?.data) {
      dispatch(clearPlateformData(DELETE_PLATFORM));
      dispatch(listPlatforms({ limit: 0 }));
    }
  }, [platforms]);
  useEffect(() => {
    if (makes.list?.data?.length) {
      dispatch(clearMakeData(LIST_MAKE));
      setMakeList(makes.list.data);
    }
    if (makes.detail?.data?._id) {
      dispatch(clearMakeData(GET_MAKE));
    }
    if (makes.add?.data?._id) {
      dispatch(clearMakeData(ADD_MAKE));
      dispatch(listMakes({ limit: 0, platform: vehicleObj?.platform?._id }));
    }
    if (makes.update?.data?._id) {
      dispatch(clearMakeData(UPDATE_MAKE));
      dispatch(listMakes({ limit: 0 }));
    }
    if (makes.delete?.data) {
      dispatch(clearMakeData(DELETE_MAKE));
      dispatch(listMakes({ limit: 0 }));
    }
  }, [makes]);
  useEffect(() => {
    if (models.list?.data?.length) {
      dispatch(clearModelData(LIST_MODEL));
      setModelList(models.list.data);
    }
    if (models.detail?.data?._id) {
      dispatch(clearModelData(GET_MODEL));
    }
    if (models.add?.data?._id) {
      dispatch(clearModelData(ADD_MODEL));
      dispatch(listModels({ limit: 0, make: vehicleObj?.make?._id }));
    }
    if (models.update?.data?._id) {
      dispatch(clearModelData(UPDATE_MODEL));
      dispatch(listModels({ limit: 0 }));
    }
    if (models.delete?.data) {
      dispatch(clearModelData(DELETE_MODEL));
      dispatch(listModels({ limit: 0 }));
    }
  }, [models]);
  useEffect(() => {
    if (variants.list?.data?.length) {
      dispatch(clearVariantData(LIST_VARIANT));
      setVariantList(variants.list.data);
    }
    if (variants.detail?.data?._id) {
      dispatch(clearVariantData(GET_VARIANT));
    }
    if (variants.add?.data?._id) {
      dispatch(clearVariantData(ADD_VARIANT));
      dispatch(listVariants({ limit: 0, model: vehicleObj?.model?._id }));
    }
    if (variants.update?.data?._id) {
      dispatch(clearVariantData(UPDATE_VARIANT));
      dispatch(listVariants({ limit: 0 }));
    }
    if (variants.delete?.data) {
      dispatch(clearVariantData(DELETE_VARIANT));
      dispatch(listVariants({ limit: 0 }));
    }
  }, [variants]);
  useEffect(() => {
    if (vehicles.add?.data?._id) {
      dispatch(clearVechicleData(ADD_VEHICLE));
      history.push('/vehicle');
    }
    if (vehicles.update?.data?._id) {
      dispatch(clearVechicleData(UPDATE_VEHICLE));
      history.push('/vehicle');
    }
    if (vehicles.list?.data?.length) {
      dispatch(clearVechicleData(LIST_VEHICLE));
      setVehicleList(vehicles.list.data);
    }
    // console.log(vehicles)
    if (vehicles.detail?.data?._id) {
      dispatch(clearVechicleData(GET_VEHICLE));
      setVehicleObj(vehicles.detail.data)
    }
  }, [vehicles]);

  useEffect(() => {
    // setVehicleObj(old => ({
    //   ...old,
    //   make: null,
    //   model: null,
    //   variant: null,
    // }))
    if (vehicleObj.platform) {
      changeActiveObject('platform');
      setSelectedObjectData('platform');
      // dispatch(listMakes({platform: vehicleObj.platform._id}));
    } else {
      resetActiveObject();
      setSelectedObjects(old => ({
        ...old,
        platform: null,
      }));
      // setSelectedObjectData('platform');
    }
  }, [vehicleObj.platform])

  useEffect(() => {
    // setVehicleObj(old => ({
    //   ...old,
    //   model: null,
    //   variant: null,
    // }))
    if (vehicleObj.make) {
      changeActiveObject('make');
      setSelectedObjectData('make');
      // dispatch(listModels({make: vehicleObj.make._id}));
    } else {
      changeActiveObject('platform');
      setSelectedObjects(old => ({
        ...old,
        make: null,
        model: null,
        variant: null,
      }));
    }
  }, [vehicleObj.make])

  useEffect(() => {
    // setVehicleObj(old => ({
    //   ...old,
    //   variant: null,
    // }))
    if (vehicleObj.model) {
      changeActiveObject('model');
      setSelectedObjectData('model');
      // dispatch(listVariants({model: vehicleObj.model._id}));
    } else {
      changeActiveObject('make');
      setSelectedObjects(old => ({
        ...old,
        model: null,
        variant: null,
      }));
    }
  }, [vehicleObj.model])

  useEffect(() => {
    if (vehicleObj.variant) {
      changeActiveObject('variant');
      setSelectedObjectData('variant');
    } else {
      changeActiveObject('model');
      setSelectedObjects(old => ({
        ...old,
        variant: null,
      }));
    }
  }, [vehicleObj.variant])

  const onTFChange = (e) => {
    setVehicleObj(old => ({
      ...old,
      [e.target.name]: e.target.value
    }))
  }

  const onACChange = (e, name, value) => {
    setVehicleObj(old => ({
      ...old,
      [name]: value === null ? null : value
    }))
  }

  const addNewItem = (item) => {
    alert(`Added successfully!`);
  }

  const shouldDisableElement = (item) => {
    switch (item.name) {
      case 'platform': return (vehicleObj.platform !== null && vehicleObj.make !== null);
      case 'make': return (vehicleObj.platform === null || vehicleObj.make !== null && vehicleObj.model !== null);
      case 'model': return (vehicleObj.make === null || vehicleObj.model !== null && vehicleObj.variant !== null);
      case 'variant': return (vehicleObj.model === null);
      default: return true;
    }
  }
  const mapData = (item) => {
    let props = {
      value: vehicleObj[item.name],
      onChange: (event, newValue) => {
        onACChange(event, item.name, newValue);
      },
      matchSorterOptions: { keys: ['name'] },
      customOptionLabel: (option) => option.name,
    }

    switch (item.name) {
      case 'platform': props = {
        ...props,
        options: platformList || [],
        onChange: (event, newValue) => {
          onACChange(event, item.name, newValue);
          if (newValue) dispatch(listMakes({ platform: newValue?._id }));
        },
        onFocus: () => {
          // changeActiveObject(item.name)
          changeAddButton(item.name)
        }
      }
        break;
      case 'make': props = {
        ...props,
        options: makeList || [],
        onChange: (event, newValue) => {
          onACChange(event, item.name, newValue);
          if (newValue) dispatch(listModels({ make: newValue?._id }));
        },
        onFocus: () => {
          // changeActiveObject(item.name)
          changeAddButton(item.name)
        }
      }
        break;
      case 'model': props = {
        ...props,
        options: modelList || [],
        onChange: (event, newValue) => {
          onACChange(event, item.name, newValue);
          if (newValue) dispatch(listVariants({ model: newValue?._id }));
        },
        onFocus: () => {
          // changeActiveObject(item.name)
          changeAddButton(item.name)
        }
      }
        break;
      case 'variant': props = {
        ...props,
        options: variantList || [],
        onFocus: () => {
          // changeActiveObject(item.name)
          changeAddButton(item.name)
        }
      }
        break;
      default: break;
    }
    return props;
  }

  const selectFormElement = (item) => {
    switch (item.component) {
      case 'Textfield': return <TextField
        {...item.props}
        value={vehicleObj[item.name]}
        error={errors[item.name]?.error || false}
        helperText={errors[item.name]?.message || ""}
        FormHelperTextProps={{ style: { whiteSpace: 'pre-wrap' } }}
        onChange={onTFChange}
      />
      case 'Autocomplete': return <AutoComplete
        {...item.props}
        disabled={shouldDisableElement(item)}
        textFieldParams={{
          error: errors[item.name]?.error || false,
          helperText: errors[item.name]?.message || "",
          FormHelperTextProps: { style: { whiteSpace: 'pre-wrap' } }
        }}
        {...mapData(item)}
      />
    }
  }

  const addIconButton = (item) => {
    if (item.dialog) {
      return <DynamicForm
        Component={(props) =>
          <IconButton {...props} color="primary" icon={<AddCircleOutline style={{ height: '32', width: '32' }} />} />}
        title={item.dialog.title}
        content={(closeDialog) =>
          FORMS.GENERAL_TF_FORM({
            onSubmit: (e) => {
              e.preventDefault();
              e.stopPropagation();
              // let list = DATA[item.list];
              // DATA[item.list].push(item.createObject(e.target, list.length));
              setShowConfirmDialog((old) => ({
                ...old,
                open: true,
                title: 'Please Confirm To Submit',
                text: 'Are you sure ?',
                btn1: {
                  ...old.btn1,
                },
                btn2: {
                  ...old.btn2,
                  onClick: () => addNewItem('item'),
                }
              }));
              closeDialog();
            },
            onReset: (e) => {
              e.preventDefault();
              e.stopPropagation();
              closeDialog();
            },
            container: `${classes.paddingX}`,
            ...item.dialog,
          },
          )}
      />
    }
    return <IconButton disabled color="primary" icon={<AddCircleOutline style={{ visibility: 'hidden', height: '32', width: '32' }} />} />
  }

  const renderForm = (model) => {
    return model.map((item, i) => <div
      key={i}
      className={`${classes.fullWidth}`}
      style={{
        display: `${hiddenElements[item.name] ? 'none' : 'flex'}`,
        visibility: `${hiddenElements[item.name] ? 'hidden' : 'visible'}`,
      }}
    >
      {selectFormElement(item)}
      {/* {addIconButton(item)} */}
    </div>
    )
  }

  const [categoryForm, setCategoryForm] = useState({ name: '', description: '' })
  const onCategoryFormChange = (e) => setCategoryForm(old => (
    {
      ...old,
      [e.target.name]: e.target.value
    }
  ))

  const onActiveEdit = (e, type) => setSelectedObjects(old => ({
    ...old,
    [type]: {
      ...old[type],
      [e.target.name]: e.target.value
    }
  }));

  const submitNewObject = (obj) => {
    switch (addButton) {
      case 'make': dispatch(addMake({
        ...obj,
        platform: vehicleObj.platform,
      }));
        return;
      case 'model': dispatch(addModel({
        ...obj,
        make: vehicleObj.make,
      }));
        return;
      case 'variant': dispatch(addVariant({
        ...obj,
        model: vehicleObj.model,
      }));
        return;
      case 'platform': dispatch(addPlatform(obj));
        return;
      default: return;
    }
  }

  const getUpdateAction = (obj) => {
    switch (activeObject.type) {
      case 'platform': return updatePlatform(obj, selectedObjects.platform._id);
      case 'make': return updateMake(obj, selectedObjects.make._id);
      case 'model': return updateModel(obj, selectedObjects.model._id);
      case 'variant': return updateVariant(obj, selectedObjects.variant._id);
      default: return;
    }
  }

  const changeActiveObject = (type) => setActiveObject({ type, data: vehicleObj[type] });
  const changeAddButton = (type) => setAddButton(type);
  const setSelectedObjectData = (type) => setSelectedObjects(old => ({ ...old, [type]: vehicleObj[type] }));
  const resetCategoryForm = () => setCategoryForm({ name: '', description: '' });
  const resetActiveObject = () => setActiveObject({ type: "", data: null });

  const previousSelectedCategory = (item) => {
    switch (item) {
      case 'platform': return '';
      case 'make': return 'platform';
      case 'model': return 'make';
      case 'variant': return 'model';
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    delete vehicleObj._id;
    delete vehicleObj.objectType;
    delete vehicleObj.deleted;
    delete vehicleObj.createdBy;
    delete vehicleObj.updatedBy;
    delete vehicleObj.updatedAt;
    delete vehicleObj.createdAt;
    delete vehicleObj.__v;
    const { error } = schema.validate(vehicleObj, { abortEarly: false });
    console.log(error)
    if (!error) {

      setErrors([]);
      setSubmitFlag(true);
      if (id || vehicleId) {
        dispatch(updateVehicle({
          ...vehicleObj,
        }, id || vehicleId));
      } else {
        dispatch(addVehicle({
          ...vehicleObj,
        }));
      }
    } else {
      setErrors([]);
      setFoundErrors(error?.details);
      console.debug(error?.details)
    }
  }

  const setFoundErrors = (errArr = []) => {
    errArr.forEach(item => {
      setErrors(old => ({
        ...old,
        [item.path[0]]: {
          error: true,
          message: item?.message
        }
      }));
    });
  }

  const checkIfCategorySelected = () => Object.entries(selectedObjects).some(([key, value]) => value);

  const autoPopulateFromSearch = (e, item) => {
    if (!item) return;
    let tempMake = null, tempPlatform = null, tempModel = null, tempVariant = null;
    switch (item.objectType) {
      case 'platform': return setVehicleObj(old => ({ ...old, platform: item }));
      case 'make': return setVehicleObj(old => ({
        ...old,
        make: item,
        platform: (platformList || []).find(temp => temp._id === item.platform)
      }));
      case 'model': tempMake = (makeList || []).find(temp => temp._id === item.make);
        tempPlatform = (platformList || []).find(temp => temp._id === tempMake.platform);
        return setVehicleObj(old => ({
          ...old,
          model: item,
          make: tempMake,
          platform: tempPlatform,
        }));
      case 'variant': tempModel = (modelList || []).find(temp => temp._id === item.model);
        tempMake = (makeList || []).find(temp => temp._id === tempModel.make);
        tempPlatform = (platformList || []).find(temp => temp._id === tempMake.platform);
        return setVehicleObj(old => ({
          ...old,
          variant: item,
          model: tempModel,
          make: tempMake,
          platform: tempPlatform,
        }));
      case 'vehicle': return setVehicleObj(old => ({
        ...old,
        ...item,
        variant: item.variant,
        model: item.model,
        make: item.make,
        platform: item.platform,
      }));
    }
  }
  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Add Vehicle</Typography>
      </Grid>
      <Grid item container className={`${classes.height95}`} direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} sm={6} md={6} direction='row' className={`${classes.stretchHeight}`}>
            <form onSubmit={onSubmit} className={`${classes.fullWidth} ${classes.stretchHeight}`} noValidate>
              <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90}`} style={{ overflowY: 'auto', whiteSpace: 'nowrap' }}>
                <Grid item xs={12} sm={6} className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(0, 8))}
                </Grid>
                <Grid item xs={12} sm={6} className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(8))}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  <Button text='Cancel' variant='contained' component={Link} to='/vehicle' />
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  <Button type='submit' text='Submit' variant='contained' color='primary' />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Hidden xsDown>
            <Grid item container sm={6} direction='column' className={`${classes.stretchHeight}`}>
              <Divider orientation='vertical' />
              <Grid container direction='column' className={`${classes.fullWidth} ${classes.paddingLeft} ${classes.stretchHeight}`}>
                <Grid item className={`${classes.stretchHeight}`}>
                  <Grid item container sm={12} className={`${classes.height10}`}>
                    {
                      // ['platform', 'make', 'model', 'variant'].includes(activeObject.type)
                      <Grid item container>
                        <Grid item sm={6}>
                          <Typography className={`${classes.fullWidth} ${classes.marginX}`}
                            variant='h5' align='left' gutterBottom>
                            All Platforms
                          </Typography>
                        </Grid>
                        <Grid item container sm={6} justifyContent='flex-end' className={`${classes.paddingX}`}>
                          {FORMCONFIG[MAPPED_OBJECTS[addButton]] && <DynamicForm
                            Component={(props) =>
                              <Button {...props} marginRight='0' text={`ADD ${addButton || 'Platform'}`} color="primary" variant='contained' />}
                            title={FORMCONFIG[MAPPED_OBJECTS[addButton]].dialog.title}
                            content={(closeDialog) =>
                              FORMS.GENERAL_TF_FORM({
                                onSubmit: (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  let formInput = {
                                    name: e.target[0].value,
                                    description: e.target[1].value,
                                  }

                                  setShowConfirmDialog((old) => ({
                                    ...old,
                                    open: true,
                                    title: `Please confirm to add new ${addButton}`,
                                    text: 'Are you sure ?',
                                    btn1: {
                                      ...old.btn1,
                                    },
                                    btn2: {
                                      ...old.btn2,
                                      onClick: () => submitNewObject(formInput),
                                    }
                                  }));
                                  closeDialog();
                                },
                                onReset: (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  closeDialog();
                                },
                                container: `${classes.paddingX}`,
                                ...FORMCONFIG[MAPPED_OBJECTS[addButton]].dialog,
                              },
                              )}
                          />}
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  <Grid item container direction='column' sm={12} className={`${classes.height90} ${classes.hideScrollbar}`} style={{ overflowY: 'scroll', whiteSpace: 'nowrap' }}>
                    {
                      checkIfCategorySelected() && <Grid item container>
                        {
                          vehicleObj.platform && <Grid item sm={3}>
                            <CategoryDisplayBox
                              name='Platform'
                              value={selectedObjects.platform?.name}
                              onChange={(e) => {
                                onActiveEdit(e, 'platform');
                              }}
                              onClick={(e) => changeActiveObject('platform')}
                              onBlur={(props) => {
                                setShowConfirmDialog((old) => ({
                                  ...old,
                                  open: true,
                                  title: 'Please Confirm To Apply Changes',
                                  text: 'Are you sure ?',
                                  btn1: {
                                    ...old.btn1,
                                  },
                                  btn2: {
                                    ...old.btn2,
                                    onClick: () => {
                                      dispatch(updatePlatform({ name: selectedObjects.platform.name }, selectedObjects.platform._id))
                                      setVehicleObj(old => ({ ...old, platform: selectedObjects.platform }))
                                    },
                                  }
                                }));
                              }}
                            // onBlur={(props) => {
                            //   let dat = DATA.PLATFORMS[selectedCategory.data.platform];
                            //   if(props !== dat.name) {
                            //     dat.name = dat.title = props;
                            //     DATA.PLATFORMS.splice(selectedCategory.data.platform, 1, dat)
                            //   }
                            //   return true
                            // }}
                            />
                          </Grid>
                        }
                        {
                          vehicleObj.make && <Grid item sm={3}>
                            <CategoryDisplayBox
                              name='Make'
                              value={selectedObjects.make?.name}
                              onChange={(e) => {
                                onActiveEdit(e, 'make');
                              }}
                              onClick={(e) => changeActiveObject('make')}
                              onBlur={(props) => {
                                setShowConfirmDialog((old) => ({
                                  ...old,
                                  open: true,
                                  title: 'Please Confirm To Apply Changes',
                                  text: 'Are you sure ?',
                                  btn1: {
                                    ...old.btn1,
                                  },
                                  btn2: {
                                    ...old.btn2,
                                    onClick: () => {
                                      dispatch(updateMake({ name: selectedObjects.make.name }, selectedObjects.make._id))
                                      setVehicleObj(old => ({ ...old, make: selectedObjects.make }))
                                    },
                                  }
                                }));
                              }}
                            />
                          </Grid>
                        }
                        {
                          vehicleObj.model && <Grid item sm={3}>
                            <CategoryDisplayBox
                              name='Model'
                              value={selectedObjects.model?.name}
                              onChange={(e) => {
                                onActiveEdit(e, 'model');
                              }}
                              onClick={(e) => changeActiveObject('model')}
                              onBlur={(props) => {
                                setShowConfirmDialog((old) => ({
                                  ...old,
                                  open: true,
                                  title: 'Please Confirm To Apply Changes',
                                  text: 'Are you sure ?',
                                  btn1: {
                                    ...old.btn1,
                                  },
                                  btn2: {
                                    ...old.btn2,
                                    onClick: () => {
                                      dispatch(updateModel({ name: selectedObjects.model.name }, selectedObjects.model._id))
                                      setVehicleObj(old => ({ ...old, model: selectedObjects.model }))
                                    },
                                  }
                                }));
                              }}
                            />
                          </Grid>
                        }
                        {
                          vehicleObj.variant && <Grid item sm={3}>
                            <CategoryDisplayBox
                              name='Variant'
                              value={selectedObjects.variant?.name}
                              onChange={(e) => {
                                onActiveEdit(e, 'variant');
                              }}
                              onClick={(e) => changeActiveObject('variant')}
                              onBlur={(props) => {
                                setShowConfirmDialog((old) => ({
                                  ...old,
                                  open: true,
                                  title: 'Please Confirm To Apply Changes',
                                  text: 'Are you sure ?',
                                  btn1: {
                                    ...old.btn1,
                                  },
                                  btn2: {
                                    ...old.btn2,
                                    onClick: () => {
                                      dispatch(updateVariant({ name: selectedObjects.variant.name }, selectedObjects.variant._id))
                                      setVehicleObj(old => ({ ...old, variant: selectedObjects.variant }))
                                    },
                                  }
                                }));
                              }}
                            />
                          </Grid>
                        }
                      </Grid>
                    }
                    {
                      // ['platform', 'make', 'model', 'variant'].includes(activeObject.type) 
                      checkIfCategorySelected() && <Grid item container>
                        <Grid item xs={12} className={`${classes.marginX}`}>
                          <CategoryDescriptionBox
                            name={activeObject.type}
                            value={selectedObjects[activeObject.type]?.description}
                            fullWidth={true}
                            onChange={(e) => {
                              onActiveEdit(e, activeObject.type);
                            }}
                            onBlur={(props) => {
                              setShowConfirmDialog((old) => ({
                                ...old,
                                open: true,
                                title: 'Please Confirm To Apply Changes',
                                text: 'Are you sure ?',
                                btn1: {
                                  ...old.btn1,
                                },
                                btn2: {
                                  ...old.btn2,
                                  onClick: () => {
                                    dispatch(getUpdateAction({ description: selectedObjects[activeObject.type].description }))
                                    setVehicleObj(old => ({ ...old, [activeObject.type]: selectedObjects[activeObject.type] }))
                                  },
                                }
                              }));
                            }}
                          />
                          <Typography variant='caption' color='textSecondary' className={classes.paddingX}>Double-click to edit</Typography>
                        </Grid>
                      </Grid>
                    }
                    {
                      <Grid item container justifyContent='center' alignItems='center' className={`${classes.stretchHeight}`}>
                        {
                          <Grid item container justifyContent='center' className={`${classes.paddingBottom}`}>
                            <Search
                              placeholder='Search Vehicles, Platform, Make, Model, Variant, Vin, Reg, Tag, Chip Id, Serial No'
                              renderOption={(option) => {
                                return <div className={`${classes.fullWidth}`} style={{ display: 'flex' }}>
                                  <span className={`${localClasses.searchACLeftRender} ${classes.overFlowTextEllipsis}`} style={{ color: TYPE_COLORS[option.objectType] }}>
                                    {option.objectType === 'vehicle' ? option.vin : option.name}
                                  </span>
                                  <span className={`${localClasses.searchACRightRender} ${classes.overFlowTextEllipsis}`}><small>{option.objectType}</small></span>
                                </div>
                                // return <div>
                                //   <span style={{color: TYPE_COLORS[option.objectType]}}>
                                //     {option.objectType === 'vehicle' ? option.vin: option.name}
                                //   </span> 
                                // </div> 
                              }}
                              matchSorterOptions={{ keys: ['objectType', 'name', 'vin', 'regNo', 'tag', 'slNo', 'chipId'] }} //? objectType takes priority
                              // customOptionLabel= {(option) => option.objectType === 'vehicle' ? option.vin: option.name}
                              onChange={(event, newValue) => {
                                autoPopulateFromSearch(event, newValue);
                              }}
                              // renderGroup={(option) => {
                              //   return (option.type ? <span>{`${option.type.charAt(0).toUpperCase()}${option.type.slice(1)}`}</span>: option.type)
                              // }}
                              options={[...(platformList || []), ...(makeList || []), ...(modelList || []), ...(variantList || []), ...(vehicleList || [])]}
                            // groupBy={(option) => `${option.objectType.charAt(0).toUpperCase()}${option.objectType.slice(1)}`}
                            />
                          </Grid>
                        }
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1: showConfirmDialog.btn1,
          btn2: showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }
      {
        DIALOGS.INFO_DIALOG({
          ...showInfoDialog,
          setOpen: setShowInfoDialog,
          container: `${classes.paddingX}`,
        })
      }
    </Grid>
  )
}

export default Index;