import {
  LIST_ASSESSMENT,
  GET_ASSESSMENT,
  ADD_ASSESSMENT,
  UPDATE_ASSESSMENT,
  DELETE_ASSESSMENT,
} from "../action_types";
import axios from "axios";
// import { QS } from '../../utils/urlHelper';

export const listAssessmentQuestions = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: "GET",
      url: `/assessment_questions`,
      params,
    });
    dispatch({
      type: LIST_ASSESSMENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_ASSESSMENT,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const getAssessmentQuestion = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "GET",
      url: `/assessment_questions/${id}`,
    });
    dispatch({
      type: GET_ASSESSMENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const addAssessmentQuestion = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/assessment_questions`,
      data,
    });
    dispatch({
      type: ADD_ASSESSMENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_ASSESSMENT,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};

export const updateAssessmentQuestion = (data, id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "PUT",
      url: `/assessment_questions/${id}`,
      data,
    });
    dispatch({
      type: UPDATE_ASSESSMENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ASSESSMENT,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};

export const deleteAssessmentQuestion = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "DELETE",
      url: `/assessment_questions/${id}`,
    });
    dispatch({
      type: DELETE_ASSESSMENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: DELETE_ASSESSMENT,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};
export const clearAssesmentQuestionData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_ASSESSMENT,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};