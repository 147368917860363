import { 
    GET_FILE,
    ADD_FILE,
    ADD_FILES,
  } from '../action_types';
  import axios from 'axios';

  
  export const getFile = (id) => async dispatch => {
    try {
      let res = await axios({
        method: 'GET',
        url: `/file/${id}`,
      });
      dispatch({
        type: GET_FILE,
        payload: {
          data: res.data,
          isLoading: false,
          hasError: false,
        }
      });
    } catch(err) {
      dispatch({
        type: GET_FILE,
        payload: {
          data: {},
          isLoading: false,
          hasError: true,
        }
      });
    }
  }
  
  export const uploadFile = (data) => async dispatch => {
    try {
      axios.defaults.headers.common["Content-Type"] = `multipart/form-data`;
      let res = await axios({
        method: 'POST',
        url: `/upload`,
        data,
      });
      dispatch({
        type: ADD_FILE,
        payload: {
          data: res.data,
          hasError: false,
        }
      });
    } catch(err) {
      dispatch({
        type: ADD_FILE,
        payload: {
          data: {},
          hasError: true,
        }
      });
    }
  }
  export const uploadFiles = (data) => async dispatch => {
    try {
      axios.defaults.headers.common["Content-Type"] = `multipart/form-data`;
      let res = await axios({
        method: 'POST',
        url: `/uploads`,
        data,
      });
      dispatch({
        type: ADD_FILES,
        payload: {
          data: res.data,
          hasError: false,
        }
      });
    } catch(err) {
      dispatch({
        type: ADD_FILES,
        payload: {
          data: {},
          hasError: true,
        }
      });
    }
  }
  export const clearFileData = (type = '') => async (dispatch) => {
    dispatch({
      type: type || ADD_FILE,
      payload: {
        data: {},
        meta: null,
        isLoading: false,
        hasError: false,
      }
    });
  };