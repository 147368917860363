import {
  LIST_PLATFORM,
  GET_PLATFORM,
  ADD_PLATFORM,
  UPDATE_PLATFORM,
  DELETE_PLATFORM
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listPlatforms = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/platform`,
      params
    });
    dispatch({
      type: LIST_PLATFORM,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_PLATFORM,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getPlatform = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/platform/${id}`,
    });
    dispatch({
      type: GET_PLATFORM,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_PLATFORM,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addPlatform = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/platform`,
      data
    });
    dispatch({
      type: ADD_PLATFORM,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_PLATFORM,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updatePlatform = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/platform/${id}`,
      data
    });
    dispatch({
      type: UPDATE_PLATFORM,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PLATFORM,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deletePlatform = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/platform/${id}`,
    });
    dispatch({
      type: DELETE_PLATFORM,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_PLATFORM,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}
export const clearPlateformData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_PLATFORM,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};